export const SVGMoveDown = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      cliprule="evenodd"
      d="M11 20.3902C5.81391 20.3902 1.60976 16.1861 1.60976 11C1.60976 5.81391 5.81391 1.60976 11 1.60976C16.1861 1.60976 20.3902 5.81391 20.3902 11C20.3902 16.1861 16.1861 20.3902 11 20.3902ZM11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22Z"
      fill="black"
    />
    <path
      d="M6.97561 14.0787L10.151 11L6.97561 7.92134L7.95106 6.97561L12.1019 11L7.95106 15.0244L6.97561 14.0787ZM15.561 6.97561H14.1774V15.0244H15.561V6.97561Z"
      fill="black"
    />
  </svg>
);

export const SVGAddSection = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7.00714 11.9998C7.00714 11.5856 7.34293 11.2498 7.75714 11.2498H11.2498V7.75717C11.2498 7.34296 11.5855 7.00717 11.9998 7.00717C12.414 7.00717 12.7498 7.34296 12.7498 7.75717V11.2498H16.2424C16.6566 11.2498 16.9924 11.5856 16.9924 11.9998C16.9924 12.414 16.6566 12.7498 16.2424 12.7498H12.7498V16.2425C12.7498 16.6567 12.414 16.9925 11.9998 16.9925C11.5855 16.9925 11.2498 16.6567 11.2498 16.2425V12.7498H7.75714C7.34293 12.7498 7.00714 12.414 7.00714 11.9998Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.31649 3.76858C10.404 3.4235 13.5954 3.4235 16.683 3.76858C18.5094 3.9727 19.9843 5.41134 20.1991 7.2483C20.5683 10.4052 20.5683 13.5944 20.1991 16.7513C19.9843 18.5883 18.5094 20.0269 16.683 20.2311C13.5954 20.5761 10.404 20.5761 7.31649 20.2311C5.49011 20.0269 4.01521 18.5883 3.80036 16.7513C3.43113 13.5944 3.43113 10.4052 3.80036 7.2483C4.01521 5.41134 5.49011 3.9727 7.31649 3.76858ZM16.5164 5.25929C13.5396 4.92659 10.4599 4.92659 7.4831 5.25929C6.33867 5.3872 5.42262 6.29045 5.29021 7.42255C4.93451 10.4637 4.93451 13.5359 5.29021 16.5771C5.42262 17.7092 6.33867 18.6124 7.4831 18.7404C10.4599 19.0731 13.5396 19.0731 16.5164 18.7404C17.6608 18.6124 18.5769 17.7092 18.7093 16.5771C19.065 13.5359 19.065 10.4637 18.7093 7.42256C18.5769 6.29045 17.6608 5.3872 16.5164 5.25929Z"
      fill="black"
    />
  </svg>
);

export const SVGDuplicateSection = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      cliprule="evenodd"
      d="M7.51339 5.5C6.40143 5.5 5.5 6.40143 5.5 7.51339V18.4152C5.5 19.5271 6.40143 20.4286 7.51339 20.4286H18.4152C19.5271 20.4286 20.4286 19.5271 20.4286 18.4152V7.51339C20.4286 6.40143 19.5271 5.5 18.4152 5.5H7.51339ZM3.92857 7.51339C3.92857 5.53355 5.53355 3.92857 7.51339 3.92857H18.4152C20.395 3.92857 22 5.53355 22 7.51339V18.4152C22 20.395 20.395 22 18.4152 22H7.51339C5.53355 22 3.92857 20.395 3.92857 18.4152V7.51339Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      cliprule="evenodd"
      d="M3.92625 3.41875e-06L3.92857 0H14.5357L14.538 3.18459e-06C15.4743 0.00266695 16.3715 0.375798 17.0336 1.03788C17.6956 1.69996 18.0688 2.59716 18.0714 3.53348C18.0714 3.53968 18.0714 3.54588 18.0713 3.55208L18.0467 4.73065C18.0377 5.1645 17.6786 5.50887 17.2448 5.49983C16.811 5.49079 16.4666 5.13176 16.4756 4.69792L16.5 3.52945C16.4963 3.01142 16.2889 2.51551 15.9224 2.14904C15.5542 1.78081 15.0552 1.57318 14.5345 1.57143H3.92982C3.30501 1.57356 2.70637 1.82271 2.26454 2.26454C1.82271 2.70637 1.57356 3.30501 1.57143 3.92982V14.5345C1.57318 15.0552 1.78081 15.5542 2.14904 15.9224C2.51729 16.2906 3.01623 16.4983 3.53698 16.5H4.71429C5.14822 16.5 5.5 16.8518 5.5 17.2857C5.5 17.7197 5.14822 18.0714 4.71429 18.0714H3.53571L3.53348 18.0714C2.59716 18.0688 1.69996 17.6956 1.03788 17.0336C0.375798 16.3715 0.00266695 15.4743 3.18459e-06 14.538L0 14.5357V3.92857L3.41875e-06 3.92625C0.00307833 2.88589 0.417723 1.88902 1.15337 1.15337C1.88902 0.417723 2.88589 0.00307833 3.92625 3.41875e-06Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      cliprule="evenodd"
      d="M12.9643 8.25C13.3982 8.25 13.75 8.60178 13.75 9.03572V16.8929C13.75 17.3268 13.3982 17.6786 12.9643 17.6786C12.5303 17.6786 12.1786 17.3268 12.1786 16.8929V9.03572C12.1786 8.60178 12.5303 8.25 12.9643 8.25Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      cliprule="evenodd"
      d="M8.25 12.9643C8.25 12.5303 8.60178 12.1786 9.03572 12.1786H16.8929C17.3268 12.1786 17.6786 12.5303 17.6786 12.9643C17.6786 13.3982 17.3268 13.75 16.8929 13.75H9.03572C8.60178 13.75 8.25 13.3982 8.25 12.9643Z"
      fill="black"
    />
  </svg>
);

export const SVGMoveUp = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      cliprule="evenodd"
      d="M11 20.3902C16.1861 20.3902 20.3902 16.1861 20.3902 11C20.3902 5.81391 16.1861 1.60976 11 1.60976C5.81391 1.60976 1.60976 5.81391 1.60976 11C1.60976 16.1861 5.81391 20.3902 11 20.3902ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
      fill="black"
    />
    <path
      d="M15.0244 14.0787L11.849 11L15.0244 7.92134L14.0489 6.97561L9.89808 11L14.0489 15.0244L15.0244 14.0787ZM6.43902 6.97561H7.82265V15.0244H6.43902V6.97561Z"
      fill="black"
    />
  </svg>
);

export const SVGDeleteSection = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.13514 7.85714H8.91892V17.2857H7.13514V7.85714Z" fill="black" />
    <path d="M12.4865 7.85714H14.2703V17.2857H12.4865V7.85714Z" fill="black" />
    <path
      d="M0 3.14286V4.71429H1.78378V20.4286C1.78378 20.8453 1.97172 21.245 2.30624 21.5397C2.64077 21.8344 3.09448 22 3.56757 22H17.8378C18.3109 22 18.7646 21.8344 19.0992 21.5397C19.4337 21.245 19.6216 20.8453 19.6216 20.4286V4.71429H21.4054V3.14286H0ZM3.56757 20.4286V4.71429H17.8378V20.4286H3.56757Z"
      fill="black"
    />
    <path d="M7.13514 0H14.2703V1.57143H7.13514V0Z" fill="black" />
  </svg>
);

export const SVGSlideOption = (
  <svg width="10" height="2" viewBox="0 0 10 2" fill="none">
    <path
      d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0Z"
      fill="#666"
    />
    <path
      d="M4 1C4 0.447715 4.44772 0 5 0C5.55229 0 6 0.447715 6 1C6 1.55228 5.55229 2 5 2C4.44772 2 4 1.55228 4 1Z"
      fill="#666"
    />
    <path
      d="M8 1C8 0.447715 8.44772 0 9 0C9.55229 0 10 0.447715 10 1C10 1.55228 9.55229 2 9 2C8.44772 2 8 1.55228 8 1Z"
      fill="#666"
    />
  </svg>
);
