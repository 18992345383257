import { useParams } from "react-router-dom";
import { Dropdown } from "antd";
import { SVGDefaultAvatar, SVGUserMenu } from "../../SVG/layout";
import { useEffect, useState } from "react";
import { getCookie } from "../../../lib/cookie";

const UserInfo = () => {
  const [usernameDisplay, setUsernameDisplay] = useState("");
  const { site = "trol", contentId } = useParams();

  const logout = () => {
    document.cookie =
      "storytelling_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "storytelling_user_info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "storytelling_trplus_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "storytelling_trplus_info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "storytelling_mirror_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "storytelling_mirror_info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    switch (site) {
      case "trplus":
        window.location.href = process.env.REACT_APP_TRPLUS_CMS_URL + "/logout";
        break;
      case "mirror":
        window.location.href = process.env.REACT_APP_MIRROR_CMS_URL + "/logout";
        break;
      default:
        window.location.href = process.env.REACT_APP_TROL_CMS_URL + "/site/logout";
    }
  };

  const getUsername = () => {
    const username = getCookie("storytelling_user_info");
    if (username) {
      try {
        const { username: _username } = JSON.parse(
          decodeURIComponent(username)
        );
        setUsernameDisplay(_username);
      } catch (e) {
        console.error(e)
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getUsername();  
    }, 1000);
  }, []);

  const items = [
    {
      label: <a onClick={logout}>Logout</a>,
      key: "0",
    },
  ];

  return (
    <>
      <Dropdown
        as="div"
        menu={{ items }}
        className="user--menu"
        trigger={["click"]}
        arrow={false}
      >
        <a onClick={(e) => e.preventDefault()}>
          {SVGDefaultAvatar}
          {usernameDisplay}
        </a>
      </Dropdown>
    </>
  );
};

export default UserInfo;
