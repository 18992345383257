import { Fragment, useContext, useState } from "react";
import striptags from "striptags";
import contentContext from "../../contexts/content_context";

import {
  templateConfig,
  templateConfigKey,
} from "../../config/template-config";
import { Dropdown, Modal } from "antd";
import _get from "lodash/get";
import {
  SVGMoveDown,
  SVGAddSection,
  SVGDuplicateSection,
  SVGMoveUp,
  SVGDeleteSection,
  SVGSlideOption,
  SVGDefaultSlideThumbnail,
} from "../SVG/slide-menu";
import { getDefaultSlide } from "../../lib/default-value";
import { SVGAddSlide, SVGAlert, SVGChangeLayout } from "../SVG/slide-edit";

const { confirm } = Modal;

const LayoutSection = ({ readOnly }) => {
  const {
    slides = [],
    setCurrentSlide,
    currentSlide,
    addSlide,
    duplicateSlide,
    removeSlide,
    moveSlide,
    setChangeTemplateModal,
  } = useContext(contentContext);
  const [dragOverSlide, setDragOverSlide] = useState(null);
  const [insertBeforeSlideNo, setInsertBeforeSlideNo] = useState(null);
  const [insertSlideModal, setInsertSlideModal] = useState(false);

  // const multipleItem = [
  //   {
  //     label: (
  //       <a href="#">
  //         {AddSection}
  //         Add section
  //       </a>
  //     ),
  //     key: "0",
  //   },
  //   ...items,
  // ];
  const confirmRemoveSlide = (index) => {
    confirm({
      title: SVGAlert,
      content: "ต้องการลบ Section นี้หรือไม่?",
      okText: "ลบ",
      cancelText: "ยกเลิก",
      centered: true,
      onOk() {
        removeSlide(index);
      },
      onCancel() {},
    });
  };

  const moveSlideForword = (index) => {
    if (readOnly) return;
    moveSlide(index, index - 1);
  };

  const moveSlideBackward = (index) => {
    if (readOnly) return;
    moveSlide(index, index + 1);
  };

  const moveSlideFirst = (index) => {
    if (readOnly) return;
    moveSlide(index, 0, false);
  };

  const moveSlideLast = (index) => {
    if (readOnly) return;
    moveSlide(index, slides.length, false);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData(
      "move-slide",
      JSON.stringify({
        dragZone: "slide-selection",
        index,
      })
    );
  };

  const handleOnDrop = (e, index) => {
    e.preventDefault();
    let data = e.dataTransfer.getData("move-slide");

    data = JSON.parse(data);
    if (readOnly) return;
    moveSlide(data.index, index, false);
  };

  const insertSlide = (selectTemplateInsert) => {
    addSlide(getDefaultSlide(selectTemplateInsert), insertBeforeSlideNo);
    setInsertSlideModal(false);
  };

  const getSlideTitle = (item) => {
    const content = _get(item, "data[0]", {});
    if (content.title) return content.title;
    if (content.abstract) return content.abstract;
    if (content.content) return striptags(content.content);
    return "";
  };

  const onChangeTemplate = (index) => {
    setCurrentSlide(index);
    setChangeTemplateModal(true);
  };
  const lastSlide = slides.length - 1;

  const checkMediaType = (_src) => {
    try {
      const extension = _src.split(".").pop();
      switch (extension) {
        case "mp4":
        case "webm":
        case "ogg":
          return "video";
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          return "image";
        default:
          return "unknown";
      }
    } catch (e) {
      return false;
    }
  };

  const getImgCover = (src) => {
    const _type = checkMediaType(src);
    if (_type === "video") {
      return "/img/template-gif/default-thumbnail.jpg";
    } else if (_type === "image") {
      return src;
    } else {
      return "/img/template-gif/default-thumbnail.jpg";
    }
  };

  return (
    <>
      <div className="thumbnail--list">
        {slides.map((item, index) => (
          <Fragment key={item.tmp_id}>
            <div
              key={"sep-" + index.tmp_id}
              className="addSlide"
              onClick={() => {
                if (readOnly) return;
                setInsertBeforeSlideNo(index);
                setInsertSlideModal(true);
              }}
            >
              {SVGAddSlide}
            </div>
            <div
              className={
                index === currentSlide
                  ? "display current--chapter selected"
                  : "display current--chapter"
              }
              key={"slide-" + item.tmp_id}
              style={{
                opacity: dragOverSlide === index ? 0.5 : 1,
              }}
              onClick={(e) => {
                //check menu modal change layout
                if (e.target.className === "_menu-section-option-11") {
                  return;
                }
                if (index === currentSlide) {
                  setCurrentSlide("new");
                } else {
                  setCurrentSlide(index);
                }
              }}
              draggable={true}
              onDragStart={(e) => handleDragStart(e, index)}
              onDragEnd={(e) => {
                e.preventDefault();
                setDragOverSlide(null);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                dragOverSlide !== index && setDragOverSlide(index);
              }}
              onDrop={(e) => {
                e.preventDefault();
                setDragOverSlide(null);
                handleOnDrop(e, index);
              }}
            >
              <div className="section--display">Section {index + 1}</div>
              <div className="display--item">
                <div
                  className="action--btn"
                  style={{ display: readOnly ? "none" : "" }}
                >
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <a
                              href="#"
                              className="_menu-section-option-8"
                              onClick={() => moveSlideFirst(index)}
                            >
                              {SVGMoveUp}
                              เลื่อนไป Section แรก
                            </a>
                          ),
                          key: "8",
                        },
                        {
                          label: (
                            <a
                              href="#"
                              className="_menu-section-option-7"
                              onClick={() => moveSlideLast(index)}
                            >
                              {SVGMoveDown}
                              เลื่อนไป Section สุดท้าย
                            </a>
                          ),
                          key: "7",
                        },
                        {
                          label: (
                            <a
                              href="#"
                              className="_menu-section-option-1"
                              onClick={() => duplicateSlide(index)}
                            >
                              {SVGDuplicateSection}
                              ทำซ้ำ Section นี้
                            </a>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            <a
                              className="_menu-section-option-2"
                              href="#"
                              onClick={() => confirmRemoveSlide(index)}
                            >
                              {SVGDeleteSection}
                              ลบ Section นี้
                            </a>
                          ),
                          key: "2",
                        },
                        !templateConfigKey[item.template]
                          .not_changetemplate && {
                          label: (
                            <a
                              href="#"
                              className="_menu-section-option-11"
                              onClick={() => onChangeTemplate(index)}
                            >
                              {SVGChangeLayout}
                              เปลี่ยน Layout
                            </a>
                          ),
                          key: "11",
                        },
                        // {
                        //   label: (
                        //     <a
                        //       href="#"
                        //       style={{ opacity: index === 0 ? 0.5 : 1 }}
                        //       onClick={() => {
                        //         if (index === 0) return;
                        //         moveSlideForword(index);
                        //       }}
                        //     >
                        //       {SVGMoveUp}
                        //       Move forward
                        //     </a>
                        //   ),
                        //   key: "3",
                        // },
                        // {
                        //   label: (
                        //     <a
                        //       href="#"
                        //       style={{ opacity: index === lastSlide ? 0.5 : 1 }}
                        //       onClick={() => {
                        //         if (index === lastSlide) return;
                        //         moveSlideBackward(index);
                        //       }}
                        //     >
                        //       {SVGMoveDown}
                        //       Move backward
                        //     </a>
                        //   ),
                        //   key: "4",
                        // },
                      ],
                    }}
                    trigger={["hover"]}
                  >
                    <a onClick={(e) => e.preventDefault()}>{SVGSlideOption}</a>
                  </Dropdown>
                </div>
                <div className="template--name--display">
                  <p>
                    {templateConfigKey[item.template]?.name &&
                      templateConfigKey[item.template]?.name}
                  </p>
                </div>
                <div className="display--list ">
                  <div className="thumbnail--display">
                    <img
                      src={getImgCover(
                        (item.data[0] || { thumbnails: [] }).thumbnails[0]
                      )}
                    />
                  </div>
                  <div className="display--content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${index + 1}. ${getSlideTitle(item)}`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ))}
        {!readOnly && (
          <div
            className="blank"
            onClick={() => {
              setInsertBeforeSlideNo(slides.length);
              setInsertSlideModal(true);
            }}
          >
            {SVGAddSlide}
          </div>
        )}
      </div>
      <span>
        Section {currentSlide === "new" ? "0" : currentSlide + 1}/
        {slides.length}
      </span>
      <Modal
        title="เลือก Layout ที่ต้องการแทรก"
        className="select--template"
        open={insertSlideModal}
        onOk={insertSlide}
        centered={true}
        footer={null}
        onCancel={() => {
          setInsertSlideModal(false);
        }}
        width="70vw"
      >
        <div className="template--list">
          {templateConfig.map((item, index) => {
            return (
              <div
                key={index}
                className="template--item"
                onClick={() => {
                  insertSlide(item.type);
                }}
              >
                <h3>{item.name}</h3>
                <img src={item.example_image} />
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default LayoutSection;
