import { useEffect, useRef, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import _debounce from "lodash/debounce";

import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/files_manager.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/css/plugins/emoticons.min.css";

const froalaConfig = {
  key: process.env.REACT_APP_FROALA_KEY,
  pastePlain: true,
  placeholderText: "รายละเอียด",
  charCounterCount: false,
  attribution: false,
  // autofocus: true,
  linkAlwaysBlank: true,
  heightMin: 500,
  useClasses: true,
  toolbarSticky: false,
  // imageManagerLoadParams: {
  //   content_id: null,
  //   // res_type: 'froala',
  // },
  imageDefaultWidth: "auto",
  imageEditButtons: [
    "imageReplace",
    "imageAlign",
    "imageRemove",
    "|",
    "imageLink",
    "linkOpen",
    "linkEdit",
    "linkRemove",
    "-",
    "imageDisplay",
    // "imageStyle",
    "imageAlt",
    "imageSize",
    "imageCaption",
  ],
  pluginsEnabled: [
    "align",
    "codeBeautifier",
    "codeView",
    "colors",
    // 'emoticons',
    // 'entities',
    // 'file',
    // 'fontAwesome',
    // 'fontFamily',
    "fontSize",
    "fullscreen",
    "image",
    // 'imageTUI',
    "imageManager",
    "inlineStyle",
    // 'inlineClass',
    // 'lineBreaker',
    // 'lineHeight',
    "link",
    "lists",
    "paragraphFormat",
    "paragraphStyle",
    // 'quickInsert',
    // 'quote',
    // 'save',
    "table",
    "url",
    "video",
    // 'wordPaste',
    "elle_quote",
    "customEmbeded",
    "qa_block",
    "insertHR",
  ],

  toolbarButtons: {
    moreText: {
      buttonsVisible: 4,
      buttons: [
        "bold",
        "italic",
        "underline",
        "fontSize",

        // more
        "textColor",
        "backgroundColor",
        "strikeThrough",
        "subscript",
        "superscript",
        // 'fontFamily',
        "inlineClass",
        "inlineStyle",
        "clearFormatting",
      ],
    },

    align: {
      buttonsVisible: 3,
      buttons: ["alignLeft", "alignCenter", "alignRight"],
    },
    moreParagraph: {
      buttonsVisible: 5,
      buttons: [
        "paragraphStyle",
        "paragraphFormat",
        "formatUL",
        "elle_quote",
        "qa_block",

        // more
        "insertTable",
        "formatOL",
        "formatOLSimple",
        "alignJustify",
        "lineHeight",
        "outdent",
        "indent",
        // 'quote',
        "insertHR",
      ],
    },
    moreRich: {
      buttonsVisible: 4,
      buttons: [
        "insertLink",
        "imageManager",
        // 'imageUpload',
        "insertVideo",
        "customEmbededBtn",
        // 'insertImage',
        // 'insertTable',
        // 'emoticons',
        // 'fontAwesome',
        // 'specialCharacters',
        // 'insertFile',
        // 'insertHR',
      ],
    },
    moreMisc: {
      buttons: [
        "fullscreen",
        "clearFormatting",
        "html",

        // more
        "undo",
        "redo",
        // 'print',
        // 'getPDF',
        "spellChecker",
        // 'selectAll',
        "help",
      ],

      buttonsVisible: 3,
    },
  },
  htmlRemoveTags: [""],
  // quickInsertButtons: ['image', 'ul', 'ol'],
  imageInsertButtons: ["imageBack", "|", "imageUpload", "imageManager"],
  videoInsertButtons: ["videoBack", "|", "videoByURL", "videoEmbed"],
  events: {
    initialized: function () {
      // console.log(`LOG: this ---> `, this)
    },
    // "imageManager.imagesLoaded": function (data) {
    //   // Do something here.
    //   // this is the editor instance.
    //   console.log(this.image.get());
    // },
    // "imageManager.imageLoaded": function ($img) {
    //   // Do something here.
    //   // this is the editor instance.
    //   console.log(this);
    // },
    // "image.uploaded": function (response) {
    //   // Do something here.
    //   // this is the editor instance.
    //   console.log(this);
    // },
    // "image.beforeUpload": handleBeforeUpload,
    "imageManager.imageDeleted": function () {
      console.log("imageManager.imageDeleted");
    },
  },
};

const HTMLEditor = ({
  value,
  onChange,
  onblur,
  onfocus,
  site,
  contentId,
  onFileUpdate,
}) => {
  const [text, setText] = useState(value);
  const foalaText = useRef(null);
  const handleSave = _debounce((value) => {
    if (onChange) {
      onChange({ value });
    }
  }, 300);
  froalaConfig.imageManagerLoadURL = `/api/image/get-ref/${site}/${contentId}`;
  froalaConfig.imageManagerDeleteURL = `/api/image/delete-froala`;
  froalaConfig.events["imageManager.imageDeleted"] = function (res) {
    try {
      onFileUpdate(JSON.parse(res));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSave.cancel();
    setText(value);
  }, [value]);

  const handleModelChange = (value) => {
    setText((prev) => {
      if (prev === value) {
        return prev;
      }
      handleSave(value);
      return value;
    });
  };

  if (onblur) {
    froalaConfig.events.onBlur = function () {
      onblur();
    };
  }

  if (onfocus) {
    froalaConfig.events.focus = function () {
      onfocus();
    };
  }

  return (
    <>
      <FroalaEditor
        tag="textarea"
        onModelChange={handleModelChange}
        model={text}
        config={froalaConfig}
        ref={foalaText}
      />
    </>
  );
};

export default HTMLEditor;
