import { Modal } from "antd";
import { useState, useContext } from "react";
import { templateConfig } from "../../config/template-config";
import contentContext from "../../contexts/content_context";
import { getDefaultSlide } from "../../lib/default-value";

const StoryLayout = () => {
  const { addSlide } = useContext(contentContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [slide, setSlide] = useState({});
  const showModal = (index) => {
    setIsModalOpen(true);
    setSlide(templateConfig[index]);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {templateConfig.map((slide, index) => {
        return (
          <div className="module--card--layout" key={index}>
            <div className="module--card--layout--name">
              <h2>{slide.name}</h2>
            </div>
            <div className="module--card--layout--thumbnail">
              <img alt={slide.name} src={slide.example_image} />
            </div>
            <div className="module--card--layout--button">
              <button className="preview" onClick={() => showModal(index)}>
                ดูตัวอย่าง
              </button>
              <button
                className="selected"
                onClick={() => addSlide(getDefaultSlide(slide.type))}
              >
                ใช้ layout นี้
              </button>
            </div>
          </div>
        )
      })}
      <Modal
        centered={true}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
        title={slide.name}
        className="select--template"
        width="70vw"
      >
        <div className="layout--list">
          <img alt={slide.name} src={slide.example_image} />
        </div>
        <button
          onClick={() => addSlide(getDefaultSlide(slide.type))}
          className="action--btn"
        >
          ใช้ layout นี้
        </button>
      </Modal>
    </>
  )
};
export default StoryLayout;
