import { Form, Modal, Button, Flex } from "antd";
import { useEffect, useMemo, useState } from "react";
import { PushpinOutlined } from "@ant-design/icons";
import {
  SVGAlert,
  SVGDNDChapter,
  SVGDeleteChapter,
} from "../../SVG/slide-edit";

const bc = new BroadcastChannel("storytelling-update");

const ChapterHeader = ({ template, charter }) => {
  if (
    !["timeline_1", "timeline_2", "bubble_1", "bubble_2"].includes(template)
  ) {
    return <></>;
  }

  if (charter !== 0) {
    return <></>;
  }
  return (
    <span
      style={{
        position: "absolute",
        top: "-12px",
        left: "31px",
        color: "#878787",
        border: "1px solid #69c5ff",
        padding: "0px 7px",
        fontSize: "10px",
        borderRadius: "5px",
        background: "#c1e7ff",
      }}
    >
      header
    </span>
  );
};

const ChapterTable = ({
  data = [],
  onClickChapter,
  onDeleteCapter,
  onMoveChapter,
  setDragOverChapter,
  activeChapter,
  template,
  config,
}) => {
  const [_activeChapter, setActiveChapter] = useState(activeChapter || null);
  const [activeDragOverChapter, setActiveDragOverChapter] = useState(null);

  useEffect(() => {
    setActiveChapter(activeChapter);
    bc.postMessage({
      type: "scroll",
      data: { activeChapter, currentSlide: 0, template: template },
    });
  }, [activeChapter]);

  const confirmDelete = (i) => {
    Modal.confirm({
      title: SVGAlert,
      content: "คุณต้องการจะลบข้อมูลนี้หรือไม่",
      okText: "ลบ",
      cancelText: "ยกเลิก",
      centered: true,
      onOk: () => {
        onDeleteCapter(i);
      },
    });
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("charter-table", index);
  };

  const handleOnDrop = (e, index) => {
    e.preventDefault();
    let data = e.dataTransfer.getData("charter-table");
    data = parseInt(data);
    onMoveChapter(data, index);
  };

  const headerType = useMemo(() => {
    if (["bubble_1", "bubble_2"].includes(template)) {
      return "พื้นหลังของ Bubble";
    } else if (["timeline_1", "timeline_2"].includes(template)) {
      return "ส่วนหัวของ Timeline";
    } else {
      return null;
    }
  }, [template]);

  return (
    <>
      <Form.Item>
        <div className="slide--list">
          {data.map((datum, i) =>
            i === 0 && headerType !== null ? (
              <div key={datum.tmp_id}>
                <div
                  key={"header"}
                  className={"slide--list--item"}
                  onClick={() => {
                    setActiveChapter(i);
                    onClickChapter(i);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                  >
                    <path
                      d="M12.4459 0.812752C12.2475 0.613295 11.9779 0.5008 11.6966 0.500004C11.4152 0.499208 11.145 0.610178 10.9455 0.808508C10.8402 0.915803 10.7588 1.04416 10.7067 1.18514C9.82386 3.0269 8.85187 4.06554 7.62522 4.67875C6.24894 5.35774 4.66893 5.82985 2.12224 5.82985C1.91276 5.83084 1.7082 5.89343 1.53404 6.00983C1.35988 6.12623 1.22383 6.29129 1.14283 6.48444C1.06264 6.67838 1.04171 6.89172 1.08269 7.09753C1.12368 7.30335 1.22473 7.49241 1.37309 7.64085L4.81431 11.0814L0 17.5L6.41978 12.6866L9.85994 16.1261C9.95849 16.2254 10.0762 16.3038 10.2059 16.3563C10.3353 16.4104 10.4733 16.4391 10.6112 16.4391C10.7492 16.4391 10.8871 16.4104 11.0166 16.3563C11.2106 16.2767 11.3766 16.1412 11.4934 15.967C11.6101 15.7928 11.6724 15.5879 11.6723 15.3782C11.6723 12.8319 12.1435 11.2522 12.8215 9.89743C13.4338 8.671 14.4726 7.6992 16.3158 6.81651C16.4567 6.76489 16.5849 6.68347 16.6914 6.5778C16.8898 6.37829 17.0008 6.10817 17 5.82685C16.9992 5.54553 16.8867 5.27605 16.6872 5.07766L12.4459 0.812752Z"
                      fill="black"
                    />
                  </svg>
                  <h4 className={_activeChapter === i ? "active" : ""}>
                    {headerType}
                  </h4>
                </div>
              </div>
            ) : (
              <div key={datum.tmp_id}>
                {activeDragOverChapter === i && (
                  <svg
                    width="17"
                    height="12"
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.647059 0.5C0.475448 0.5 0.310866 0.564385 0.189519 0.67899C0.068172 0.793596 0 0.949034 0 1.11111C0 1.27319 0.068172 1.42863 0.189519 1.54323C0.310866 1.65784 0.475448 1.72222 0.647059 1.72222H16.1765C16.3481 1.72222 16.5127 1.65784 16.634 1.54323C16.7554 1.42863 16.8235 1.27319 16.8235 1.11111C16.8235 0.949034 16.7554 0.793596 16.634 0.67899C16.5127 0.564385 16.3481 0.5 16.1765 0.5H0.647059ZM0 6C0 5.83792 0.068172 5.68248 0.189519 5.56788C0.310866 5.45327 0.475448 5.38889 0.647059 5.38889H16.1765C16.3481 5.38889 16.5127 5.45327 16.634 5.56788C16.7554 5.68248 16.8235 5.83792 16.8235 6C16.8235 6.16208 16.7554 6.31751 16.634 6.43212C16.5127 6.54673 16.3481 6.61111 16.1765 6.61111H0.647059C0.475448 6.61111 0.310866 6.54673 0.189519 6.43212C0.068172 6.31751 0 6.16208 0 6ZM0 10.8889C0 10.7268 0.068172 10.5714 0.189519 10.4568C0.310866 10.3422 0.475448 10.2778 0.647059 10.2778H16.1765C16.3481 10.2778 16.5127 10.3422 16.634 10.4568C16.7554 10.5714 16.8235 10.7268 16.8235 10.8889C16.8235 11.051 16.7554 11.2064 16.634 11.321C16.5127 11.4356 16.3481 11.5 16.1765 11.5H0.647059C0.475448 11.5 0.310866 11.4356 0.189519 11.321C0.068172 11.2064 0 11.051 0 10.8889Z"
                      fill="black"
                    />
                  </svg>
                )}
                <div
                  key={datum.tmp_id || i}
                  className={"slide--list--item"}
                  onClick={() => {
                    setActiveChapter(i);
                    onClickChapter(i);
                  }}
                  draggable={true}
                  onDragStart={(e) => handleDragStart(e, i)}
                  onDragEnd={(e) => {
                    e.preventDefault();
                    setActiveDragOverChapter(null);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    activeDragOverChapter !== i && setActiveDragOverChapter(i);
                  }}
                  onDrop={(e) => {
                    handleOnDrop(e, i);
                  }}
                >
                  {SVGDNDChapter}

                  <h4 className={_activeChapter === i ? "active" : ""}>
                    {datum.title || "New Chapter "}
                  </h4>
                    <button
                      className="remove"
                      onClick={() => {
                        confirmDelete(i);
                      }}
                    >
                      {SVGDeleteChapter}
                    </button>
                </div>
              </div>
            )
          )}
        </div>
      </Form.Item>
    </>
  );
};

export default ChapterTable;
