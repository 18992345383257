import { Form, Select } from "antd";

const StoryOptions = ({ value, onSelected }) => {
  return (
    <>
      <Form.Item label="การจัดวาง (layout)">
        <Select
          value={value || "fullscreenTxtLeftTheme"}
          defaultValue={"fullscreenTxtLeftTheme"}
          onChange={onSelected}
          style={{ height: "auto" }}
        >
          <Select.OptGroup
            label={<b style={{ fontSize: 16, color: "#000" }}>รูปเต็มจอ</b>}
          >
            <Select.Option value="fullscreenTxtLeftTheme">
              ข้อความชิดซ้าย
            </Select.Option>
            <Select.Option value="fullscreenTxtRightTheme">
              ข้อความชิดขวา
            </Select.Option>
            <Select.Option value="fullscreenTxtBottomTheme">
              ข้อความอยู่ล่าง
            </Select.Option>
            <Select.Option value="squareTxtCenterTheme">
              ข้อความอยู่กลางจอ
            </Select.Option>
          </Select.OptGroup>
          <Select.OptGroup
            label={
              <b style={{ fontSize: 16, color: "#000" }}>รูปครึ่งหน้าจอ</b>
            }
          >
            <Select.Option value="squareGreenTheme">
              รูปซ้าย ข้อความขวา
            </Select.Option>
            <Select.Option value="squareBlueTheme">
              รูปขวา ข้อความซ้าย
            </Select.Option>
          </Select.OptGroup>
        </Select>
      </Form.Item>
    </>
  );
};

export default StoryOptions;
