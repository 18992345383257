const TooltipImage = () => {
  return (
    <>
      ถ้าหากใส่รูปภาพไม่ตรงกับขนาดที่กำหนดไว้
      ระบบจะทำการครอบรูปกึ่งกลางให้อัตโนมัติ
      <img
        style={{ width: 200, margin: "15px auto", display: "block" }}
        src="/tooltip-1.jpg"
      />
    </>
  )
}
export default TooltipImage
