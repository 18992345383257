export const getDefaultChapter = () => {
  const tmp_id = Math.random().toString(36).substring(7);
  let defaultChapter = {
    tmp_id: tmp_id,
    title: "Title",
    abstract: "Description",
    content: "<p>Content</p>",
    thumbnails: [],
    thumbnails_mobile: [],
    layout: "",
    abbr_name: "",
    width: 150,
    height: 150,
    image_split: false,
  };
  return defaultChapter;
};

export const getDefaultSlide = (template) => {
  let defaultSlide = {
    tmp_id: Math.random().toString(36).substring(7),
    template: template,
    data: [],
  };

  if (template === "timeline_1" || template === "timeline_2") {
    defaultSlide.data = [
      getDefaultChapter(),
      { ...getDefaultChapter(), ...getDefaultChapter() },
    ];
  } else if (template === "bubble_1" || template === "bubble_2") {
    defaultSlide.data = [
      getDefaultChapter(),
      { ...getDefaultChapter(), ...getDefaultChapter() },
    ];
  } else if (template === "story") {
    defaultSlide.data = [
      {
        ...getDefaultChapter(),
        layout: "fullscreenTxtLeftTheme",
        title_style: "normal",
        description_style: "normal",
      },
    ];
  } else if (template === "bubble_1" || template === "timeline_2") {
    defaultSlide.data = [
      {
        ...getDefaultChapter(),
        embed: "",
        background_type: "image",
      },
    ];
  } else {
    defaultSlide.data = [getDefaultChapter()];
  }

  return defaultSlide;
};
