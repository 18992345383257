import { Form, Radio } from "antd";

const optionName = {
  left: "ซ้าย",
  right: "ขวา",
  center: "กลาง",
  full: "เต็มจอ",
  fullscreenTxtLeftTheme: "เต็มจอ ข้อความซ้าย",
  fullscreenTxtRightTheme: "เต็มจอ ข้อความขวา",
  fullscreenTxtBottomTheme: "เต็มจอ ข้อความล่าง",
  squareGreenTheme: "รูปด้านข้าง ข้อความซ้ายบนพื้นเขียว",
  squareBlueTheme: "รูปด้านข้าง ข้อความซ้ายบนพื้นน้ำเงิน",
  squareTxtCenterTheme: "รูปด้านข้าง ข้อความกลาง พื้นสีเทา",
};

const BottonLayoutOptions = ({
  value,
  onSelected,
  options = ["left", "right", "center", "full"],
}) => {
  return (
    <>
      <Form.Item label="ตำแหน่งการจัดวาง (layout)">
        <Radio.Group value={value} onChange={(e) => onSelected(e.target.value)}>
          {options.map((option, index) => (
            <Radio.Button value={option} key={option}>
              {optionName[option]}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default BottonLayoutOptions;
