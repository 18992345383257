import { Form, Modal, Button, Flex } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  SVGAlert,
  SVGDNDChapter,
  SVGDeleteChapter,
} from "../../SVG/slide-edit";

const bc = new BroadcastChannel("storytelling-update");

const ChapterTableChat = ({ onClickChapter, activeChapter, template }) => {
  const [_activeChapter, setActiveChapter] = useState(activeChapter || null);
  const [chapter, setChapter] = useState([
    { title: "จัดการหน้าแจ้งเตือน" },
    { title: "แชทสนทนา" },
  ]);
  useEffect(() => {
    setActiveChapter(activeChapter);
    bc.postMessage({
      type: "scroll",
      data: { activeChapter, currentSlide: 0, template: template },
    });
  }, [activeChapter]);

  return (
    <>
      <Form.Item>
        <div className="slide--list">
          {chapter.map((item, i) => (
            <div key={`chapter_chat_${i}`}>
              <div
                key={"header"}
                className={"slide--list--item"}
                style={{ gridTemplateColumns: "100%" }}
                onClick={() => {
                  setActiveChapter(i);
                  onClickChapter(i);
                }}
              >
                <h4 className={_activeChapter === i ? "active" : ""}>
                  {item.title}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </Form.Item>
    </>
  );
};

export default ChapterTableChat;
