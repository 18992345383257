import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Avatar, Dropdown, Form, TimePicker, Modal } from "antd";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import _get from "lodash/get";
import _set from "lodash/set";
import FormColor from "../../Form/Elements/ColorPicker";
import contentContext from "../../../contexts/content_context";
import ChapterTableChat from "../../Form/Elements/ChapterTableChat";
import DropImages from "../../Form/Elements/DropImage";
import UploadList from "../../Form/Elements/ImageList";
import OptionChat from "../../Form/Elements/OptionChat";
import axios from "axios";
import set from "lodash/set";

const _imgDefault =
  "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/602ea959c5a0430067e7e06b/fa7d00ec-51bc-4909-ac0e-2d3f135606dd/48";

const defaultChapter = {
  thumbnails: [],
  thumbnails_mobile: [],
  chat_bubble: {
    person: [
      {
        name: "นายสมชาย",
        image: _imgDefault,
        layout: "left",
      },
      {
        name: "นายพิธา",
        image: _imgDefault,
        layout: "right",
      },
    ],
    time: "09:00",
    backgroundColor: "#ffffff",
    conversation: [],
  },
};

const getDefaultValue = (slide) => {
  const content = _get(slide, "data[0]", {});
  if (!content.chat_bubble) {
    return defaultChapter;
  }
  return content;
};

let handleTimeout = null;

const FormCustomTitleEffect = ({ slide = {}, config, onChange }) => {
  const titleLength = 15;
  const [selectChapter, setSelectChapter] = useState(0);
  const { contentId, site, addImages, currentSlide, imageMode } =
    useContext(contentContext);
  const [chapter, setChapter] = useState(getDefaultValue(slide));
  const [message, setMessage] = useState("");
  const [cover, setCover] = useState({});
  const [conversation, setConversation] = useState([]);
  const [getActivePaticipant, setActivePaticipant] = useState(0);
  const [importModal, setImportModal] = useState(false);
  const [messageImport, setMessageImport] = useState("");
  const [textEdit, setTextEdit] = useState("");
  const messageRef = useRef(null);
  const uploadFileRef = useRef(null);

  const handleInputChange = (field, value) => {
    let _chapter = { ...chapter };
    _set(_chapter, field, value);
    setChapter(_chapter);
    clearTimeout(handleTimeout);
    handleTimeout = setTimeout(() => {
      onChange({ ...slide, data: [_chapter] });
    }, 500);
  };

  const onNewMessage = (forceRight = false) => {
    if (message === "") {
      return;
    }
    const newConversation = {
      id: conversation.length,
      content: message,
      time: "",
      personNo: getActivePaticipant,
      layout: getActivePaticipant === 0 ? "left" : "right",
    };
    if (forceRight) {
      newConversation.layout = "right";
    }
    setConversation([...conversation, newConversation]);
    setMessage("");
  };

  const changeLayout = (index) => {
    const _conversation = [...conversation];
    _conversation[index].layout =
      _conversation[index].layout === "left" ? "right" : "left";
    setConversation(_conversation);
  };

  const removeConversation = (index) => {
    const _conversation = [...conversation];
    _conversation.splice(index, 1);
    setConversation(_conversation);
  };

  const importChat = () => {
    const _conversation = messageImport.split("\n").map((item) => {
      return {
        id: conversation.length,
        content: item,
        time: "",
        personNo: getActivePaticipant,
        layout: getActivePaticipant === 0 ? "left" : "right",
      };
    });
    setConversation([...conversation, ..._conversation]);
    setMessageImport("");
    setImportModal(false);
  };

  useEffect(() => {
    const _chapter = getDefaultValue(slide);
    setChapter(_chapter);
    setConversation(_chapter.chat_bubble.conversation || []);
  }, [currentSlide, slide]);

  useEffect(() => {
    handleInputChange("chat_bubble", {
      ...chapter.chat_bubble,
      selectChapter,
      conversation,
    });
  }, [conversation, selectChapter]);

  const chat_bubble = chapter.chat_bubble || {};
  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     handleInputChange("chat_bubble.person[0]", {
  //       ...chat_bubble.person[0],
  //       image: reader.result,
  //     });
  //   };

  //   reader.readAsDataURL(file);
  // };
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const _formData = new FormData();

    _formData.append("images", file);
    _formData.append("ref_id", contentId);
    _formData.append("site", site);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/image/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: _formData,
      withCredentials: true,
    };

    const { data, status } = await axios.request(config);
    if (status === 200) {
      if (data.length > 0) {
        handleInputChange("chat_bubble.person[0]", {
          ...chat_bubble.person[0],
          image: data[0].url,
        });
      }
    }
    uploadFileRef.current.value = "";
  };

  const handleChapterChange = (key, value) => {
    const _chapter = { ...chapter, [key]: value };
    setChapter(_chapter);
    onChange({ ...slide, data: [_chapter] });
  };
  const editMessage = () => {
    const _conversation = [...conversation];
    _conversation[textEdit.id].content = message;
    setConversation(_conversation);
    setMessage("");
    setTextEdit("");
  };
  return (
    <Fragment>
      <div className="form--multiple--chapter">
        <div className="chapter--table">
          <ChapterTableChat
            onClickChapter={setSelectChapter}
            activeChapter={selectChapter}
          />
          <div id="chapter--editor"></div>
        </div>
        <div>
          {selectChapter === 0 ? (
            <>
              <Fragment>
                <Form.Item
                  label={
                    <span>
                      * <strong>เวลาบนหน้าจอ</strong>
                    </span>
                  }
                  rules={[{ required: true, message: "Please input title!" }]}
                >
                  <div style={{ display: "grid", width: "80px" }}>
                    <TimePicker
                      defaultValue={dayjs(chat_bubble.time, "HH:mm")}
                      format={"HH:mm"}
                      onChange={(time, timeString) => {
                        handleInputChange(
                          "chat_bubble.time",
                          timeString || "09:00"
                        );
                      }}
                    />
                  </div>
                </Form.Item>

                <Form.Item
                  className="uploadAvatar"
                  label={
                    <span>
                      * <strong>รูปตัวละครที่ส่งข้อความมา (ขนาด1:1)</strong>
                    </span>
                  }
                  rules={[{ required: true, message: "Please input title!" }]}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      marginTop: 10,
                      width: "100%",
                    }}
                  >
                    <img
                      src={chapter.chat_bubble.person[0].image || _imgDefault}
                      alt="Preview"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                        border: "3px solid #ff9243",
                        objectFit: "cover",
                      }}
                    />
                    <div className="browseFile">
                      <input
                        type="file"
                        accept="image/*"
                        ref={uploadFileRef}
                        onChange={handleImageChange}
                      />
                      <span>อัพโหลดรูป</span>
                    </div>
                  </div>
                </Form.Item>
              </Fragment>
              <DropImages
                limitThumbnail={config.thumbnails}
                ref_id={contentId}
                site={site}
                template={config.type}
                chapterImageSplit={chapter.image_split}
                onCheckbox={(imgs) => {
                  handleChapterChange("image_split", imgs);
                }}
              />
              {imageMode === "desktop" && (
                <UploadList
                  selected={chapter.thumbnails || []}
                  limitThumbnail={config.thumbnails}
                  onChange={(imgs) => {
                    handleInputChange("thumbnails", imgs);
                  }}
                />
              )}
              {imageMode === "mobile" && (
                <UploadList
                  selected={chapter.thumbnails_mobile || []}
                  limitThumbnail={config.thumbnails}
                  onChange={(imgs) => {
                    handleInputChange("thumbnails_mobile", imgs);
                  }}
                />
              )}
            </>
          ) : (
            <Fragment>
              <Form.Item
                label="ชื่อห้องสนทนา"
                rules={[{ required: true, message: "Please input title!" }]}
                tooltip="This is a required field"
              >
                <TextArea
                  type="text"
                  placeholder="ชื่อห้องสนทนา"
                  value={chapter.title}
                  onChange={(e) => {
                    handleInputChange("title", e.target.value);
                  }}
                />
                <span
                  className="char--remind"
                  style={{
                    color: chapter.title?.length > titleLength && "red",
                    borderColor: chapter.title?.length > titleLength && "red",
                  }}
                >
                  {(chapter.title || "").length}/{titleLength} ตัวอักษร
                </span>
              </Form.Item>
              <div style={{ display: "grid", gridGap: 10 }}>
                {conversation.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{ textAlign: item.layout || "left" }}
                    >
                      {item.layout === "left" && (
                        <Avatar
                          src={_get(
                            chat_bubble,
                            ["person", item.personNo, "image"],
                            _imgDefault
                          )}
                          style={{ margin: "0 5px" }}
                        />
                      )}
                      {item.layout === "right" && (
                        <OptionChat
                          changeLayout={changeLayout}
                          removeConversation={removeConversation}
                          setMessage={setMessage}
                          setTextEdit={setTextEdit}
                          item={item}
                          index={index}
                        />
                      )}
                      <div
                        style={{
                          display: "inline-block",
                          padding: "5px 10px",
                          borderRadius: "10px",
                          background: "#f2f2f2",
                          margin: "0 5px",
                        }}
                      >
                        {item.content}
                      </div>
                      {item.layout === "left" && (
                        <OptionChat
                          changeLayout={changeLayout}
                          removeConversation={removeConversation}
                          setMessage={setMessage}
                          setTextEdit={setTextEdit}
                          item={item}
                          index={index}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <Form.Item
                className="chatCharacter"
                label="ตัวละครฝั่งซ้ายและฝั่งขวา"
              >
                <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
                  {chat_bubble.person.map((item, index) => {
                    return (
                      <Avatar
                        src={item.image || _imgDefault}
                        onClick={() => {
                          setActivePaticipant(index);
                          messageRef.current.focus();
                        }}
                        style={{
                          marginRight: "5px",
                          border:
                            getActivePaticipant === index
                              ? "2px solid #ff9243"
                              : "none",
                        }}
                      >
                        {item.name || ""}
                      </Avatar>
                    );
                  })}
                </div>
                <span>(ตัวละครฝั่งขวาจะไม่แสดงรูปในแชท)</span>
              </Form.Item>
              <Form.Item
                label="ข้อความ (กด enter เพื่อส่ง):
"
              >
                <TextArea
                  ref={messageRef}
                  type="text"
                  placeholder="ข้อความ"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    e.preventDefault();
                    if (e.metaKey && e.keyCode === 13) {
                      if (textEdit) {
                        editMessage();
                      } else {
                        onNewMessage(true);
                      }
                    } else if (e.keyCode === 13) {
                      if (textEdit) {
                        editMessage();
                      } else {
                        onNewMessage();
                      }
                    }
                  }}
                />
                {/* <button
                  style={{ marginTop: "10px", float: "right" }}
                  onClick={() => {
                    if (textEdit) {
                      editMessage();
                    } else {
                      onNewMessage(true);
                    }
                  }}
                >
                  ส่งข้อความ
                </button> */}
              </Form.Item>
              <Form.Item label="หรือ">
                <button
                  onClick={() => {
                    setImportModal(true);
                  }}
                  style={{
                    display: "grid",
                    width: "auto",
                    textAlign: "center",
                    padding: "4px 8px",
                    background: "#fff",
                    borderRadius: 4,
                    border: "1px solid #000",
                  }}
                >
                  Import ชุดข้อความแชท
                </button>
              </Form.Item>
              {/* <FormColor
            title="เลือกสีพื้นตัวอักษร"
            value={chat_bubble.backgroundColor}
            onChange={(value) => {
              handleInputChange(
                "chat_bubble.backgroundColor",
                value.toHexString()
              );
            }}
          /> */}
            </Fragment>
          )}
        </div>
      </div>

      <Modal
        title="ใส่ชุดข้อความแชท"
        open={importModal}
        // onOk={importChat}
        // onCancel={() => {
        //   setImportModal(false);
        // }}
        className="importChat"
        footer={[
          <button
            className="onCancle"
            key="cancle"
            type="primary"
            onClick={() => setImportModal(false)}
          >
            ยกเลิก
          </button>,
          <button className="onOk" onClick={importChat}>
            ยืนยัน
          </button>,
        ]}
      >
        <ol>
          <li>พิมพ์ประโยคที่ต้องการ</li>
          <li>กด enter เพื่อขึ้นบรรทัดใหม่ในแชท</li>
          <li>
            เมื่อกดยืนยันแล้ว ข้อความจะอยู่ทางฝั่งซ้ายทั้งหมด แต่สามารถกด icon
            เพื่อสลับฝั่งได้
          </li>
        </ol>
        <TextArea
          type="text"
          rows="8"
          placeholder="ข้อความ"
          value={messageImport}
          onChange={(e) => {
            setMessageImport(e.target.value);
          }}
        />
      </Modal>
    </Fragment>
  );
};

export default FormCustomTitleEffect;
