import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Global, css } from "@emotion/react";
import { randomSlidesTmpId } from "./edit";
import axios from "axios";
import { templateConfig } from "../config/template-config";
import { Button, Col, Divider, Form, Input, Modal, Row } from "antd";
import HTMLEditor from "../Modules/Form/froala";
import _set from "lodash/set";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import { SVGAlertFailed, SVGAlertSuccess } from "../Modules/SVG/slide-edit";
import { SVGBackButton } from "../Modules/SVG/layout";
import SlideSection from "../Modules/Layout/SlideSection";
import contentContext from "../contexts/content_context";
import striptags from "striptags";
// const { info } = Modal;
const bc = new BroadcastChannel("storytelling-update");
const ProvePage = () => {
  const [slides, setSlides] = useState([]);
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [isDataChanged, setIsDataChanged] = useState(false);
  const getHash = () => {
    try {
      if (!hash?.[1]) return 0;
      return Number(hash.replace("#", ""));
    } catch (error) {
      return 0;
    }
  };

  const [currentSlide, setCurrentSlide] = useState(getHash());
  const { site = "trol", contentId } = useParams();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const contentCtx = useContext(contentContext);

  const boardcastUpdate = _debounce((_currentSlide, _slides) => {
    // bc.postMessage({
    //   currentSlide: _currentSlide,
    //   data: _slides || slides,
    //   type: 'content',
    // });
  }, 500);

  const handleDataChange = (index, field, _data) => {
    setSlides((prev) => {
      const keyField = `[${currentSlide}].data[${index}].${field}`;
      setIsDataChanged(true);
      console.log(`LOG: keyField ---> `, keyField);
      const newSlides = _set([...prev], keyField, _data);

      boardcastUpdate(currentSlide, newSlides);
      return newSlides;
    });
  };

  const fetchContent = async (contentId) => {
    const { data } = await axios.get(`/api/content/get/${site}/${contentId}`, {
      withCredentials: true,
    });

    if (data.status === 200) {
      if (data.data && typeof data.data === "object") {
        const _slides = randomSlidesTmpId(data.data);
        setSlides(_slides);
        scrollToSlide(currentSlide);
        boardcastUpdate(currentSlide, _slides);
        setIsDataChanged(false);
      }
    }
    if (data.status === 404) {
      alert("not found");
      setSlides([]);
    }
    if (data.status === 401) {
      document.cookie =
        "storytelling_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.href = `/layout/${site}/${contentId}`;
    }
  };

  const handleChangeSlide = (index) => {
    setCurrentSlide(index);
    boardcastUpdate(index, slides);
    navigate(`#${index}`);
    scrollToSlide(index);
  };

  const handleSaveContent = async () => {
    setConfirmLoading(true);
    const { data } = await axios.post(
      `/api/content/save/${site}/${contentId}`,
      { storytelling: slides },
      {
        withCredentials: true,
      }
    );

    if (data.status === 200) {
      setSlides(data.data.storytelling);
      boardcastUpdate(currentSlide, data.data.storytelling);
      setIsDataChanged(false);
      Modal.info({
        title: SVGAlertSuccess,
        content: (
          <>
            บันทึกเนื้อหาสำเร็จ
            <div className="ant-modal-confirm-btns">
              <button
                className="ant-btn-default"
                onClick={() => Modal.destroyAll()}
              >
                ปิด
              </button>
              <button
                className="ant-btn-default"
                onClick={() => {
                  Modal.destroyAll();
                  handleChangeSlide((currentSlide + 1) % slides.length);
                }}
              >
                ไป section ถัดไป
              </button>
            </div>
          </>
        ),
        // cancelText: 'ปิด',
        footer: null,
        centered: true,
        onOk() {},
      });
    } else {
      Modal.info({
        title: SVGAlertFailed,
        content: (
          <>
            บันทึกเนื้อหาไม่สำเร็จ
            <div className="ant-modal-confirm-btns">
              <button
                className="ant-btn-default"
                onClick={() => Modal.destroyAll()}
              >
                ปิด
              </button>
            </div>
          </>
        ),
        // cancelText: 'ปิด',
        footer: null,
        centered: true,
        onOk() {},
      });
    }
    setOpen(false);
    setConfirmLoading(false);
  };

  const scrollToSlide = (slide) => {
    try {
      document.getElementById(`section-${slide}`)?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    } catch (error) {}
  };
  const getSlideTitle = (item) => {
    const content = _get(item, "data[0]", {});
    if (content.title) return content.title;
    if (content.abstract) return content.abstract;
    if (content.content) return striptags(content.content);
    return "";
  };
  // const bcScroll = (data) => {
  //   const { activeChapter = 0, template } = data.data;
  //   // console.log(`LOG: activeChapter ---> `,activeChapter)
  //   const id = `storytelling-slide-prove-${activeChapter}`;
  //   const elm = document.getElementById(id);
  //   if (elm) {
  //     elm.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //     elm.classList.add('prove-active-chapter');
  //     setTimeout(() => {
  //       elm.classList.remove('prove-active-chapter');
  //       // elm.style.border = 'inherit';
  //     }, 1000);
  //   }
  // };

  // const bcContent = ({ data, currentSlide, type }) => {
  //   if (type !== 'content') return;
  //   if (!data) return;
  //   handleChangeSlide(currentSlide || 0);
  // };

  const handleBack = () => {
    window.location.href = `/layout/${site}/${contentId}`;
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDataChanged) {
        const confirmationMessage =
          "Are you sure you want to leave? Your changes may not be saved.";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDataChanged]);
  useEffect(() => {
    if (contentId) {
      fetchContent(contentId);
    }
    return () => {};
  }, [contentId]);

  useEffect(() => {
    const now = getHash();
    handleChangeSlide(now);
    return () => {};
  }, [hash]);

  useEffect(() => {
    // bc.onmessage = (event) => {
    //   const { type } = event.data;
    //   // console.log("bc", event.data);
    //   if (type === 'content') {
    //     bcContent(event.data);
    //   } else if (type === 'scroll') {
    //     // bcScroll(event.data);
    //   }
    // };
    return () => {};
    // bc.postMessage({ type: "preview-page-ready" });
  }, []);

  return (
    <contentContext.Provider
      value={{
        slides,
      }}
    >
      <Global styles={styles} />
      {slides.length > 0 && (
        <div className="prove-container">
          <div className="prove-nav">
            <Button
              onClick={handleBack}
              className="prove-back"
              icon={SVGBackButton}
            >
              Back
            </Button>
            <Button onClick={() => setOpen(true)} className="prove-save">
              Save
            </Button>
            <div>
              <Divider type="vertical" />
            </div>
            <div className="prove-slide-container h-scroll">
              {slides.map((slide, index) => {
                const config = templateConfig.find(
                  (item) => item.type === slide?.template
                );
                if (!config) {
                  return <></>;
                }
                return (
                  <div
                    id={`section-${index}`}
                    className={`prove-slide ${
                      index === currentSlide ? "prove-slide-active" : ""
                    }`}
                    onClick={() => handleChangeSlide(index)}
                  >
                    <p className="prove-slide-name">
                      Section {index + 1} - {config.name}
                    </p>
                    <div className="prove-slide-body">
                      <img
                        src={
                          (slide.data[0] || { thumbnails: [] }).thumbnails[0] ||
                          "/img/template-gif/default-thumbnail.jpg"
                        }
                        alt="thumbnail"
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${index + 1}. ${getSlideTitle(slide)}`,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {[slides[currentSlide]].map((slide, index) => {
            const config = templateConfig.find(
              (item) => item.type === slide?.template
            );
            if (!config) {
              return <>Not Found Config: {slide?.template}</>;
            }
            return (
              <div className="prove-section" key={slide.tmp_id}>
                {slide.data.map((item, i) => {
                  return (
                    <FormData
                      formId={`storytelling-slide-prove-${i}`}
                      key={i}
                      item={item}
                      config={config}
                      onChange={handleDataChange}
                      index={i}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      <Modal
        title="Do you want to save this data?"
        open={open}
        onOk={handleSaveContent}
        okText={"Save"}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
      ></Modal>

      {slides.length === 0 && (
        <div style={{ margin: "50px auto", textAlign: "center", width: 300 }}>
          Waiting data...
        </div>
      )}
    </contentContext.Provider>
  );
};

function FormData({ item, config, onChange, index, readOnly, formId }) {
  return (
    <div key={item.tmp_id} id={formId} className="prove-chapter">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        layout="vertical"
      >
        {config.title && (
          <Form.Item
            label="Title"
            rules={[
              {
                required: true,
                message: "Please input title!",
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Title"
              value={item.title}
              onChange={(e) => {
                onChange(index, "title", e.target.value);
              }}
              readOnly={readOnly}
            />
          </Form.Item>
        )}
        {config.abstract && (
          <Form.Item
            label="Abstract"
            rules={[
              {
                required: true,
                message: "Please input sub Title!",
              },
            ]}
          >
            <Input.TextArea
              type="text"
              placeholder="Abstract"
              value={item.abstract}
              onChange={(e) => {
                onChange(index, "abstract", e.target.value);
              }}
              readOnly={readOnly}
            />
          </Form.Item>
        )}
        {config.content && (
          <Form.Item
            label="Content (HTML)"
            rules={[
              {
                required: true,
                message: "Please input content!",
              },
            ]}
          >
            <HTMLEditor
              value={item.content}
              onChange={({ value }) => {
                onChange(index, "content", value);
              }}
            />
          </Form.Item>
        )}
        {config.abbr_name && (
          <Form.Item label="หัวข้อ (แถบด้านบน)">
            <Input
              type="text"
              placeholder="ก.พ."
              value={item.abbr_name}
              onChange={(e) => {
                onChange(index, "abbr_name", e.target.value);
              }}
              readOnly={readOnly}
            />
          </Form.Item>
        )}
        {config.point_name && (
          <Form.Item label="หัวข้อ (บนเส้นไทม์ไลน์)">
            <Input
              type="text"
              placeholder="กุมภาพันธ์"
              value={item.point_name}
              onChange={(e) => {
                onChange(index, "point_name", e.target.value);
              }}
              readOnly={readOnly}
            />
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

const styles = css`
  .prove-container {
    position: relative;
    font-family: "KaLaTeXa Display", "Thongterm", sans-serif;
    .ant-form-item {
      margin-bottom: 1rem;
    }
    .ant-btn-icon {
      margin-inline-end: 0px !important;
    }
  }
  .prove-section {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
  .prove-section-title {
    margin: 0;
    text-align: center;
  }
  img {
    /* max-height: 200px; */
  }
  label {
    font-weight: bold;
  }
  .prove-container .fr-box.fr-basic .fr-element {
    box-shadow: none;
    border: none;
  }
  .prove-nav {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    padding-top: 0.5rem;
    padding-inline: 0.5rem;
    position: sticky;
    top: 0rem;
    z-index: 100;
    background: #00334d;
    box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 10px -10px;
  }
  .prove-save,
  .prove-back {
    border: 1px solid #000;
    background: #2dd280;
    color: #00334d;
    font-style: normal;
    font-weight: 650;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .prove-back {
    background: orange;
  }
  .prove-active-chapter {
    border: 2px solid red !important;
    padding: 0.5rem;
  }
  .prove-chapter {
    border: 2px solid transparent;
    padding: 0.5rem;
  }
  .prove-back-popup .ant-modal-confirm-paragraph {
    margin-top: 0 !important;
  }
  .prove-slide-container {
    display: flex;
    /* flex-direction: column; */
    gap: 0.5rem;
    overflow-y: auto;
    padding-bottom: 0.5rem;
    .prove-slide-active,
    .prove-slide:hover {
      border: 2px solid #ff9243 !important;
      .prove-slide-name {
        color: #ff9243;
      }
    }
    .prove-slide {
      cursor: pointer;
      font-size: 12px;
      border-radius: 8px;
      background: white;
      border: 2px solid #fff;
      padding: 6px;
      .prove-slide-name {
        margin: 0;
        font-style: normal;
        font-weight: 600;
      }
      .prove-slide-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 60px;
        width: 200px;
        gap: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
          max-height: 60px;
        }
        p {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
        }
      }
    }
    /* .h-scroll {
      width: 100px;
      height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      transform-origin: right top;
      transform: rotate(-90deg) translateY(-100px);
    }

    .h-scroll > div {
      width: 100px;
      height: 100px;
      transform: rotate(90deg);
      transform-origin: right top;
    } */
  }
`;
export default ProvePage;
