import { useContext, useEffect, useState } from "react";
import { Badge, Button, Modal } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import contentContext from "../../../contexts/content_context";
import { SVGAlert } from "../../SVG/slide-edit";
import { templateConfigKey } from "../../../config/template-config";
import _uniqBy from "lodash/uniqBy";

const Thumbnails = ({ onChange, limitThumbnail, selected }) => {
  const { images, removeImage, slides, currentSlide } =
    useContext(contentContext);
  const [imageSelected, setImageSelected] = useState(selected);
  const [showImagesAll, setShowImagesAll] = useState(false);
  const [slideImages, setSlideImages] = useState([]);
  const _imagesUrl = images.map((img) => img.url);
  // const _images = _uniqBy(images, 'url');

  const _template = slides[currentSlide]?.template;
  const _config = templateConfigKey[_template];

  useEffect(() => {
    setImageSelected(selected);
  }, [selected]);
  useEffect(() => {
    const _slideImages = slides[currentSlide]?.images || [];
    setSlideImages(_slideImages);
  }, [currentSlide, showImagesAll, images]);

  const onSelected = (img) => {
    let _imgs;
    if (limitThumbnail === 1) {
      setImageSelected([img]);
      _imgs = [img];
    } else if (limitThumbnail === 2) {
      if (imageSelected.includes(img)) {
        _imgs = imageSelected.filter((item) => item !== img);
      } else {
        _imgs = [...imageSelected, img];
      }
      if (_imgs.length > 2) {
        _imgs.shift();
      }
    } else {
      if (imageSelected.includes(img)) {
        _imgs = imageSelected.filter((item) => item !== img);
      } else {
        _imgs = [...imageSelected, img];
      }
    }
    setImageSelected(_imgs);
    onChange(_imgs);
  };

  const confirmRemoveImage = (image, index) => {
    Modal.confirm({
      title: SVGAlert,
      okText: "ลบ",
      cancelText: "ยกเลิก",
      content: (
        <>
          <p
            style={{
              margin: "0 0 15px",
              color: "#00334D",
              fontFamily: "KaLaTeXa Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "110%",
            }}
          >
            ต้องการลบรูปภาพนี้หรือไม่?
          </p>
          <img src={image.url} style={{ width: "100%" }} alt="" />
        </>
      ),
      onOk() {
        if (imageSelected.includes(image.url)) {
          onSelected(_imagesUrl[index]);
        }
        removeImage(image, image.can_delete === false ? false : true);
      },
      onCancel() {},
    });
  };

  const checkMediaType = (url) => {
    try {
      const extension = url.split(".").pop();

      switch (extension) {
        case "mp4":
        case "webm":
        case "ogg":
          return "video";
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
          return "image";
        default:
          return "unknown";
      }
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="image--list">
      <ul>
        <li>
          <button
            onClick={() => setShowImagesAll(false)}
            className={showImagesAll === false ? "active" : ""}
          >
            รูปใน Section
          </button>
        </li>
        <li>
          <button
            onClick={() => setShowImagesAll(true)}
            className={showImagesAll === true ? "active" : ""}
          >
            รูปทั้งหมด
          </button>
        </li>
      </ul>
      {/* <Button onClick={() => setShowImagesAll(!showImagesAll)}>
        {showImagesAll ? "แสดงรูปภาพเฉพาะ section นี้" : "แสดงรูปภาพทั้งหมด"}
      </Button> */}
      <div className="images--item--list">
        {images.map((img, i) => {
          const url = img.url;
          // shown only selected images and slide images
          if (!showImagesAll) {
            if (!imageSelected.includes(url) && !slideImages.includes(url)) {
              return <></>;
            }
          }
          let isActive = imageSelected.includes(url) ? "active" : "";
          const order = imageSelected.indexOf(url) + 1;
          var _mimetype = checkMediaType(url);
          if (_mimetype === "video" && !_config.video) {
            return <></>;
          }
          return (
            <div className="item" key={img.image_id}>
              <Badge count={order}>
                <div
                  className="close--btn"
                  onClick={() => {
                    confirmRemoveImage(img, i);
                  }}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
                      fill="white"
                    />
                    <path
                      d="M16.3636 9C16.3636 10.953 15.5878 12.8259 14.2069 14.2069C12.8259 15.5878 10.953 16.3636 9 16.3636C7.04704 16.3636 5.17407 15.5878 3.79312 14.2069C2.41217 12.8259 1.63636 10.953 1.63636 9C1.63636 7.04704 2.41217 5.17407 3.79312 3.79312C5.17407 2.41217 7.04704 1.63636 9 1.63636C10.953 1.63636 12.8259 2.41217 14.2069 3.79312C15.5878 5.17407 16.3636 7.04704 16.3636 9ZM6.56448 5.9128C6.47806 5.82638 6.36085 5.77783 6.23864 5.77783C6.11642 5.77783 5.99921 5.82638 5.9128 5.9128C5.82638 5.99921 5.77783 6.11642 5.77783 6.23864C5.77783 6.36085 5.82638 6.47806 5.9128 6.56448L8.34924 9L5.9128 11.4355C5.87001 11.4783 5.83606 11.5291 5.8129 11.585C5.78975 11.6409 5.77783 11.7008 5.77783 11.7614C5.77783 11.8219 5.78975 11.8818 5.8129 11.9377C5.83606 11.9936 5.87001 12.0444 5.9128 12.0872C5.99921 12.1736 6.11642 12.2222 6.23864 12.2222C6.29915 12.2222 6.35907 12.2103 6.41498 12.1871C6.47089 12.1639 6.52169 12.13 6.56448 12.0872L9 9.65076L11.4355 12.0872C11.4783 12.13 11.5291 12.1639 11.585 12.1871C11.6409 12.2103 11.7008 12.2222 11.7614 12.2222C11.8219 12.2222 11.8818 12.2103 11.9377 12.1871C11.9936 12.1639 12.0444 12.13 12.0872 12.0872C12.13 12.0444 12.1639 11.9936 12.1871 11.9377C12.2103 11.8818 12.2222 11.8219 12.2222 11.7614C12.2222 11.7008 12.2103 11.6409 12.1871 11.585C12.1639 11.5291 12.13 11.4783 12.0872 11.4355L9.65076 9L12.0872 6.56448C12.13 6.52169 12.1639 6.47089 12.1871 6.41498C12.2103 6.35907 12.2222 6.29915 12.2222 6.23864C12.2222 6.17812 12.2103 6.1182 12.1871 6.06229C12.1639 6.00638 12.13 5.95559 12.0872 5.9128C12.0444 5.87001 11.9936 5.83606 11.9377 5.8129C11.8818 5.78975 11.8219 5.77783 11.7614 5.77783C11.7008 5.77783 11.6409 5.78975 11.585 5.8129C11.5291 5.83606 11.4783 5.87001 11.4355 5.9128L9 8.34924L6.56448 5.9128Z"
                      fill="black"
                    />
                  </svg>
                </div>
                {_mimetype === "video" ? (
                  <video
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    className={isActive ? "active" : "unactive"}
                    style={{ maxWidth: "100%" }}
                    key={img.image_id}
                    src={url}
                    onClick={() => {
                      onSelected(url);
                    }}
                  >
                    <source src={url} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    className={isActive ? "active" : "unactive"}
                    alt="thumbnail"
                    key={img.image_id}
                    src={url}
                    onClick={() => {
                      onSelected(url);
                    }}
                  />
                )}
              </Badge>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Thumbnails;
