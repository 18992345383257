import React, { useContext, useState, useEffect } from "react";
import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import _get from "lodash/get";
import _set from "lodash/set";
import FormColor from "../../Form/Elements/ColorPicker";
import contentContext from "../../../contexts/content_context";
import DropImages from "../../Form/Elements/DropImage";
import UploadList from "../../Form/Elements/ImageList";
import AnimeTitleOption from "../../Form/Elements/AnimeTitleOption";

let handleTimeout = null;

const FormCustomTitleEffect = ({ slide = {}, config, onChange }) => {
  const defaultChapter = {
    thumbnails: [],
    title_effect: {
      text_1: "",
      text_color_1: "#000000",
      background_color_1: "#4040bf",
      text_2: "",
      text_color_2: "#000000",
      background_color_2: "#bf4060",
      background_color_section: null,
      duration: "0.1",
    },
  };

  const getDefaultValue = (slide) => {
    const content = _get(slide, "data[0]", {});
    if (!content.title_effect) {
      return { ...defaultChapter };
    }
    return content;
  };
  const { contentId, site, addImages, currentSlide, imageMode } =
    useContext(contentContext);
  const [chapter, setChapter] = useState(getDefaultValue(slide));

  const handleInputChange = (field, value) => {
    let _chapter = { ...chapter };
    _set(_chapter, field, value);
    setChapter(_chapter);

    clearTimeout(handleTimeout);
    handleTimeout = setTimeout(() => {
      onChange({ ...slide, data: [_chapter] });
    }, 500);
  };

  useEffect(() => {
    setChapter(getDefaultValue(slide));
  }, [currentSlide, slide]);

  const title_effect = chapter.title_effect || {};

  return (
    <>
      <Form.Item
        label="Title 1"
        rules={[{ required: true, message: "Please input title!" }]}
        tooltip="This is a required field"
      >
        <TextArea
          type="text"
          placeholder="Title 1"
          value={title_effect.text_1}
          onChange={(e) => {
            handleInputChange("title_effect.text_1", e.target.value);
          }}
        />

        {/* <span className="char--remind">
          {(title_effect.text_1 || "").length}/20 ตัวอักษร
        </span> */}
      </Form.Item>
      <>
        <AnimeTitleOption
          label="Animetion (Title)"
          value={title_effect.animetion_title}
          onSelected={(val) => {
            handleInputChange("title_effect.animetion_title", val);
          }}
        />
      </>
      <FormColor
        title="เลือกสีตัวอักษร"
        value={title_effect.text_color_1}
        onChange={(value) => {
          handleInputChange("title_effect.text_color_1", value);
        }}
        key={"text_color_1"}
      />
      <FormColor
        title="เลือกสีพื้นตัวอักษร"
        value={title_effect.background_color_1}
        onChange={(value) => {
          handleInputChange("title_effect.background_color_1", value);
        }}
        key={"background_color_1"}
      />
      <Form.Item
        label="Title 2"
        rules={[{ required: true, message: "Please input title!" }]}
        tooltip="This is a required field"
      >
        <TextArea
          type="text"
          placeholder="Title 2"
          value={title_effect.text_2}
          onChange={(e) => {
            handleInputChange("title_effect.text_2", e.target.value);
          }}
        />
        {/* <span className="char--remind">
          {(title_effect.text_2 || "").length}/20 ตัวอักษร
        </span> */}
      </Form.Item>
      <FormColor
        title="เลือกสีตัวอักษร"
        value={title_effect.text_color_2}
        onChange={(value) => {
          handleInputChange("title_effect.text_color_2", value);
        }}
        key={"text_color_2"}
      />
      <FormColor
        title="เลือกสีพื้นตัวอักษร"
        value={title_effect.background_color_2}
        onChange={(value) => {
          handleInputChange("title_effect.background_color_2", value);
        }}
        key={"background_color_2"}
      />
      <DropImages
        isMultiple={config.thumbnails === "many"}
        ref_id={contentId}
        site={site}
        chapterImageSplit={title_effect.image_split}
        onUploaded={addImages}
        template={config.type}
        onCheckbox={(imgs) => {
          handleInputChange("title_effect.image_split", imgs);
        }}
      />
      {imageMode === "desktop" && (
        <UploadList
          selected={chapter.thumbnails || []}
          limitThumbnail={config.thumbnails}
          onChange={(imgs) => {
            handleInputChange("thumbnails", imgs);
          }}
        />
      )}
      {imageMode === "mobile" && (
        <UploadList
          selected={chapter.thumbnails_mobile || []}
          limitThumbnail={config.thumbnails}
          onChange={(imgs) => {
            handleInputChange("thumbnails_mobile", imgs);
          }}
        />
      )}
    </>
  );
};

export default FormCustomTitleEffect;
