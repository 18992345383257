import React, { useEffect, useRef, useState } from "react";
import { TRStorytelling } from "@thairath/tr-storytelling-modules";
import { useParams, useSearchParams } from "react-router-dom";
import { Global, css } from "@emotion/react";
import * as CIStyle from "../Modules/CiStyle";

const bc = new BroadcastChannel("storytelling-update");
const WidgetPreivew = () => {
  const [content, setContent] = useState([]);
  const [backgroundType, setBackgroundType] = useState("");
  const sto = useRef(null);
  const { site = "trol" } = useParams();

  useEffect(() => {
    bc.onmessage = (event) => {
      const { type } = event.data;
      // console.log("bc", event.data);
      if (type === "content") {
        bcContent(event.data);
      } else if (type === "scroll") {
        bcScroll(event.data);
      }
    };
    // bc.postMessage({ type: "preview-page-ready" });
  }, []);

  const bcScroll = (data) => {
    const { activeChapter, currentSlide, template } = data.data;
    const elm = document.querySelector(
      `#storytelling-slide-${currentSlide}-${activeChapter}`
    );
    if (!elm) return;
    if (template === "opener") {
      elm.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const bcContent = ({ data, currentSlide, type, _backgroundType }) => {
    if (type !== "content") return;
    if (_backgroundType) {
      setBackgroundType(_backgroundType);
    }
    if (!data) return;
    if (currentSlide === "new") {
      setContent(data);
    } else {
      setContent([data[currentSlide]]);
    }
  };
  // console.log(content, slide, currentSlide);

  const handleClickPreview = (type, slide, chapter, field) => {
    bc.postMessage({ event: "click-preview", type, slide, chapter, field });
  };

  return (
    <div>
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
      />
      {content.length > 0 && (
        <div id="storytelling-preview">
          <>
            {CIStyle[site] && (
              <Global
                styles={css`
                    #storytelling-render {
                      --primary-color: ${CIStyle[site]?.MainColor};
                      --heading-font: ${CIStyle[site]?.Heading_Font}};
                      --article-font: ${CIStyle[site]?.Article_Font};
                    }
                  `}
              />
            )}
            <TRStorytelling
              content={content}
              onClickEvent={handleClickPreview}
              backgroundType={backgroundType}
              onSite={"cms"}
            />
          </>
        </div>
      )}
      {content.length === 0 && (
        <div style={{ margin: "50px auto", textAlign: "center", width: 300 }}>
          Waiting data...
        </div>
      )}
    </div>
  );
};

export default WidgetPreivew;
