export const SVGDNDChapter = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: "grab" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H19C19.4142 12.75 19.75 12.4142 19.75 12Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.75 7C19.75 6.58579 19.4142 6.25 19 6.25H5C4.58579 6.25 4.25 6.58579 4.25 7C4.25 7.41421 4.58579 7.75 5 7.75H19C19.4142 7.75 19.75 7.41421 19.75 7Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.75 17C19.75 16.5858 19.4142 16.25 19 16.25H5C4.58579 16.25 4.25 16.5858 4.25 17C4.25 17.4142 4.58579 17.75 5 17.75H19C19.4142 17.75 19.75 17.4142 19.75 17Z"
      fill="black"
    />
  </svg>
);

export const SVGDeleteChapter = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.62162 5.71429H6.91892V12.5714H5.62162V5.71429Z" fill="black" />
    <path d="M9.51352 5.71429H10.8108V12.5714H9.51352V5.71429Z" fill="black" />
    <path
      d="M0.432434 2.28571V3.42857H1.72973V14.8571C1.72973 15.1602 1.86641 15.4509 2.1097 15.6653C2.35299 15.8796 2.68296 16 3.02703 16H13.4054C13.7495 16 14.0794 15.8796 14.3227 15.6653C14.566 15.4509 14.7027 15.1602 14.7027 14.8571V3.42857H16V2.28571H0.432434ZM3.02703 14.8571V3.42857H13.4054V14.8571H3.02703Z"
      fill="black"
    />
    <path d="M5.62162 0H10.8108V1.14286H5.62162V0Z" fill="black" />
  </svg>
);

export const SVGAddSlide = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 0C3.808 0 0 3.808 0 8.5C0 13.192 3.808 17 8.5 17C13.192 17 17 13.192 17 8.5C17 3.808 13.192 0 8.5 0ZM8.5 15.3C4.743 15.3 1.7 12.257 1.7 8.5C1.7 4.743 4.743 1.7 8.5 1.7C12.257 1.7 15.3 4.743 15.3 8.5C15.3 12.257 12.257 15.3 8.5 15.3ZM12.75 7.65H9.35V4.25C9.35 3.7825 8.9675 3.4 8.5 3.4C8.0325 3.4 7.65 3.7825 7.65 4.25V7.65H4.25C3.7825 7.65 3.4 8.0325 3.4 8.5C3.4 8.9675 3.7825 9.35 4.25 9.35H7.65V12.75C7.65 13.2175 8.0325 13.6 8.5 13.6C8.9675 13.6 9.35 13.2175 9.35 12.75V9.35H12.75C13.2175 9.35 13.6 8.9675 13.6 8.5C13.6 8.0325 13.2175 7.65 12.75 7.65Z"
      fill="black"
    />
  </svg>
);
export const SVGDropImage = (
  <svg
    width="44"
    height="40"
    viewBox="0 0 44 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.1867 1.33333C38.1867 0.979711 38.0463 0.640573 37.7962 0.390524C37.5462 0.140476 37.207 0 36.8534 0H7.52008C7.16646 0 6.82732 0.140476 6.57727 0.390524C6.32722 0.640573 6.18675 0.979711 6.18675 1.33333V2.66667H38.1867V1.33333Z"
      fill="#00334D"
    />
    <path
      d="M40.8267 6.66667C40.8267 6.31304 40.6863 5.97391 40.4362 5.72386C40.1862 5.47381 39.847 5.33333 39.4934 5.33333H4.82675C4.47313 5.33333 4.13399 5.47381 3.88394 5.72386C3.63389 5.97391 3.49341 6.31304 3.49341 6.66667V8H40.8267V6.66667Z"
      fill="#00334D"
    />
    <path
      d="M40.8267 10.6667H3.17341C2.50861 10.6667 1.87102 10.9308 1.40093 11.4009C0.930842 11.8709 0.666748 12.5085 0.666748 13.1733V37.4933C0.666748 38.1581 0.930842 38.7957 1.40093 39.2658C1.87102 39.7359 2.50861 40 3.17341 40H40.8267C41.4916 40 42.1291 39.7359 42.5992 39.2658C43.0693 38.7957 43.3334 38.1581 43.3334 37.4933V13.1733C43.3334 12.5085 43.0693 11.8709 42.5992 11.4009C42.1291 10.9308 41.4916 10.6667 40.8267 10.6667ZM9.41342 15.2667C10.2045 15.2667 10.9779 15.5013 11.6357 15.9408C12.2935 16.3803 12.8062 17.005 13.1089 17.7359C13.4117 18.4668 13.4909 19.2711 13.3366 20.047C13.1822 20.823 12.8013 21.5357 12.2418 22.0951C11.6824 22.6545 10.9697 23.0355 10.1938 23.1898C9.41785 23.3441 8.61359 23.2649 7.88268 22.9622C7.15178 22.6594 6.52706 22.1467 6.08754 21.4889C5.64801 20.8312 5.41341 20.0578 5.41341 19.2667C5.41341 18.2058 5.83484 17.1884 6.58499 16.4382C7.33513 15.6881 8.35255 15.2667 9.41342 15.2667ZM38.0001 34.6667H6.00008L15.9467 24.7067C16.1241 24.5308 16.3637 24.4321 16.6134 24.4321C16.8632 24.4321 17.1028 24.5308 17.2801 24.7067L22.1867 29.6133L28.9467 22.6667C29.1241 22.4908 29.3637 22.3921 29.6134 22.3921C29.8632 22.3921 30.1028 22.4908 30.2801 22.6667L38.0001 30.3867V34.6667Z"
      fill="#00334D"
    />
  </svg>
);
export const SVGAlert = (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
      fill="#00334D"
    />
    <path
      d="M57 32C57 45.8071 45.8071 57 32 57C18.1929 57 7 45.8071 7 32C7 18.1929 18.1929 7 32 7C45.8071 7 57 18.1929 57 32Z"
      fill="#FF9243"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59 32C59 46.9117 46.9117 59 32 59C17.0883 59 5 46.9117 5 32C5 17.0883 17.0883 5 32 5C46.9117 5 59 17.0883 59 32ZM32 57C45.8071 57 57 45.8071 57 32C57 18.1929 45.8071 7 32 7C18.1929 7 7 18.1929 7 32C7 45.8071 18.1929 57 32 57Z"
      fill="white"
    />
    <path
      d="M45.0964 35.7298L36.9762 22.6902C35.9104 20.9792 34.2801 20 32.5 20C30.7199 20 29.0896 20.9792 28.0238 22.6876L19.9036 35.7298C18.8241 37.462 18.7059 39.3093 19.5809 40.798C20.4544 42.288 22.1603 43.1429 24.259 43.1429H40.741C42.8397 43.1429 44.5456 42.288 45.4191 40.7993C46.2941 39.3106 46.1759 37.4633 45.0964 35.7298ZM32.5 38.5762C31.327 38.5762 30.3711 37.6565 30.3711 36.5265C30.3711 35.395 31.3257 34.474 32.5 34.474C33.6743 34.474 34.6289 35.395 34.6289 36.5265C34.6289 37.6565 33.673 38.5762 32.5 38.5762ZM34.7429 28.7522C34.7278 28.7932 32.8187 33.3413 32.8187 33.3413C32.7665 33.4657 32.6401 33.5477 32.5014 33.5477C32.3626 33.5477 32.2363 33.4657 32.1841 33.3413L30.2736 28.7919C30.1582 28.5163 30.098 28.2222 30.0964 27.9251C30.0964 26.6482 31.1746 25.6094 32.5 25.6094C32.8893 25.6103 33.2725 25.7023 33.6168 25.8773C33.9611 26.0523 34.2562 26.3052 34.4767 26.6142C34.6973 26.9233 34.8367 27.2792 34.8831 27.6516C34.9294 28.024 34.8814 28.4016 34.7429 28.7522Z"
      fill="white"
    />
  </svg>
);
export const SVGAlertSuccess = (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
      fill="#00334D"
    />
    <path
      d="M57 32C57 45.8071 45.8071 57 32 57C18.1929 57 7 45.8071 7 32C7 18.1929 18.1929 7 32 7C45.8071 7 57 18.1929 57 32Z"
      fill="#2DD280"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59 32C59 46.9117 46.9117 59 32 59C17.0883 59 5 46.9117 5 32C5 17.0883 17.0883 5 32 5C46.9117 5 59 17.0883 59 32ZM32 57C45.8071 57 57 45.8071 57 32C57 18.1929 45.8071 7 32 7C18.1929 7 7 18.1929 7 32C7 45.8071 18.1929 57 32 57Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9356 28.5596C23.087 28.3977 23.2668 28.2693 23.4647 28.1817C23.6626 28.094 23.8747 28.0489 24.0889 28.0489C24.3032 28.0489 24.5153 28.094 24.7132 28.1817C24.9111 28.2693 25.0909 28.3977 25.2423 28.5596L28.7 32.2523L38.7732 21.4944C38.9245 21.3356 39.1036 21.2102 39.3003 21.1254C39.497 21.0406 39.7074 20.998 39.9196 21.0001C40.1317 21.0022 40.3414 21.0489 40.5366 21.1376C40.7318 21.2263 40.9087 21.3553 41.0572 21.5171L44.5165 25.2116C44.8218 25.5318 44.9956 25.9683 44.9999 26.4252C45.0042 26.8822 44.8385 27.3223 44.5393 27.649L29.8314 43.3549C29.68 43.5136 29.5008 43.6389 29.3041 43.7236C29.1073 43.8082 28.8968 43.8507 28.6847 43.8484C28.4726 43.8461 28.263 43.7992 28.0678 43.7104C27.8727 43.6215 27.6959 43.4924 27.5475 43.3305L19.4778 34.7124C19.3264 34.551 19.2062 34.3593 19.1242 34.1482C19.0422 33.9371 19 33.7109 19 33.4824C19 33.2538 19.0422 33.0276 19.1242 32.8165C19.2062 32.6054 19.3264 32.4137 19.4778 32.2523L22.9356 28.5596Z"
      fill="white"
    />
  </svg>
);
export const SVGAlertFailed = (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64 32C64 49.67 49.67 64 32 64C14.33 64 0 49.67 0 32C0 14.33 14.33 0 32 0C49.67 0 64 14.33 64 32Z"
      fill="#00334D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59 32C59 46.91 46.91 59 32 59C17.09 59 5 46.91 5 32C5 17.09 17.09 5 32 5C46.91 5 59 17.09 59 32Z"
      fill="white"
    />
    <path
      d="M32 7C18.19 7 7 18.19 7 32C7 45.81 18.19 57 32 57C45.81 57 57 45.81 57 32C57 18.19 45.81 7 32 7ZM41.67 37.33C43.72 39.52 45.51 41.15 43.06 43.75C40.62 46.35 39.08 44.45 37.03 42.27L32.01 36.93L26.99 42.27C24.94 44.45 23.4 46.35 20.95 43.75C18.51 41.15 20.29 39.52 22.35 37.33L27.37 31.99L22.35 26.65C20.3 24.47 18.51 22.83 20.95 20.23C23.4 17.64 24.94 19.53 26.99 21.71L32.01 27.04L37.03 21.71C39.08 19.53 40.62 17.63 43.06 20.23C45.51 22.83 43.72 24.47 41.67 26.65L36.65 31.99L41.67 37.33Z"
      fill="#CC1F1F"
    />
  </svg>
);

export const SVGChangeLayout = (
  <img
    src="https://cdn-icons-png.flaticon.com/128/9980/9980049.png"
    loading="lazy"
    alt="Convert "
    title="Convert "
    width="24"
    height="24"
  />
);
