import { Input } from "antd";
import { Form } from "antd";
import { templateConfig } from "../../../config/template-config";
import _get from "lodash/get";

const FormAbbr = ({
  template,
  value,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
}) => {
  
  const _config = templateConfig.find((item) => item.type === template);
  const abbrLength = _get(_config, "max_length.abbr", 20);
  return (
    <>
      <Form.Item
        label="หัวข้อ (แถบด้านบน)"
        rules={[
          { required: true, message: "Please input หัวข้อ (แถบด้านบน)!" },
        ]}
      >
        <Input
          type="text"
          placeholder="ก.พ."
          value={value}
          onBlur={() => onBlur("")}
          onFocus={() => {
            onFocus("abbr_name");
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <span
          className="char--remind"
          style={{
            color: value.length > abbrLength && "red",
            borderColor: value.length > abbrLength && "red",
          }}
        >
          {value.length}/{abbrLength} ตัวอักษร
        </span>
      </Form.Item>
    </>
  );
};

export default FormAbbr;
