const BlankPage = ({ type }) => {
  const url = new URL(window.location.href);
  const p = url.pathname.split("/");
  const currentSite = p[2]
  const contentId = p[3]

  const getRedirectLink = (site = "trol", id = null) => {
    let url;
    switch (site) {
      case "trplus":
        const trplus_url = process.env.REACT_APP_TRPLUS_CMS_URL;
        url = id
          ? `${trplus_url}/content/edit/${id}`
          : `${trplus_url}/content`;
        break;
      case "mirror":
        const mirror_url = process.env.REACT_APP_MIRROR_CMS_URL;
        url = id
          ? `${mirror_url}/content/edit/${id}`
          : `${mirror_url}/content`;
        break;
      default:
        const default_url = process.env.REACT_APP_TROL_CMS_URL;
        url = id
          ? `${default_url}/content/edit/${id}`
          : `${default_url}/content/storytelling`;
        break;
    }

    window.location.href = url;
    return (
      <div style={{ padding: "20px 100px" }}>
        <h1>กำลัง login</h1>
      </div>
    );
  };

  return getRedirectLink(currentSite, contentId)

  // return (
  //   <div style={{ padding: "20px 100px" }}>
  //     <h1>CMS Storytelling ต้องกดมาจาก cms และเพราะต้องมี ID และ token</h1>

  //     {type === "login" && (
  //       <>
  //         <a
  //           href={process.env.REACT_APP_TROL_CMS_URL + "/content/edit/" + p[3]}
  //         >
  //           ไป login ก่อน
  //         </a>
  //       </>
  //     )}

  //     {type === "blank" && (
  //       <>
  //         <p>เลือกเข้าได้ตามสบาย</p>
  //         <a
  //           href={process.env.REACT_APP_TROL_CMS_URL + "/content/edit/2697680"}
  //         >
  //           ไม่มี storytelling (ห้ามกด save ไม่งั้นจะมีทันที)
  //         </a>
  //         <br />
  //         <a
  //           href={process.env.REACT_APP_TROL_CMS_URL + "/content/edit/2697681"}
  //         >
  //           มี storytelling เอาไว้เล่น
  //         </a>
  //         <br />
  //       </>
  //     )}
  //     <br />
  //     <br />
  //     <h3>กดมาจาก CMS ได้ตามนี้เลย (ที่วงแดงๆ)</h3>
  //     <img
  //       src="https://media.thairathaws.store/image/M0f8t0bN3ZwLIp1V2uhuE5LchhCLaGf6opTjpv7hwexG9Ee9vA1.jpg"
  //       alt=""
  //       width="500"
  //     />
  //     {/*
  //     <p>หรือใส่ ID เอาเอง</p>
  //     <input
  //       type="text"
  //       placeholder="ใส่ ID ที่นี่"
  //       value={id}
  //       onChange={(e) => {
  //         setId(e.target.value);
  //       }}
  //     />
  //     <Link to={`/layout/trol/${id}`}>
  //       <button>กด</button>
  //     </Link> */}
  //   </div>
  // );
};

export default BlankPage;
