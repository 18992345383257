import './App.css';
import './styles.css';
import './trol-theme.css';
import { Route, Routes } from 'react-router-dom';

import TemplatePage from './pages/template';
import LayoutPage from './pages/edit';
import WidgetPage from './pages/widget';
import BlankPage from './pages/blank';
import PreviewPage from './pages/preview';
import ProvePage from './pages/prove';
import { useAuth } from './contexts/auth_content';
import { PageProvider } from './pages/pageContext';

function App() {
  const { authenticated } = useAuth();

  // waiting for authenticated
  if (authenticated === null) {
    return <></>;
  } else if (authenticated === false) {
    return <BlankPage type='login' />;
  }

  return (
    <PageProvider>
      <Routes>
        <Route exact path='/' element={<BlankPage type='login' />} />
        <Route exact path='/:site/preview' element={<PreviewPage />} />
        <Route path='/template/:site/:contentId' element={<TemplatePage />} />
        <Route path='/layout/:site/:contentId' element={<LayoutPage />} />
        <Route path='/widget/:site/:contentId' element={<WidgetPage />} />
        <Route path='/prove/:site/:contentId' element={<ProvePage />} />
      </Routes>
    </PageProvider>
  );
}

export default App;
