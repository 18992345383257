import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Tooltip } from "antd";
import _cloneDeep from "lodash/cloneDeep";
import _uniqBy from "lodash/uniqBy";

// import { DesktopOutlined, ExportOutlined } from "@ant-design/icons";
import ContentContext from "../contexts/content_context";
import SlideSection from "../Modules/Layout/SlideSection";
import LayoutHeader from "../Modules/Layout/Header";
import SlideEditor from "../Modules/Layout/SlideEditor";
import SlideList from "../Modules/Layout/SlideList";
import SideBar from "../Modules/Layout/SideBar";
// import SlidePreview from "../Modules/Layout/SlidePreview";
import mockupTemplate from "../config/mockup-template.json";
import ModalChangeTemplate from "../Modules/Layout/ModelChangeTemplate";

import {
  SVGCollapableDown,
  SVGCollapableLeft,
  // SVGNewWindow,
  // SVGPreviewDesktop,
  // SVGPreviewMobile,
  // SVGPreviewTablet,
} from "../Modules/SVG/layout";
import ResponsiveBar from "../Modules/Layout/ResponsiveBar";
import { SVGAlertFailed, SVGAlertSuccess } from "../Modules/SVG/slide-edit";
import { getCookie } from "../lib/cookie";

const { info } = Modal;
let refreshTimeout = null;

const bc = new BroadcastChannel("storytelling-update");

export const randomSlidesTmpId = (data, force = false) => {
  return data.map((slide) => {
    if (force) {
      slide.tmp_id = Math.random().toString(36).substr(2, 9);
    } else {
      slide.tmp_id = slide.tmp_id || Math.random().toString(36).substr(2, 9);
    }

    slide.data = randomChapgersTmpId(slide.data, force);
    return slide;
  });
};

export const randomChapgersTmpId = (data, force = false) => {
  return data.map((chapter) => {
    if (force) {
      chapter.tmp_id = Math.random().toString(36).substr(2, 9);
    } else {
      chapter.tmp_id =
        chapter.tmp_id || Math.random().toString(36).substr(2, 9);
    }
    return chapter;
  });
};

const LayoutPage = () => {
  const { site = "trol", contentId = "" } = useParams();
  const history = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const applyTemplate = urlParams.get("apply_template");
  const [slides, setSlides] = useState([]);
  const [contentTitle, setContentTitle] = useState("");
  const [currentSlide, setCurrentSlide] = useState("new");
  const [images, setImages] = useState([]);
  const [saveContentProgress, setSaveContentProgress] = useState("idle");
  const [screenWidth, setScreenWidth] = useState();
  const [boxWidth, setBoxWidth] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [changeTemplateModal, setChangeTemplateModal] = useState(false);
  const [imageMode, setImageMode] = useState(false);
  const [backgroundType, setBackgroundType] = useState();
  const collapseMainControl = useRef(null);
  const collapseMenu = useRef(null);
  const collapseMain = useRef(null);
  const collapseSide = useRef(null);
  const templateLayout = useRef(null);
  const handleLoad = () => {
    setLoading(false);
  };
  useEffect(() => {
    prepareContent(contentId);
  }, [contentId]);

  useEffect(() => {
    boardcastUpdate(slides);
  }, [backgroundType, imageMode, slides, currentSlide]);

  useEffect(() => {
    backupSlides(slides);
  }, [slides]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDataChanged) {
        const confirmationMessage =
          "Are you sure you want to leave? Your changes may not be saved.";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDataChanged]);

  useEffect(() => {
    // restoreSlides();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      switch (site) {
        case "trplus":
          const _infoTRPlus = getCookie("storytelling_trplus_info");
          try {
            if (_infoTRPlus) {
              const infoJsonTRPlus = decodeURIComponent(_infoTRPlus);
              const infoTRPlus = JSON.parse(infoJsonTRPlus);
              const { preview_url } = infoTRPlus;
              if (preview_url) setPreviewUrl(preview_url);
            }
          } catch (error) {
            console.error("Error :", error);
          }
          break;
        case "mirror":
          const _infoMirror = getCookie("storytelling_mirror_info");
          try {
            if (_infoMirror) {
              const infoJsonMirror = decodeURIComponent(_infoMirror);
              const infoMirror = JSON.parse(infoJsonMirror);
              const { preview_url } = infoMirror;
              if (preview_url) setPreviewUrl(preview_url);
            }
          } catch (error) {
            console.error("Error :", error);
          }
          break;
        default:
          setPreviewUrl(
            `${
              process.env[`REACT_APP_${site.toUpperCase()}_WEB_URL`]
            }/content/${contentId}?key=eyJ0cyI6MjUyNDU4MjgwMCwic2VjcmV0IjoiZF5jb2RlbDtwIn0=&random=${Math.random()}`
          );
      }
    }, 1000);
  }, [site]);
  

  const boardcastUpdate = (_slides) => {
    bc.postMessage({
      currentSlide,
      data: { ...(_slides || slides), windowMode: imageMode || "desktop" },
      type: "content",
      _backgroundType: backgroundType,
    });
  };

  const prepareContent = async (contentId) => {
    if (templateLayout.current === null) return;
    setScreenWidth(templateLayout.current.getBoundingClientRect().width);
    setBoxWidth(templateLayout.current.getBoundingClientRect().width);
    if (contentId) {
      // apply content from template or fetch content from server
      if (applyTemplate) {
        const template = mockupTemplate.find(
          (item) => item.id.toString() === applyTemplate.toString()
        );

        if (template) {
          const _images = extractImagesFromSlides(template.slides);
          await getImages(_images);
          setSlides(template.slides);
          setIsDataChanged(true);
          history({ pathname: `/layout/${site}/${contentId}` });
        } else {
          fetchContent(contentId);
        }

        // get content from server
      } else {
        fetchContent(contentId);
      }
      fetchContentTitle(contentId);
    }
  };

  const extractImagesFromSlides = (slides) => {
    // add images from template to images list
    const _imagesForTemplate = [];
    slides.forEach((slide, i) => {
      // add images each data to images slide
      slides[i].images = [];
      slide.data.forEach((chapter) => {
        chapter.thumbnails.forEach((thumbnail) => {
          _imagesForTemplate.push({
            url: thumbnail,
            src: thumbnail,
            timestamp: 0,
            can_delete: false, // lock image for template
          });
          slides[i].images.push(thumbnail);
        });
      });
    });

    return _imagesForTemplate;
  };

  const fetchContent = async (contentId) => {
    const { data } = await axios.get(`/api/content/get/${site}/${contentId}`, {
      withCredentials: true,
    });
    if (data.status === 200) {
      if (data.data && typeof data.data === "object") {
        const _slides = randomSlidesTmpId(data.data);
        const _images = extractImagesFromSlides(_slides);
        await getImages(_images);
        setSlides(_slides);
        setTimeout(() => {
          boardcastUpdate(_slides);
        }, 1000);
      }
    }
    if (data.status === 404) {
      // setSlides([]);
    }
    if (data.status === 401) {
      document.cookie =
        "storytelling_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "storytelling_trplus_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "storytelling_mirror_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.href = `/layout/${site}/${contentId}`;
    }
  };

  const fetchContentTitle = async (contentId) => {
    const { data } = await axios.get(
      `/api/content/get-title/${site}/${contentId}`,
      {
        withCredentials: true,
      }
    );

    if (data.status === 200) {
      if (data.data) {
        setContentTitle(data.data);
      }
    }
  };

  const getImages = async (_imagesSlide = []) => {
    const { data, status } = await axios.get(
      `/api/image/get-ref/${site}/${contentId}`,
      {
        withCredentials: true,
      }
    );

    if (status === 200) {
      if (data.length > 0) {
        const _imageUniq = _uniqBy(
          [...images, ...data, ..._imagesSlide].sort((a, b) => -a.timestamp),
          "url"
        );
        setImages(_imageUniq);
      } else {
        setImages([...images, ..._imagesSlide].sort((a, b) => -a.timestamp));
      }
    }
  };

  const duplicateSlide = (index) => {
    // duplicate slide and insert after current slide
    const _slides = slides;
    const _slide = randomSlidesTmpId([_cloneDeep(_slides[index])], true);
    _slides.splice(index, 0, _slide[0]);
    setSlides([..._slides]);
    setCurrentSlide(index + 1);
    setIsDataChanged(true);
  };

  const addSlide = (slide, i) => {
    if (i) {
      const _slide = slides.splice(i);
      setSlides([...slides, slide, ..._slide]);
      setCurrentSlide(i);
    } else {
      const _slide = [...slides, slide];
      setSlides(_slide);
      setCurrentSlide(_slide.length - 1);
    }
    setIsDataChanged(true);
  };

  const removeSlide = (index) => {
    const _slides = slides;
    _slides.splice(index, 1);
    setSlides([..._slides]);
    setCurrentSlide("new");
    setIsDataChanged(true);
  };

  const moveSlide = (target, moveto, isSwap = true) => {
    setIsDataChanged(true);
    if (isSwap) {
      const _slides = slides;
      const _target = _slides[target];
      const _moveto = _slides[moveto];
      _slides[target] = _moveto;
      _slides[moveto] = _target;
      setSlides([..._slides]);
    } else {
      moveSlideBefore(target, moveto);
    }
  };

  const moveSlideBefore = (target, moveto) => {
    setIsDataChanged(true);
    const _slide = slides.splice(target, 1);
    if (target > moveto) {
      slides.splice(moveto, 0, _slide[0]);
    } else {
      slides.splice(moveto, 0, _slide[0]);
    }
    setSlides([...slides]);
  };

  const addImages = (new_images) => {
    setImages([...new_images, ...images]);

    const _slides = slides[currentSlide];
    _slides.images = [
      ...new_images.map((img) => img.url),
      ...(_slides.images || []),
    ];
    setSlides([...slides]);
  };

  // call_server=true, delete from image list component. remove from the list and call server to delete
  // call_server=false, delete from froala editor. just remove from the list
  const removeImage = async (image, call_server = true) => {
    let delete_image_link = "";

    if (call_server) {
      const { data } = await axios.post(`/api/image/delete`, {
        image,
        site,
      });
      delete_image_link = data.src;
    } else {
      delete_image_link = image.src;
    }

    const _images = images.filter((img) => img.url !== delete_image_link);
    for (let i = 0; i < slides.length; i++) {
      const _slide = slides[i];
      // remove image that upload each slide
      const _slideImages = (_slide.images || []).filter(
        (img) => img !== delete_image_link
      );
      _slide.images = _slideImages;

      // remove image that selected each slide
      _slide.data = (_slide.data || []).map((chapter) => {
        const _chapter = chapter;
        _chapter.thumbnails = (chapter.thumbnails || []).filter(
          (img) => img !== delete_image_link
        );
        return _chapter;
      });
    }
    setSlides([...slides]);
    setImages([..._images]);
  };

  const saveCurrentSlide = (slide) => {
    setIsDataChanged(true);
    const _slides = slides;
    _slides[currentSlide] = slide;
    setSlides(_slides);
    refreshPreview();
  };

  const refreshPreview = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }
    refreshTimeout = setTimeout(() => {
      setSlides([...slides]);
    }, 1000);
  };

  const saveContent = async ({ title, storytelling }, show_alert = true) => {
    if (!isDataChanged) {
      return;
    }

    setSaveContentProgress("saving");
    const { data } = await axios.post(
      `/api/content/save/${site}/${contentId}`,
      { storytelling: storytelling || slides, title: title || contentTitle },
      {
        withCredentials: true,
      }
    );
    if (data.status === 200) {
      show_alert &&
        info({
          title: SVGAlertSuccess,
          content: (
            <>
              บันทึกเนื้อหาสำเร็จ
              <div className="ant-modal-confirm-btns">
                <button
                  className="ant-btn-default"
                  onClick={() => {
                    setSaveContentProgress("idle");
                    Modal.destroyAll();
                  }}
                >
                  ปิด
                </button>
                <a
                  target="_blank"
                  href={previewUrl}
                  alt="Preview"
                  rel="noreferrer"
                  className="preview--btn"
                >
                  ดู Preview
                </a>
              </div>
            </>
          ),
          // cancelText: "ปิด",
          footer: null,
          centered: true,
          onOk() {
            setSaveContentProgress("idle");
          },
        });
      setSaveContentProgress("saved");
      setIsDataChanged(false);
      localStorage.removeItem(`storytelling-${site}-${contentId}`);
    } else {
      show_alert &&
        info({
          title: SVGAlertFailed,
          content: (
            <>
              บันทึกเนื้อหาไม่สำเร็จ
              <div className="ant-modal-confirm-btns">
                <button
                  className="ant-btn-default"
                  onClick={() => Modal.destroyAll()}
                >
                  ปิด
                </button>
                <a
                  target="_blank"
                  href={previewUrl}
                  alt="Preview"
                  rel="noreferrer"
                  className="preview--btn"
                >
                  ดู Preview
                </a>
              </div>
            </>
          ),
          footer: null,
          centered: true,
          onOk() {},
        });
      setSaveContentProgress("idle");
    }
  };

  const backupSlides = (data) => {
    if (isDataChanged) {
      localStorage.setItem(
        `storytelling-${site}-${contentId}`,
        JSON.stringify(data)
      );
    }
  };

  const restoreSlides = () => {
    const data = localStorage.getItem(`storytelling-${site}-${contentId}`);
    if (!data) return;

    const _slides = JSON.parse(data);

    setTimeout(() => {
      boardcastUpdate(_slides);
    }, 1000);

    Modal.confirm({
      title: "คุณมีข้อมูลล่าสุดที่ยังไม่ได้บันทึก ต้องการกู้คืนหรือไม่",
      content: (
        <>
          <iframe
            src={`/${site}/preview`}
            title="template"
            width={"100%"}
            style={{ border: "none", height: "calc(100vh - 300px)" }}
          />
        </>
      ),
      okText: "กู้ข้อมูล",
      cancelText: "ยกเลิก",
      onOk() {
        setSlides(JSON.parse(data));
        setIsDataChanged(true);
        localStorage.removeItem(`storytelling-${site}-${contentId}`);
      },
      onCancel() {
        localStorage.removeItem(`storytelling-${site}-${contentId}`);
      },
      width: "calc(100vw - 100px)",
    });
  };

  // console.log(slides);

  const collapseSideBar = () => {
    collapseMenu.current.classList.toggle("aside--preview--collapse");
    collapseMain.current.classList.toggle("aside--preview--collapse");
    collapseSide.current.classList.toggle("aside--preview--collapse");
    collapseMainControl.current.classList.toggle("aside--preview--collapse");
    // setScreenWidth(templateLayout.current.getBoundingClientRect().width)
    // console.log(
    //   `templateLayout.current.getBoundingClientRect().width`,
    //   templateLayout.current.getBoundingClientRect().width
    // );
    // console.log(`screenWidth>>>`, screenWidth);
  };
  const collapseSlideMenu = () => {
    collapseSide.current.classList.toggle("go--down");
    collapseMain.current.classList.toggle("add--height");
  };
  return (
    <ContentContext.Provider
      value={{
        contentId,
        slides,
        setSlides,
        currentSlide,
        setCurrentSlide,
        addSlide,
        duplicateSlide,
        removeSlide,
        moveSlide,
        site,
        images,
        addImages,
        removeImage,
        saveCurrentSlide,
        changeTemplateModal,
        setChangeTemplateModal,
        imageMode,
        setImageMode,
        backgroundType,
        setBackgroundType,
      }}
    >
      <div className="App">
        <div className="main--control" ref={collapseMainControl}>
          <LayoutHeader
            onSave={saveContent}
            saveContentProgress={saveContentProgress}
            contentTitle={contentTitle}
            callbackSaveTitle={(title) => {
              setIsDataChanged(true);
              setContentTitle(title);
            }}
            isDataChanged={isDataChanged}
          />
          <div className="aside">
            <SideBar page="layout" />
          </div>
          <div
            className={
              screenWidth > 768
                ? "main--component desktop--view"
                : "main--component"
            }
            ref={collapseMain}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: "100%",
              }}
            >
              <div className="scroll--overflow" />
              <div className="aside--preview" ref={collapseMenu}>
                {currentSlide === "new" ? <SlideList /> : <SlideEditor />}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // width: "100%",
                height: "100dvh",
              }}
            >
              <div className="template--layout" ref={templateLayout}>
                <button onClick={collapseSideBar} className="collapable--btn">
                  {SVGCollapableLeft}
                </button>
                <ResponsiveBar
                  maxWidth={boxWidth}
                  onChange={setScreenWidth}
                  onNewWindow={() => {
                    window.open(`/${site}/preview`);
                    setTimeout(() => {
                      boardcastUpdate(slides);
                    }, 2000);
                  }}
                />
                <div
                  className="preview--template"
                  style={{ width: "auto", margin: "auto" }}
                >
                  <iframe
                    onLoad={handleLoad}
                    src={`/${site}/preview`}
                    title="template"
                    width={screenWidth !== null ? screenWidth : "100%"}
                    // style={{ border: "none", height: "calc(100% - 5px)" }}
                  />
                  {Loading ? (
                    <div className="loading">
                      <div className="loader"></div>
                    </div>
                  ) : null}
                </div>
                {/* <SlidePreview content={slides} currentSlide={currentSlide} /> */}
                <button
                  onClick={collapseSlideMenu}
                  className="collapable--bottom--btn"
                >
                  {SVGCollapableDown}
                </button>
              </div>
              <div className="preview--component" ref={collapseSide}>
                <SlideSection />
              </div>
              {changeTemplateModal && currentSlide !== "new" && (
                <ModalChangeTemplate />
              )}
            </div>
          </div>
        </div>
      </div>
    </ContentContext.Provider>
  );
};

export default LayoutPage;
