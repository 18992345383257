import { Button, Form } from "antd";

const AddNewChapter = ({ onAddChapter, config }) => {
  return (
    <div
      style={
        config.type !== "timeline_1" &&
        config.type !== "timeline_2" &&
        config.type !== "bubble_1"
          ? { position: "relative", bottom: "0px", width: "100%" }
          : {}
      }
    >
      <Form.Item>
          <>
            {["timeline_1", "timeline_2", "bubble_1"].includes(config.type) ? (
              <Button
                className="add--item"
                onClick={() => {
                  onAddChapter();
                }}
                style={{ color: "#4096ff", background: "#4096ff" }}
              >
                {config.dataset_text || "Add more to section"}
              </Button>
            ) : (
              <Button
                className="add--next--point"
                onClick={() => {
                  onAddChapter();
                }}
                style={{ color: "#4096ff", background: "#4096ff" }}
              >
                {/* <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="10" fill="#707070" />
              <path
                d="M12 17V7"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M7 12L17 12"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg> */}
                {config.dataset_text || "Add more to section +"}
              </Button>
            )}
          </>
        <hr />
      </Form.Item>
    </div>
  );
};

export default AddNewChapter;
