import { Form, Select } from "antd";

const StoryStyles = ({ value, onSelected, label }) => {
  return (
    <>
      <Form.Item label={label || "การจัดวาง (Style)"}>
        <Select
          value={value||"normal"}
          defaultValue={"normal"}
          onChange={onSelected}
          style={{ height: "auto" }}
        >
          <Select.Option value="normal">
            <span>Normal</span>
          </Select.Option>
          <Select.Option value="style1">
            <span
              style={{
                fontSize: 35,
                fontFamily:
                  "'KondolarThai-Bold','KaLaTeXa Display', 'Thongterm'",
              }}
            >
              style1
            </span>
          </Select.Option>
          <Select.Option value="style2">
            <span
              style={{
                fontSize: 25,
                fontFamily: "'KaLaTeXa Display', 'Thongterm'",
              }}
            >
              style2
            </span>
          </Select.Option>
          <Select.Option value="style3">
            {" "}
            <span
              style={{
                fontSize: 16,
                fontFamily: "'Thongterm','KaLaTeXa Display'",
              }}
            >
              style3
            </span>
          </Select.Option>
          {/* <Select.Option value="style4">style4</Select.Option> */}
        </Select>
      </Form.Item>
    </>
  );
};

export default StoryStyles;
