import { ColorPicker, Form } from "antd";
import ColorThief from "colorthief";

const presetColors = [
  "#000000",
  "#000000E0",
  "#000000A6",
  "#00000073",
  "#00000040",
  "#00000026",
  "#0000001A",
  "#00000012",
  "#0000000A",
  "#00000005",
  "#F5222D",
  "#FA8C16",
  "#FADB14",
  "#8BBB11",
  "#52C41A",
  "#13A8A8",
  "#1677FF",
  "#2F54EB",
  "#722ED1",
  "#EB2F96",
  "#F5222D4D",
  "#FA8C164D",
  "#FADB144D",
  "#8BBB114D",
  "#52C41A4D",
  "#13A8A84D",
  "#1677FF4D",
  "#2F54EB4D",
  "#722ED14D",
  "#EB2F964D",
];
const FormColor = ({
  title = "เลือกสีพื้นหลัง",
  value,
  onChange,
  image,
  enableImage = false,
  id,
}) => {
  const getImageColor = () => {
    if (image) {
      const colorThief = new ColorThief();
      const img = document.querySelector("#example-image");
      const color = colorThief.getColor(img);
      onChange("#" + color.map((x) => x.toString(16)).join(""));
    }
  };

  return (
    <>
      <Form.Item name="color-picker" label={title} className="color--picker">
        <ColorPicker
          key={id}
          presets={[
            {
              label: "Recommended",
              colors: presetColors,
            },
            {
              label: "Recent",
              colors: [],
            },
          ]}
          defaultValue={value ?? "#000000"}
          onChange={(v) => {
            onChange(v.toHexString());
          }}
          showText
        />
        {enableImage && (
          <>
            <img
              src={image}
              style={{ width: "100%", display: image ? "block" : "none" }}
              id="example-image"
              onLoad={() => {
                if (value) return;
                getImageColor();
              }}
              onClick={getImageColor}
              crossorigin="anonymous"
              title="คลิ๊กที่รูปเพื่อดูดสี"
              alt="คลิ๊กที่รูปเพื่อดูดสี"
            />
          </>
        )}
      </Form.Item>
    </>
  );
};

export default FormColor;
