import { Radio, Form } from "antd";
import React, { Fragment } from "react";

const RedioEmbed = ({ ...props }) => {
  return (
    <div className="media--section">
      <Form.Item label={`Media (เลือกได้ 1 รูปหรือคลิป)`} required>
        <Radio.Group {...props}>
          <div className="media--list">
            <Radio value="image">Image</Radio>
            <Radio value="video">Video</Radio>
          </div>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default RedioEmbed;
