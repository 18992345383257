import { Form, Select } from "antd";

const AnimeTitleOption = ({ value, onSelected, label }) => {
  return (
    <>
      <Form.Item label={label || "การจัดวาง (Style)"}>
        <Select
          value={value||"normal"}
          defaultValue={"normal"}
          onChange={onSelected}
          style={{ height: "auto" }}
        >
          <Select.Option value="normal">
            <span>Normal</span>
          </Select.Option>
          <Select.Option value="animetion1">
            <span>animetion 1</span>
          </Select.Option>
          <Select.Option value="animetion2">
            <span>animetion 2</span>
          </Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default AnimeTitleOption;
