import { Input, Form } from "antd";
import { templateConfig } from "../../../config/template-config";
import _get from "lodash/get";

const FormPointName = ({
  template,
  value,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
}) => {
  const _config = templateConfig.find((item) => item.type === template);
  const pointLength = _get(_config, "max_length.point", 20);
  return (
    <>
      <Form.Item
        label="หัวข้อ (บนเส้นไทม์ไลน์)"
        rules={[
          { required: true, message: "Please input หัวข้อ (บนเส้นไทม์ไลน์)!" },
        ]}
      >
        <Input
          type="text"
          placeholder="กุมภาพันธ์"
          value={value}
          onBlur={() => onBlur("")}
          onFocus={() => {
            onFocus("point_name");
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <span
          className="char--remind"
          style={{
            color: value.length > pointLength && "red",
            borderColor: value.length > pointLength && "red",
          }}
        >
          {value.length}/{pointLength} ตัวอักษร
        </span>
      </Form.Item>
    </>
  );
};

export default FormPointName;
