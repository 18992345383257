import { Form, Select } from "antd";

const EffectOpener2 = ({ value, onSelected, label }) => {
  return (
    <>
      <Form.Item label={label || "เอฟเฟค (Effect)"}>
        <Select
          value={value||"normal"}
          defaultValue={"normal"}
          onChange={onSelected}
          style={{ height: "auto" }}
        >
          <Select.Option value="normal">
            <span>Normal</span>
          </Select.Option>
          <Select.Option value="effect1">
            <span>Effect 1</span>
          </Select.Option>
          <Select.Option value="effect2">
            <span>Effect 2</span>
          </Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default EffectOpener2;
