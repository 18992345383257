import { Tooltip, Form, Checkbox, Radio } from "antd";
import { useContext, useRef, useState, useEffect, Fragment } from "react";
import axios from "axios";
import { FileImageOutlined } from "@ant-design/icons";
import TooltipImage from "../../../lib/custom-tooltip";
import contentContext from "../../../contexts/content_context";
import { templateConfigKey } from "../../../config/template-config";
import { SVGDropImage } from "../../SVG/slide-edit";

const processImage = (_file) => {
  return new Promise((resolve, reject) => {
    const file = _file;
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          // Create a canvas to draw the image on
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0);

          // Re-encode the image as a new file
          canvas.toBlob(
            function (blob) {
              if (blob) {
                const newFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: file.lastModified,
                });
                resolve(newFile);
              } else {
                reject(new Error("Blob creation failed"));
              }
            },
            file.type,
            1
          );
        };
        img.onerror = () => reject(new Error("Image load failed"));
      };
      reader.onerror = () => reject(new Error("File read failed"));
      reader.readAsDataURL(file);
    } else {
      reject(new Error("No file provided"));
    }
  });
};

const DropImages = ({
  limitThumbnail = 1,
  ref_id,
  site,
  template,
  onCheckbox,
  chapterImageSplit,
  device,
}) => {
  const { addImages, setImageMode, setBackgroundType } =
    useContext(contentContext);
  const [images, setImages] = useState([]);
  // const [cleanImages, setCleanImages] = useState("");
  const [dragOver, setDragOver] = useState(false);
  const uploadFileRef = useRef(null);
  const [progress, setProgress] = useState(null);
  const [ImageSplit, setImageSplit] = useState(chapterImageSplit || false);
  const [DesktopTab, setDesktopTab] = useState(true);
  const _config = templateConfigKey[template] || "";
  const _device = device === undefined ? _config.device : device;
  useEffect(() => {
    if (DesktopTab) setImageMode("desktop");
    else setImageMode("mobile");
  }, [DesktopTab]);

  useEffect(() => {
    setImageSplit(chapterImageSplit);
  }, [chapterImageSplit]);

  const checkSizeFile = (files) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSizeInBytes = file.size;
      const fileSizeInMegabytes = fileSizeInBytes / (1024 * 1024); // convert to megabytes

      if (fileSizeInMegabytes > 5) {
        alert("File size exceeds 5 MB");
        return true;
      }
      return false;
    }
  };

  const uploadImages = async (info, fromDrop) => {
    setProgress(0);

    let files;
    if (fromDrop) {
      files = info;
    } else {
      files = info.target.files;
    }
    if (checkSizeFile(files)) {
      return;
    }
    const _formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      var _cleanImages = "";
      // check this image file is gif or video dont go to processImage this clear script
      if (files[i].type === "image/gif" || files[i].type.includes("video/")) {
        _cleanImages = files[i];
      } else {
        await processImage(files[i])
          .then((newFile) => {
            _cleanImages = newFile;
          })
          .catch((error) => {
            _cleanImages = files[i];
            console.error("Error processing image:", error);
          });
      }
      if (_cleanImages) {
        _formData.append("images", _cleanImages);
      }
    }
    _formData.append("ref_id", ref_id);
    _formData.append("site", site);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/image/upload`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: _formData,
      withCredentials: true,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    };

    const { data, status } = await axios.request(config);
    if (status === 200) {
      if (data.length > 0) {
        const _images = [...images, ...data];
        setImages([...images, ...data]);
        addImages(data);
      }
    }
    setProgress(null);
    uploadFileRef.current.value = "";
  };
  const textThumnail = () => {
    if (template === "chat_bubble") {
      return "ภาพพื้นหลังหน้าจอ (เลือกได้ 1 รูป)";
    }
    if (limitThumbnail === 999) {
      return "Images";
    } else if (limitThumbnail === "none") {
      return "";
    } else if (_config.video) {
      return `Media (เลือกได้ 1 รูปหรือคลิป)`;
    } else {
      return `Image (เลือกได้ ${limitThumbnail} รูป)`;
    }
  };
  return (
    <>
      <Form.Item
        className="uploadFile"
        label={textThumnail()}
        required
        tooltip={
          limitThumbnail === 999
            ? "กรุณาเลือกรูปภาพ"
            : limitThumbnail === "none"
            ? ""
            : `กรุณาเลือกรูปภาพอย่างน้อย ${limitThumbnail} รูป`
        }
      >
        <input
          type="file"
          name="images"
          multiple
          id="drop-file-zone"
          accept={_config.video ? "image/*,video/*" : "image/*"}
          onChange={uploadImages}
          style={{ display: "none" }}
          ref={uploadFileRef}
        />
        <div
          className={`dropFileZone ${dragOver ? "hover" : ""}`}
          onClick={() => {
            uploadFileRef.current.click();
          }}
          onDrop={(e) => {
            e.preventDefault();
            uploadImages(e.dataTransfer.files, true);
            setDragOver(false);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setDragOver(false);
          }}
          onDragEnd={(e) => {
            e.preventDefault();
            setDragOver(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            if (dragOver) return;
            setDragOver(true);
            console.log(e.dataTransfer.files);
          }}
        >
          {SVGDropImage}
          <br />
          {_config.video ? (
            <Fragment>Drop or Click to upload media</Fragment>
          ) : (
            <Fragment>
              Drop or Click to upload images
              {progress && (
                <div className="progress">
                  <div
                    className="progress-bar"
                    style={{ width: `${progress}%`, backgroundColor: "#ccc" }}
                  >
                    {progress}%
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </div>

        {template !== "image_pile" && (
          <div className="img--size--box">
            {_device && (
              <div className="check--box--value">
                <Checkbox
                  checked={chapterImageSplit}
                  onChange={() => {
                    setImageSplit(!ImageSplit);
                    onCheckbox(!ImageSplit);
                  }}
                >
                  ใส่รูปแยกขนาดสำหรับ Desktop/Mobile
                </Checkbox>
              </div>
            )}
            {ImageSplit && (
              <div className="toggle--tab">
                <ul>
                  <li>
                    <button
                      onClick={() => setDesktopTab(!DesktopTab)}
                      className={DesktopTab === true ? "active" : ""}
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.4333 10.0385V2.03846C15.4333 1.95513 15.4069 1.88301 15.3542 1.82212C15.3014 1.76122 15.2389 1.73077 15.1667 1.73077H1.83333C1.76111 1.73077 1.69861 1.76122 1.64583 1.82212C1.59306 1.88301 1.56667 1.95513 1.56667 2.03846V10.0385C1.56667 10.1218 1.59306 10.1939 1.64583 10.2548C1.69861 10.3157 1.76111 10.3462 1.83333 10.3462H15.1667C15.2389 10.3462 15.3014 10.3157 15.3542 10.2548C15.4069 10.1939 15.4333 10.1218 15.4333 10.0385ZM16.5 2.03846V12.5C16.5 12.9231 16.3694 13.2853 16.1083 13.5865C15.8472 13.8878 15.5333 14.0385 15.1667 14.0385H10.6333C10.6333 14.2756 10.6778 14.524 10.7667 14.7837C10.8556 15.0433 10.9444 15.2708 11.0333 15.4663C11.1222 15.6619 11.1667 15.8013 11.1667 15.8846C11.1667 16.0513 11.1139 16.1955 11.0083 16.3173C10.9028 16.4391 10.7778 16.5 10.6333 16.5H6.36667C6.22222 16.5 6.09722 16.4391 5.99167 16.3173C5.88611 16.1955 5.83333 16.0513 5.83333 15.8846C5.83333 15.7949 5.87778 15.6538 5.96667 15.4615C6.05556 15.2692 6.14444 15.0449 6.23333 14.7885C6.32222 14.5321 6.36667 14.2821 6.36667 14.0385H1.83333C1.46667 14.0385 1.15278 13.8878 0.891667 13.5865C0.630556 13.2853 0.5 12.9231 0.5 12.5V2.03846C0.5 1.61538 0.630556 1.25321 0.891667 0.951923C1.15278 0.650641 1.46667 0.5 1.83333 0.5H15.1667C15.5333 0.5 15.8472 0.650641 16.1083 0.951923C16.3694 1.25321 16.5 1.61538 16.5 2.03846Z"
                          fill="black"
                        />
                      </svg>
                      Desktop
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setDesktopTab(!DesktopTab)}
                      className={DesktopTab === false ? "active" : ""}
                    >
                      <svg
                        width="10"
                        height="17"
                        viewBox="0 0 10 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.33667 0H1.66333C1.22219 0 0.799114 0.178748 0.487179 0.496923C0.175243 0.815097 0 1.24663 0 1.6966V15.3026C0 16.2401 0.745 17 1.66333 17H8.33583C8.77705 17 9.20021 16.8213 9.51228 16.5031C9.82435 16.185 9.99978 15.7534 10 15.3034V1.6966C10 1.24663 9.82476 0.815097 9.51282 0.496923C9.20089 0.178748 8.77781 0 8.33667 0ZM5 16.15C4.425 16.15 3.95833 15.7701 3.95833 15.3C3.95833 14.83 4.425 14.45 5 14.45C5.575 14.45 6.04167 14.83 6.04167 15.3C6.04167 15.7701 5.575 16.15 5 16.15ZM8.33333 13.6H1.66667V1.7H8.33333V13.6Z"
                          fill="black"
                        />
                      </svg>
                      Mobile
                    </button>
                  </li>
                </ul>
                <h2>รูปสำหรับแสดงบน Desktop</h2>
                <p>
                  <Tooltip title={TooltipImage} placement="right">
                    <span>
                      ขนาดรูปภาพ [1280 x 720]
                      <span
                        role="img"
                        aria-label="question-circle"
                        style={{ display: "inline-block", marginLeft: 5 }}
                        className="anticon anticon-question-circle ant-form-item-tooltip"
                      >
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="question-circle"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                          <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
                        </svg>
                      </span>
                    </span>
                  </Tooltip>
                </p>
                <p>คลิกที่รูปด้านล่างเพื่อเลือกใช้งาน</p>
              </div>
            )}
          </div>
        )}
        {/* <Tooltip title={TooltipImage} placement="right">
            <span>
              ขนาดรูปภาพ [1280 x 720]
              <span
                role="img"
                aria-label="question-circle"
                style={{ display: "inline-block", marginLeft: 5 }}
                className="anticon anticon-question-circle ant-form-item-tooltip"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="question-circle"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                  <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
                </svg>
              </span>
            </span>
          </Tooltip> */}
      </Form.Item>
    </>
  );
};

export default DropImages;
