import { Form } from "antd";
import HTMLEditor from "../froala";
import { useEffect } from "react";

const FormDescription = ({
  value,
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  site,
  contentId,
  onFileUpdate,
}) => {
  useEffect(() => {
    // console.log("value", value);
  }, [value]);

  return (
    <>
      <Form.Item
        label={
          <span>
            * <strong>Content (HTML)</strong>
          </span>
        }
        rules={[{ required: true, message: "Please input content!" }]}
      >
        <HTMLEditor
          value={value}
          onblur={() => onBlur("")}
          onfocus={() => {
            onFocus("content");
          }}
          onChange={({ value }) => {
            onChange(value);
          }}
          site={site}
          contentId={contentId}
          onFileUpdate={onFileUpdate}
        />
      </Form.Item>
    </>
  );
};

export default FormDescription;
