import React, { useState, useContext, useEffect } from "react";
import contentContext from "../../../contexts/content_context";
import { Input } from "antd";
const EmbedUpload = ({ onChange, value }) => {
  const { TextArea } = Input;
  const [embedCode, setEmbedCode] = useState(value || "");
  const [videoUrl, setVideoUrl] = useState(value || "");
  useEffect(() => {
    setVideoUrl(value);
  }, [value]);
  const handleEmbedCodeChange = (event) => {
    setEmbedCode(event.target.value);
    const urlMatch = event.target.value.match(/src="([^"]*)"/i);
    if (urlMatch && urlMatch[1]) {
      setVideoUrl(urlMatch[1]);
      onChange(urlMatch[1]);
    }
  };

  return (
    <div>
      <div>
        <TextArea
          placeholder={`<iframe width="966" height="543" src="https://www.youtube.com/embed/M3JmTjI_YZ0" title="ปลาเก๋ายักษ์กินปลาฉลามทั้งตัว" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`}
          autoSize={{
            minRows: 4,
            maxRows: 4,
          }}
          onChange={handleEmbedCodeChange}
          value={embedCode}
        />
      </div>
      {videoUrl && (
        <iframe
          src={videoUrl}
          width="560"
          height="315"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullscreen
        />
      )}
    </div>
  );
};

export default EmbedUpload;
