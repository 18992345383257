export const SVGCollapableDown = (
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.94615 0.613651C7.08563 0.473912 7.25249 0.362452 7.43697 0.285774C7.62146 0.209096 7.81987 0.168735 8.02065 0.167047C8.22143 0.165359 8.42054 0.202377 8.60637 0.275942C8.7922 0.349506 8.96104 0.458144 9.10301 0.595515C9.24499 0.732887 9.35727 0.896242 9.4333 1.07605C9.50933 1.25585 9.54759 1.44851 9.54584 1.64278C9.5441 1.83704 9.50238 2.02903 9.42313 2.20753C9.34388 2.38603 9.22869 2.54747 9.08427 2.68243L4.1049 7.50032L9.08427 12.3182C9.22869 12.4532 9.34388 12.6146 9.42313 12.7931C9.50238 12.9716 9.5441 13.1636 9.54584 13.3579C9.54758 13.5521 9.50933 13.7448 9.4333 13.9246C9.35727 14.1044 9.24499 14.2678 9.10301 14.4051C8.96103 14.5425 8.7922 14.6511 8.60637 14.7247C8.42054 14.7983 8.22143 14.8353 8.02065 14.8336C7.81987 14.8319 7.62145 14.7916 7.43697 14.7149C7.25249 14.6382 7.08563 14.5267 6.94615 14.387L0.897713 8.53471C0.614236 8.26035 0.45499 7.88828 0.45499 7.50032C0.45499 7.11237 0.614236 6.7403 0.897713 6.46593L6.94615 0.613651Z"
      fill="#fff"
    />
  </svg>
);

export const SVGCollapableLeft = (
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.94615 0.613651C7.08563 0.473912 7.25249 0.362452 7.43697 0.285774C7.62146 0.209096 7.81987 0.168735 8.02065 0.167047C8.22143 0.165359 8.42054 0.202377 8.60637 0.275942C8.7922 0.349506 8.96104 0.458144 9.10301 0.595515C9.24499 0.732887 9.35727 0.896242 9.4333 1.07605C9.50933 1.25585 9.54759 1.44851 9.54584 1.64278C9.5441 1.83704 9.50238 2.02903 9.42313 2.20753C9.34388 2.38603 9.22869 2.54747 9.08427 2.68243L4.1049 7.50032L9.08427 12.3182C9.22869 12.4532 9.34388 12.6146 9.42313 12.7931C9.50238 12.9716 9.5441 13.1636 9.54584 13.3579C9.54758 13.5521 9.50933 13.7448 9.4333 13.9246C9.35727 14.1044 9.24499 14.2678 9.10301 14.4051C8.96103 14.5425 8.7922 14.6511 8.60637 14.7247C8.42054 14.7983 8.22143 14.8353 8.02065 14.8336C7.81987 14.8319 7.62145 14.7916 7.43697 14.7149C7.25249 14.6382 7.08563 14.5267 6.94615 14.387L0.897713 8.53471C0.614236 8.26035 0.45499 7.88828 0.45499 7.50032C0.45499 7.11237 0.614236 6.7403 0.897713 6.46593L6.94615 0.613651Z"
      fill="black"
    />
  </svg>
);

export const SVGBackToCMS = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0C6.735 0 0 6.735 0 15C0 23.265 6.735 30 15 30C23.265 30 30 23.265 30 15C30 6.735 23.265 0 15 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
      fill="white"
    />
  </svg>
);

export const SVGLayoutTemplate = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2502 30H11.2499V11.2521H30.0002V26.2499C30.0002 27.2445 29.6051 28.1984 28.9019 28.9016C28.1986 29.6049 27.2447 30 26.2502 30ZM-0.000244141 3.75006C-0.000244141 2.75548 0.39485 1.80164 1.09812 1.09837C1.8014 0.395094 2.75524 0 3.74981 0H26.2502C27.2447 0 28.1986 0.395094 28.9019 1.09837C29.6051 1.80164 30.0002 2.75548 30.0002 3.75006V7.50199H-0.000244141V3.75006ZM7.50034 30H3.74981C2.75524 30 1.8014 29.6049 1.09812 28.9016C0.39485 28.1984 -0.000244141 27.2445 -0.000244141 26.2499V11.2521H7.50034V30Z"
      fill="white"
    />
  </svg>
);

export const SVGLayoutLayout = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6.75C0 4.65327 0 3.60489 0.342541 2.77793C0.799265 1.67531 1.67531 0.799265 2.77793 0.342541C3.60489 0 4.65327 0 6.75 0C8.84673 0 9.89511 0 10.7221 0.342541C11.8247 0.799265 12.7007 1.67531 13.1575 2.77793C13.5 3.60489 13.5 4.65327 13.5 6.75C13.5 8.84673 13.5 9.89511 13.1575 10.7221C12.7007 11.8247 11.8247 12.7007 10.7221 13.1575C9.89511 13.5 8.84673 13.5 6.75 13.5C4.65327 13.5 3.60489 13.5 2.77793 13.1575C1.67531 12.7007 0.799265 11.8247 0.342541 10.7221C0 9.89511 0 8.84673 0 6.75Z"
      fill="#fff"
    />
    <path
      d="M16.8426 2.77793C16.5 3.60489 16.5 4.65327 16.5 6.75C16.5 8.84673 16.5 9.89511 16.8426 10.7221C17.2992 11.8247 18.1754 12.7007 19.2779 13.1575C20.1049 13.5 21.1533 13.5 23.25 13.5C25.3467 13.5 26.3951 13.5 27.2221 13.1575C28.3246 12.7007 29.2008 11.8247 29.6574 10.7221C30 9.89511 30 8.84673 30 6.75C30 4.65327 30 3.60489 29.6574 2.77793C29.2008 1.67531 28.3246 0.799265 27.2221 0.342541C26.3951 0 25.3467 0 23.25 0C21.1533 0 20.1049 0 19.2779 0.342541C18.1754 0.799265 17.2992 1.67531 16.8426 2.77793Z"
      fill="#fff"
    />
    <path
      d="M0.342541 19.2779C0 20.1049 0 21.1533 0 23.25C0 25.3467 0 26.3951 0.342541 27.2221C0.799265 28.3246 1.67531 29.2008 2.77793 29.6574C3.60489 30 4.65327 30 6.75 30C8.84673 30 9.89511 30 10.7221 29.6574C11.8247 29.2008 12.7007 28.3246 13.1575 27.2221C13.5 26.3951 13.5 25.3467 13.5 23.25C13.5 21.1533 13.5 20.1049 13.1575 19.2779C12.7007 18.1754 11.8247 17.2992 10.7221 16.8426C9.89511 16.5 8.84673 16.5 6.75 16.5C4.65327 16.5 3.60489 16.5 2.77793 16.8426C1.67531 17.2992 0.799265 18.1754 0.342541 19.2779Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.25 16.575C23.8713 16.575 24.375 17.0787 24.375 17.7V22.125H28.8C29.4213 22.125 29.925 22.6287 29.925 23.25C29.925 23.8715 29.4213 24.375 28.8 24.375H24.375V28.8C24.375 29.4215 23.8713 29.925 23.25 29.925C22.6287 29.925 22.125 29.4215 22.125 28.8V24.375H17.7C17.0787 24.375 16.575 23.8715 16.575 23.25C16.575 22.6287 17.0787 22.125 17.7 22.125H22.125V17.7C22.125 17.0787 22.6287 16.575 23.25 16.575Z"
      fill="#fff"
    />
  </svg>
);

export const SVGLayoutWidget = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 5.78816C7.59833 6.16346 4.75 9.24902 4.75 12.9998C4.75 17.0039 7.99594 20.2498 12 20.2498C15.7508 20.2498 18.8364 17.4015 19.2117 13.7498H12C11.5858 13.7498 11.25 13.4141 11.25 12.9998V5.78816ZM3.25 12.9998C3.25 8.16735 7.16751 4.24984 12 4.24984C12.4142 4.24984 12.75 4.58563 12.75 4.99984V12.2498H20C20.4142 12.2498 20.75 12.5856 20.75 12.9998C20.75 17.8323 16.8325 21.7498 12 21.7498C7.16751 21.7498 3.25 17.8323 3.25 12.9998Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 4.67349V9.49984H20.3264C19.7633 7.11544 17.8844 5.23651 15.5 4.67349ZM14.998 3.06149C18.6183 3.51202 21.4878 6.38151 21.9384 10.0018C22.0066 10.5499 21.5523 10.9998 21 10.9998H14.5C14.2239 10.9998 14 10.776 14 10.4998V3.99984C14 3.44756 14.45 2.99329 14.998 3.06149Z"
      fill="white"
    />
  </svg>
);

export const SVGBackButton = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7074 11.3741C15.098 10.9836 15.098 10.3504 14.7074 9.95989C14.3169 9.56936 13.6837 9.56936 13.2932 9.95989L7.95989 15.2932C7.76462 15.4885 7.66699 15.7444 7.66699 16.0003C7.66699 16.1359 7.69398 16.2652 7.74287 16.3831C7.79167 16.5011 7.86401 16.6116 7.95989 16.7074L13.2932 22.0408C13.6837 22.4313 14.3169 22.4313 14.7074 22.0408C15.098 21.6502 15.098 21.0171 14.7074 20.6266L11.0812 17.0003H24.0003C24.5526 17.0003 25.0003 16.5526 25.0003 16.0003C25.0003 15.448 24.5526 15.0003 24.0003 15.0003H11.0812L14.7074 11.3741Z"
      fill="black"
    />
  </svg>
);

export const SVGCollapseButton = (
  <svg
    className="collapse--btn"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6579 4.78947C13.6579 4.97841 13.5047 5.13158 13.3158 5.13158L9.21053 5.13158C9.02159 5.13158 8.86842 4.97841 8.86842 4.78947V0.68421C8.86842 0.495271 9.02159 0.342105 9.21053 0.342105C9.39947 0.342105 9.55263 0.495271 9.55263 0.68421V3.96356L13.416 0.100201C13.5496 -0.0334002 13.7662 -0.0334002 13.8998 0.100201C14.0334 0.233801 14.0334 0.45041 13.8998 0.58401L10.0364 4.44737H13.3158C13.5047 4.44737 13.6579 4.60053 13.6579 4.78947Z"
      fill="black"
    />
    <path
      d="M1.21051 8.34209C1.21051 8.15315 1.36368 7.99999 1.55262 7.99999H5.65788C5.84682 7.99999 5.99999 8.15315 5.99999 8.34209V12.4474C5.99999 12.6363 5.84682 12.7895 5.65788 12.7895C5.46894 12.7895 5.31578 12.6363 5.31578 12.4474V9.16801L1.45242 13.0314C1.31882 13.165 1.10221 13.165 0.968608 13.0314C0.835008 12.8978 0.835008 12.6812 0.968608 12.5476L4.83197 8.6842H1.55262C1.36368 8.6842 1.21051 8.53103 1.21051 8.34209Z"
      fill="black"
    />
  </svg>
);
export const SVGExpandButton = (
  <svg
    className="expand--btn"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.21053 0.342105C8.21053 0.153166 8.36369 0 8.55263 0H12.6579C12.8468 0 13 0.153166 13 0.342105V4.44737C13 4.63631 12.8468 4.78947 12.6579 4.78947C12.469 4.78947 12.3158 4.63631 12.3158 4.44737V1.16802L8.45243 5.03138C8.31883 5.16498 8.10222 5.16498 7.96862 5.03138C7.83502 4.89778 7.83502 4.68117 7.96862 4.54757L11.832 0.684211H8.55263C8.36369 0.684211 8.21053 0.531045 8.21053 0.342105ZM4.54757 7.96862C4.68117 7.83502 4.89778 7.83502 5.03138 7.96862C5.16498 8.10222 5.16498 8.31883 5.03138 8.45243L1.16802 12.3158H4.44737C4.63631 12.3158 4.78947 12.469 4.78947 12.6579C4.78947 12.8468 4.63631 13 4.44737 13H0.342105C0.153166 13 0 12.8468 0 12.6579V8.55263C0 8.36369 0.153166 8.21053 0.342105 8.21053C0.531045 8.21053 0.684211 8.36369 0.684211 8.55263V11.832L4.54757 7.96862Z"
      fill="black"
    />
  </svg>
);

export const SVGDefaultAvatar = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 18C36 21.5601 34.9444 25.0402 32.9666 28.0003C30.9888 30.9603 28.1777 33.2674 24.8888 34.6298C21.5998 35.9922 17.9808 36.3487 14.4893 35.6541C10.9978 34.9596 7.79063 33.2453 5.27339 30.7279C2.75615 28.2106 1.04189 25.0033 0.347384 21.5116C-0.34712 18.02 0.00932455 14.4008 1.37164 11.1117C2.73396 7.82263 5.04097 5.01141 8.00093 3.03355C10.9609 1.05568 14.4409 0 18.0008 0C20.3645 0 22.705 0.465584 24.8888 1.37017C27.0725 2.27475 29.0568 3.60062 30.7281 5.27208C32.3995 6.94353 33.7253 8.92784 34.6299 11.1117C35.5344 13.2956 36 15.6362 36 18ZM21.8134 17.8545H14.1586C12.6558 17.7511 11.1725 18.2418 10.0279 19.2211C8.88328 20.2003 8.16884 21.5898 8.03833 23.0906V26.5237C8.03953 27.0009 8.22962 27.4582 8.56704 27.7956C8.90446 28.1331 9.36175 28.3232 9.83893 28.3244H26.1308C26.6076 28.3232 27.0645 28.1334 27.4019 27.7964C27.7392 27.4595 27.9296 27.0028 27.9314 26.526V23.086C27.8003 21.5857 27.0856 20.1968 25.9411 19.218C24.7965 18.2392 23.3135 17.7488 21.8111 17.8522L21.8134 17.8545ZM12.6353 10.7041C12.6353 11.7621 12.9491 12.7965 13.5369 13.6762C14.1247 14.556 14.9602 15.2417 15.9377 15.6466C16.9152 16.0515 17.9908 16.1574 19.0285 15.951C20.0662 15.7446 21.0194 15.2351 21.7675 14.4869C22.5157 13.7387 23.0252 12.7855 23.2316 11.7477C23.438 10.71 23.3321 9.63433 22.9272 8.65679C22.5223 7.67925 21.8366 6.84373 20.9569 6.2559C20.0772 5.66806 19.0429 5.3543 17.9849 5.3543C16.5661 5.3543 15.2054 5.91793 14.2022 6.92121C13.1989 7.92448 12.6353 9.28521 12.6353 10.7041Z"
      fill="white"
    />
  </svg>
);

export const SVGUserMenu = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9997 9.1697C16.8123 8.98345 16.5589 8.87891 16.2947 8.87891C16.0305 8.87891 15.7771 8.98345 15.5897 9.1697L11.9997 12.7097L8.4597 9.1697C8.27234 8.98345 8.01889 8.87891 7.7547 8.87891C7.49052 8.87891 7.23707 8.98345 7.0497 9.1697C6.95598 9.26266 6.88158 9.37326 6.83081 9.49512C6.78004 9.61698 6.75391 9.74769 6.75391 9.8797C6.75391 10.0117 6.78004 10.1424 6.83081 10.2643C6.88158 10.3861 6.95598 10.4967 7.0497 10.5897L11.2897 14.8297C11.3827 14.9234 11.4933 14.9978 11.6151 15.0486C11.737 15.0994 11.8677 15.1255 11.9997 15.1255C12.1317 15.1255 12.2624 15.0994 12.3843 15.0486C12.5061 14.9978 12.6167 14.9234 12.7097 14.8297L16.9997 10.5897C17.0934 10.4967 17.1678 10.3861 17.2186 10.2643C17.2694 10.1424 17.2955 10.0117 17.2955 9.8797C17.2955 9.74769 17.2694 9.61698 17.2186 9.49512C17.1678 9.37326 17.0934 9.26266 16.9997 9.1697Z"
      fill="black"
    />
  </svg>
);

export const SVGPreviewMobile = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
  >
    <path
      d="M11.2833 0H2.47453C1.89222 0 1.33376 0.231321 0.922007 0.643076C0.510252 1.05483 0.278931 1.61329 0.278931 2.1956V19.8033C0.278931 21.0166 1.26233 22 2.47453 22H11.2822C11.8646 22 12.4232 21.7687 12.8351 21.357C13.2471 20.9453 13.4786 20.3868 13.4789 19.8044V2.1956C13.4789 1.61329 13.2476 1.05483 12.8359 0.643076C12.4241 0.231321 11.8656 0 11.2833 0ZM6.87893 20.9C6.11993 20.9 5.50393 20.4083 5.50393 19.8C5.50393 19.1917 6.11993 18.7 6.87893 18.7C7.63793 18.7 8.25393 19.1917 8.25393 19.8C8.25393 20.4083 7.63793 20.9 6.87893 20.9ZM11.2789 17.6H2.47893V2.2H11.2789V17.6Z"
      fill="black"
    />
  </svg>
);

export const SVGPreviewDesktop = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
  >
    <path
      d="M20.3339 0.000916667H2.87083C2.1895 0.0396371 1.55625 0.27891 1.10928 0.666516C0.662314 1.05412 0.437912 1.55859 0.485052 2.06983V2.06342V19.9375C0.439496 20.4463 0.663074 20.9481 1.10735 21.3342C1.55162 21.7202 2.18081 21.9594 2.85861 22H20.3327C21.0135 21.9608 21.646 21.7213 22.0923 21.3337C22.5385 20.9462 22.7623 20.4419 22.7148 19.9311V19.9375V2.06342C22.7581 1.55499 22.5338 1.05412 22.0899 0.668449C21.6461 0.282778 21.0182 0.0430955 20.3413 0.000916667L20.3315 0L20.3339 0.000916667ZM11.6024 20.6259C11.1168 20.6257 10.6512 20.4808 10.3081 20.2231C9.96498 19.9654 9.77239 19.616 9.77272 19.2518C9.77304 18.8876 9.96625 18.5385 10.3098 18.2811C10.6534 18.0238 11.1192 17.8793 11.6048 17.8796C12.0904 17.8798 12.556 18.0247 12.8991 18.2824C13.2422 18.5401 13.4348 18.8895 13.4345 19.2537C13.4342 19.6179 13.241 19.967 12.8974 20.2244C12.5538 20.4817 12.088 20.6262 11.6024 20.6259ZM21.1125 15.9857C21.1112 16.1216 21.0387 16.2516 20.9106 16.3477C20.7825 16.4437 20.6092 16.4981 20.428 16.4991H2.77672C2.59559 16.4981 2.42224 16.4437 2.29416 16.3477C2.16608 16.2516 2.09355 16.1216 2.09227 15.9857V2.5795C2.09355 2.44365 2.16608 2.31364 2.29416 2.21758C2.42224 2.12152 2.59559 2.06713 2.77672 2.06617H20.4232C20.6043 2.06713 20.7776 2.12152 20.9057 2.21758C21.0338 2.31364 21.1063 2.44365 21.1076 2.5795L21.1125 15.9857Z"
      fill="black"
    />
  </svg>
);

export const SVGPreviewTablet = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
  >
    <path
      d="M20.3339 0.000916667H2.87083C2.1895 0.0396371 1.55625 0.27891 1.10928 0.666516C0.662314 1.05412 0.437912 1.55859 0.485052 2.06983V2.06342V19.9375C0.439496 20.4463 0.663074 20.9481 1.10735 21.3342C1.55162 21.7202 2.18081 21.9594 2.85861 22H20.3327C21.0135 21.9608 21.646 21.7213 22.0923 21.3337C22.5385 20.9462 22.7623 20.4419 22.7148 19.9311V19.9375V2.06342C22.7581 1.55499 22.5338 1.05412 22.0899 0.668449C21.6461 0.282778 21.0182 0.0430955 20.3413 0.000916667L20.3315 0L20.3339 0.000916667ZM11.6024 20.6259C11.1168 20.6257 10.6512 20.4808 10.3081 20.2231C9.96498 19.9654 9.77239 19.616 9.77272 19.2518C9.77304 18.8876 9.96625 18.5385 10.3098 18.2811C10.6534 18.0238 11.1192 17.8793 11.6048 17.8796C12.0904 17.8798 12.556 18.0247 12.8991 18.2824C13.2422 18.5401 13.4348 18.8895 13.4345 19.2537C13.4342 19.6179 13.241 19.967 12.8974 20.2244C12.5538 20.4817 12.088 20.6262 11.6024 20.6259ZM21.1125 15.9857C21.1112 16.1216 21.0387 16.2516 20.9106 16.3477C20.7825 16.4437 20.6092 16.4981 20.428 16.4991H2.77672C2.59559 16.4981 2.42224 16.4437 2.29416 16.3477C2.16608 16.2516 2.09355 16.1216 2.09227 15.9857V2.5795C2.09355 2.44365 2.16608 2.31364 2.29416 2.21758C2.42224 2.12152 2.59559 2.06713 2.77672 2.06617H20.4232C20.6043 2.06713 20.7776 2.12152 20.9057 2.21758C21.0338 2.31364 21.1063 2.44365 21.1076 2.5795L21.1125 15.9857Z"
      fill="black"
    />
  </svg>
);
export const SVGNewWindow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
  >
    <path
      d="M19.9712 16.5H8.97119C8.21208 16.5 7.56396 16.2314 7.02686 15.6943C6.48975 15.1572 6.22119 14.5091 6.22119 13.75V2.75C6.22119 1.99089 6.48975 1.34277 7.02686 0.805664C7.56396 0.268555 8.21208 0 8.97119 0H19.9712C20.7303 0 21.3784 0.268555 21.9155 0.805664C22.4526 1.34277 22.7212 1.99089 22.7212 2.75V13.75C22.7212 14.5091 22.4526 15.1572 21.9155 15.6943C21.3784 16.2314 20.7303 16.5 19.9712 16.5ZM19.9712 4.125C19.9712 3.73828 19.8387 3.41244 19.5737 3.14746C19.3088 2.88249 18.9829 2.75 18.5962 2.75H10.3462C9.95947 2.75 9.63363 2.88249 9.36865 3.14746C9.10368 3.41244 8.97119 3.7347 8.97119 4.11426C8.97119 4.49382 9.10368 4.81966 9.36865 5.0918C9.63363 5.36393 9.95947 5.5 10.3462 5.5H15.3306L9.33643 11.4941C9.07861 11.752 8.94971 12.0635 8.94971 12.4287C8.94971 12.7939 9.07861 13.1055 9.33643 13.3633C9.59424 13.6211 9.90576 13.75 10.271 13.75C10.6362 13.75 10.9478 13.6211 11.2056 13.3633L17.2212 7.34766V12.375C17.2212 12.7617 17.3573 13.0876 17.6294 13.3525C17.9015 13.6175 18.2274 13.75 18.6069 13.75C18.9865 13.75 19.3088 13.6175 19.5737 13.3525C19.8387 13.0876 19.9712 12.7617 19.9712 12.375V4.125ZM18.5962 20.6357C18.5962 21.0153 18.4637 21.3376 18.1987 21.6025C17.9338 21.8675 17.6079 22 17.2212 22H3.47119C2.71208 22 2.06396 21.7314 1.52686 21.1943C0.989746 20.6572 0.721191 20.0091 0.721191 19.25V5.5C0.721191 5.11328 0.853678 4.78744 1.11865 4.52246C1.38363 4.25749 1.70589 4.125 2.08545 4.125C2.46501 4.125 2.79085 4.25749 3.06299 4.52246C3.33512 4.78744 3.47119 5.11328 3.47119 5.5V17.875C3.47119 18.2617 3.60368 18.5876 3.86865 18.8525C4.13363 19.1175 4.45947 19.25 4.84619 19.25H17.2212C17.5936 19.25 17.9159 19.3861 18.188 19.6582C18.4601 19.9303 18.5962 20.2562 18.5962 20.6357Z"
      fill="black"
    />
  </svg>
);
export const SVGEdit = (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.22669 7.99513L0.517741 11.0612C0.493285 11.1733 0.494123 11.2894 0.520195 11.4011C0.546267 11.5128 0.596914 11.6173 0.668436 11.7069C0.739958 11.7965 0.830548 11.8689 0.933589 11.9189C1.03663 11.9689 1.14952 11.9953 1.26401 11.996C1.31735 12.0014 1.37111 12.0014 1.42445 11.996L4.5028 11.2855L10.4132 5.38525L7.11473 2.08738L1.22669 7.99513Z"
      fill="white"
    />
    <path
      d="M12.2714 2.43137L10.0699 0.225313C9.9252 0.0810081 9.72933 0 9.52516 0C9.32099 0 9.12513 0.0810081 8.98039 0.225313L7.75651 1.45173L11.0513 4.75334L12.2752 3.52692C12.3468 3.45479 12.4035 3.36921 12.4421 3.27508C12.4807 3.18096 12.5003 3.08013 12.5 2.97837C12.4996 2.87662 12.4793 2.77593 12.4401 2.68207C12.4008 2.58821 12.3435 2.50302 12.2714 2.43137Z"
      fill="white"
    />
  </svg>
);
