import { Link, useParams } from "react-router-dom";
import {
  SVGBackToCMS,
  SVGLayoutLayout,
  SVGLayoutTemplate,
  SVGLayoutWidget,
} from "../SVG/layout";

const SideBar = ({ page = "template" }) => {
  const { contentId, site } = useParams();
  const backToCMS = () => {
    switch (site) {
      case "trplus":
        if (contentId) {
          window.location.href =
            process.env.REACT_APP_TRPLUS_CMS_URL + "/content/edit/" + contentId;
        } else {
          window.location.href =
            process.env.REACT_APP_TRPLUS_CMS_URL + "/content";
        }
        break;
      case "mirror":
        if (contentId) {
          window.location.href =
            process.env.REACT_APP_MIRROR_CMS_URL + "/content/edit/" + contentId;
        } else {
          window.location.href =
            process.env.REACT_APP_MIRROR_CMS_URL + "/content";
        }
        break;
      default:
        if (contentId) {
          window.location.href =
            process.env.REACT_APP_TROL_CMS_URL + "/content/edit/" + contentId;
        } else {
          window.location.href =
            process.env.REACT_APP_TROL_CMS_URL + "/content/list";
        }
    }
  };
  return (
    <>
      <div className="home--btn">
        <button
          onClick={() => {
            backToCMS();
          }}
        >
          {SVGBackToCMS}
          <span>Back to CMS</span>
        </button>
      </div>
      <div className="menu">
        <ul>
          <li>
            <Link
              to={`/layout/${site}/${contentId}`}
              className={page === "layout" ? "active" : " "}
            >
              {SVGLayoutLayout}
              Layout
            </Link>
          </li>
          <li>
            <a
              href={`/template/${site}/${contentId}`}
              className={page === "template" ? "active" : " "}
            >
              {SVGLayoutTemplate}
              Templates
            </a>
          </li>
          {/* <li>
            <Link
              className={page === "widget" ? "active" : " "}
              to={`/widget/${site}/${contentId}`}
            >
              {SVGLayoutWidget}
              Widget
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default SideBar;
