import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Modal, Select } from "antd";
import contentContext from "../../../contexts/content_context";
import MultipleChapter from "./multi";
import SingleChapter from "./single";
import {
  templateConfig,
  templateConfigAll,
} from "../../../config/template-config";
import {
  SVGBackButton,
  SVGCollapseButton,
  SVGExpandButton,
} from "../../SVG/layout";
import FormCustomTitleEffect from "./custom-title-effect";
import FormCustomChatBubble from "./custom-chat-bubble";

const SlideEditor = () => {
  const {
    currentSlide,
    slides,
    setCurrentSlide,
    saveCurrentSlide,
    changeTemplateModal,
    setChangeTemplateModal,
  } = useContext(contentContext);

  const [slide, setSlide] = useState(slides[currentSlide] || { data: [] });
  const [slideConfig, setSlideConfig] = useState(templateConfigAll);
  // const [changeTemplateModal, setChangeTemplateModal] = useState(false);
  const [changeSlide, setChangeSlide] = useState("");

  useEffect(() => {
    const _slide = slides[currentSlide];
    setSlideConfig(
      templateConfigAll.find((item) => item.type === _slide.template)
    );
  }, [changeTemplateModal]);

  useEffect(() => {
    const _slide = slides[currentSlide];
    setSlide(_slide);
    setSlideConfig(
      templateConfigAll.find((item) => item.type === _slide.template)
    );
  }, [currentSlide]);

  const onSlideChange = (value) => {
    const _slide = { ...slide, ...value };
    setSlide(value);
    saveCurrentSlide(_slide);
  };

  // const changeTemplate = () => {
  //   const _slide = { ...slide, template: changeSlide };
  //   setSlide(_slide);
  //   saveCurrentSlide(_slide);
  //   setChangeTemplateModal(false);
  // };

  const settingTab = useRef(null);
  const exPand = () => {
    const fullscreen = document.querySelector(".aside--preview");
    fullscreen.classList.toggle("expand");
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 550);
  };
  const setSlideCurrent = () => {
    setCurrentSlide("new");
    const fullscreen = document.querySelector(".aside--preview");
    fullscreen.classList.remove("expand");
  };

  return (
    <Fragment>
      <div className="setting--tab" ref={settingTab}>
        <div className="action--btn">
          <button onClick={setSlideCurrent}>{SVGBackButton}</button>
          <button onClick={exPand}>
            {SVGCollapseButton}
            {SVGExpandButton}
          </button>
        </div>
        <div className="slide--name">
          <label>Layout</label>
          <button
            onClick={() => {
              setChangeSlide(slide.template);
              // setChangeTemplateModal(true);
            }}
          >
            {slideConfig.name}
          </button>
        </div>
        <div className="form">
          {slideConfig.other_settings ? (
            <>
              {slideConfig.other_settings === "title_effect" && (
                <FormCustomTitleEffect
                  slide={slide}
                  config={slideConfig}
                  onChange={onSlideChange}
                />
              )}
              {slideConfig.other_settings === "chat_bubble" && (
                <FormCustomChatBubble
                  slide={slide}
                  config={slideConfig}
                  onChange={onSlideChange}
                />
              )}
            </>
          ) : (
            <>
              {slideConfig.dataset === "many" && (
                <MultipleChapter
                  slide={slide}
                  config={slideConfig}
                  onChange={onSlideChange}
                />
              )}
              {slideConfig.dataset === "one" && (
                <SingleChapter
                  slide={slide}
                  config={slideConfig}
                  onChange={onSlideChange}
                />
              )}
            </>
          )}
        </div>
      </div>
      {/* <Modal
        open={changeTemplateModal}
        title="ต้องการเปลี่ยน Layout หรือไม่? (beta)"
        onOk={changeTemplate}
        onCancel={() => {
          setChangeTemplateModal(false);
        }}
      >
        <Form.Item label="Layout">
          <Select value={changeSlide} onChange={(val) => setChangeSlide(val)}>
            {templateConfig.map((item) => {
              // special template can't convert to other template
              if (
                ["image_comparison", "chat_bubble", "title_effect"].includes(
                  slideConfig.type
                )
              ) {
                return null;
              }
              if (
                item.dataset === slideConfig.dataset &&
                item.thumbnails === slideConfig.thumbnails
              ) {
                // templates dataset and thumbnails must be same
                return (
                  <Select.Option key={item.type} value={item.type}>
                    {item.name}
                  </Select.Option>
                );
              }
            })}
          </Select>
        </Form.Item>
      </Modal> */}
    </Fragment>
  );
};

export default SlideEditor;
