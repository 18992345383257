import React, { Fragment, useContext, useEffect, useState } from "react";
import { Checkbox, Form, Input, Radio, Tooltip } from "antd";
import FormDescription from "../../Form/Elements/Description";
import FormTitle from "../../Form/Elements/Title";
import DropImages from "../../Form/Elements/DropImage";
import FormAbstract from "../../Form/Elements/Abstract";
import UploadList from "../../Form/Elements/ImageList";
import EmbedUpload from "../../Form/Elements/EmbedUpload";
import contentContext from "../../../contexts/content_context";
import LayoutOptions from "../../Form/Elements/ButtonLayoutOptions";
import { getDefaultChapter } from "../../../lib/default-value";
import EffectOpener2 from "../../Form/Elements/EffectOpener2";
import FormColor from "../../Form/Elements/ColorPicker";

const defaultChapter = getDefaultChapter();

const SingleChapter = ({ config, slide, onChange }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [MediaTab, setMediaTab] = useState(true);
  const [value, setValue] = useState(1);
  const [ImageSplit, setImageSplit] = useState(false);
  const [DesktopTab, setDesktopTab] = useState(null);
  const {
    contentId,
    site,
    currentSlide,
    removeImage,
    imageMode,
    backgroundType,
    setBackgroundType,
  } = useContext(contentContext);
  const [chapter, setChapter] = useState(
    slide.data[0] || { ...defaultChapter }
  );

  useEffect(() => {
    setChapter(slide.data[0] || { ...defaultChapter });
    setBackgroundType(chapter.background_type || "image");
  }, [currentSlide, slide]);

  const handleChapterChange = (key, value) => {
    const _chapter = { ...chapter, [key]: value };
    setChapter(_chapter);
    onChange({ ...slide, data: [_chapter] });
  };
  const onChangeRadio = (e) => {
    setValue(e.target.value);
  };
  return (
    <>
      <Form autoComplete="off" form={form} layout="vertical">
        {config.title && (
          <FormTitle
            template={config.type}
            value={chapter.title}
            onChange={(val) => {
              handleChapterChange("title", val);
            }}
          />
        )}

        {config.effect && (
          <>
            <EffectOpener2
              label="Effect (Title)"
              value={chapter.effect}
              onSelected={(val) => {
                handleChapterChange("effect", val);
              }}
            />
          </>
        )}

        {config.opacity_bg && (
          <>
            <FormColor
              title="เลือกสีพื้นหลัง"
              id={chapter.tmp_id}
              value={chapter.opacity_bg}
              onChange={(val) => {
                handleChapterChange("opacity_bg", val);
              }}
              key={"opacity_bg_1"}
            />
          </>
        )}

        {config.abstract && (
          <FormAbstract
            template={config.type}
            value={chapter.abstract}
            onChange={(val) => {
              handleChapterChange("abstract", val);
            }}
          />
        )}

        {config.content && (
          <FormDescription
            value={chapter.content}
            onChange={(val) => {
              handleChapterChange("content", val);
            }}
            site={site}
            contentId={contentId}
            onFileUpdate={(res) => {
              if (res.type === "remove_image") {
                removeImage(res.src, false);
              }
            }}
          />
        )}

        {config.layout && (
          <LayoutOptions
            value={chapter.layout}
            options={config.layout_options}
            onSelected={(val) => {
              handleChapterChange("layout", val);
            }}
          />
        )}

        {config.embed && (
          <div className="media--section">
            <Form.Item label={`Media (เลือกได้ 1 รูปหรือคลิป)`} required>
              <Radio.Group
                defaultValue={backgroundType || "image"}
                onChange={(e) => {
                  setBackgroundType(e.target.value);
                  handleChapterChange("background_type", e.target.value);
                }}
                // value={value}
              >
                <div className="media--list">
                  <Radio value="image">Image</Radio>
                  <Radio value="video">Video</Radio>
                </div>
              </Radio.Group>
            </Form.Item>
          </div>
        )}
        {config.thumbnails && (
          <>
            {backgroundType === "image" ? (
              <div className="image--upload--container">
                <DropImages
                  limitThumbnail={config.thumbnails}
                  ref_id={contentId}
                  site={site}
                  template={config.type}
                  chapterImageSplit={chapter.image_split}
                  onCheckbox={(imgs) => {
                    handleChapterChange("image_split", imgs);
                  }}
                />
                {imageMode === "desktop" && (
                  <UploadList
                    selected={chapter.thumbnails || []}
                    limitThumbnail={config.thumbnails}
                    onChange={(imgs) => {
                      handleChapterChange("thumbnails", imgs);
                    }}
                  />
                )}
                {imageMode === "mobile" && (
                  <UploadList
                    selected={chapter.thumbnails_mobile || []}
                    limitThumbnail={config.thumbnails}
                    onChange={(imgs) => {
                      handleChapterChange("thumbnails_mobile", imgs);
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="img--size--box">
                <div className="check--box--value">
                  <Checkbox
                    value="true"
                    onChange={() => setImageSplit(!ImageSplit)}
                  >
                    ใส่วิดีโอแยกขนาดสำหรับ Desktop/Mobile
                  </Checkbox>
                </div>
                {ImageSplit && (
                  <div className="toggle--tab">
                    <ul>
                      <li>
                        <button
                          onClick={() => setDesktopTab(!DesktopTab)}
                          className={DesktopTab === true ? "active" : ""}
                        >
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.4333 10.0385V2.03846C15.4333 1.95513 15.4069 1.88301 15.3542 1.82212C15.3014 1.76122 15.2389 1.73077 15.1667 1.73077H1.83333C1.76111 1.73077 1.69861 1.76122 1.64583 1.82212C1.59306 1.88301 1.56667 1.95513 1.56667 2.03846V10.0385C1.56667 10.1218 1.59306 10.1939 1.64583 10.2548C1.69861 10.3157 1.76111 10.3462 1.83333 10.3462H15.1667C15.2389 10.3462 15.3014 10.3157 15.3542 10.2548C15.4069 10.1939 15.4333 10.1218 15.4333 10.0385ZM16.5 2.03846V12.5C16.5 12.9231 16.3694 13.2853 16.1083 13.5865C15.8472 13.8878 15.5333 14.0385 15.1667 14.0385H10.6333C10.6333 14.2756 10.6778 14.524 10.7667 14.7837C10.8556 15.0433 10.9444 15.2708 11.0333 15.4663C11.1222 15.6619 11.1667 15.8013 11.1667 15.8846C11.1667 16.0513 11.1139 16.1955 11.0083 16.3173C10.9028 16.4391 10.7778 16.5 10.6333 16.5H6.36667C6.22222 16.5 6.09722 16.4391 5.99167 16.3173C5.88611 16.1955 5.83333 16.0513 5.83333 15.8846C5.83333 15.7949 5.87778 15.6538 5.96667 15.4615C6.05556 15.2692 6.14444 15.0449 6.23333 14.7885C6.32222 14.5321 6.36667 14.2821 6.36667 14.0385H1.83333C1.46667 14.0385 1.15278 13.8878 0.891667 13.5865C0.630556 13.2853 0.5 12.9231 0.5 12.5V2.03846C0.5 1.61538 0.630556 1.25321 0.891667 0.951923C1.15278 0.650641 1.46667 0.5 1.83333 0.5H15.1667C15.5333 0.5 15.8472 0.650641 16.1083 0.951923C16.3694 1.25321 16.5 1.61538 16.5 2.03846Z"
                              fill="black"
                            />
                          </svg>
                          Desktop
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => setDesktopTab(!DesktopTab)}
                          className={DesktopTab === false ? "active" : ""}
                        >
                          <svg
                            width="10"
                            height="17"
                            viewBox="0 0 10 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.33667 0H1.66333C1.22219 0 0.799114 0.178748 0.487179 0.496923C0.175243 0.815097 0 1.24663 0 1.6966V15.3026C0 16.2401 0.745 17 1.66333 17H8.33583C8.77705 17 9.20021 16.8213 9.51228 16.5031C9.82435 16.185 9.99978 15.7534 10 15.3034V1.6966C10 1.24663 9.82476 0.815097 9.51282 0.496923C9.20089 0.178748 8.77781 0 8.33667 0ZM5 16.15C4.425 16.15 3.95833 15.7701 3.95833 15.3C3.95833 14.83 4.425 14.45 5 14.45C5.575 14.45 6.04167 14.83 6.04167 15.3C6.04167 15.7701 5.575 16.15 5 16.15ZM8.33333 13.6H1.66667V1.7H8.33333V13.6Z"
                              fill="black"
                            />
                          </svg>
                          Mobile
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
                {/* embred input */}
                {backgroundType === "video" && (
                  <Fragment>
                    <h2>
                      {DesktopTab !== null &&
                        DesktopTab === true &&
                        "วิดิโอสำหรับแสดงบน Desktop"}
                      {DesktopTab !== null &&
                        DesktopTab === false &&
                        "วิดิโอสำหรับแสดงบน Mobile"}
                      {DesktopTab === null && "วิดิโอสำหรับแสดงบนทุกอุปกรณ์"}
                    </h2>
                    <p></p>
                    <p>ใส่โค้ด Embed ของวิดีโอที่ต้องการให้แสดงผล</p>
                    {config.embed && (
                      <Fragment>
                        <EmbedUpload
                          value={chapter.embed || []}
                          onChange={(val) => {
                            handleChapterChange("embed", val);
                          }}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            )}
          </>
        )}
      </Form>
    </>
  );
};
export default SingleChapter;
