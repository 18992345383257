import React, { useContext, useEffect, useRef, useState } from 'react';
import { DesktopOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import SlideSection from '../Modules/Layout/SlideSection';
import StoryTemplate from '../Modules/Layout/ArticleTemplates';
import LayoutHeader from '../Modules/Layout/Header';
import SideBar from '../Modules/Layout/SideBar';
// import TemplatePreview from "../Modules/Layout/ArticlePreview";
import MockupContent from '../config/mockup-template.json';
import ContentContext from '../contexts/content_context';
import {
  SVGCollapableDown,
  SVGCollapableLeft,
  SVGNewWindow,
  SVGPreviewDesktop,
  SVGPreviewMobile,
  SVGPreviewTablet,
} from '../Modules/SVG/layout';
import PageContext from './pageContext';
import axios from 'axios';
import ResponsiveBar from '../Modules/Layout/ResponsiveBar';
import { SVGAlert } from '../Modules/SVG/slide-edit';

const bc = new BroadcastChannel('storytelling-update');

const TemplatePage = () => {
  const { site = 'trol', contentId = '' } = useParams();
  const { setApplyTemplate } = useContext(PageContext);
  const [template, setTemplate] = useState('blank');
  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState('new');
  const [screenWidth, setScreenWidth] = useState();
  const [boxWidth, setBoxWidth] = useState(null);
  const [popupWidth, setPopupWidth] = useState(380);
  const [isExistsStorytelling, setIsExistsStorytelling] = useState(false);
  const collapseMainControl = useRef(null);
  const collapseMenu = useRef(null);
  const collapseMain = useRef(null);
  const collapseSide = useRef(null);
  const templateLayout = useRef(null);
  const [Loading, setLoading] = useState(true);
  const handleLoad = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (templateLayout.current === null) return;
    setScreenWidth(templateLayout.current.getBoundingClientRect().width);
    setBoxWidth(templateLayout.current.getBoundingClientRect().width);
    if (template !== 'blank') {
      getContentFromMockup();
    }
  }, [template]);

  useEffect(() => {
    if (contentId) {
      fetchContent(contentId);
    }
  }, [contentId]);

  useEffect(() => {
    boardcastUpdate(slides);
  }, [slides, currentSlide]);

  const boardcastUpdate = (_slides) => {
    setTimeout(() => {
      bc.postMessage({
        currentSlide,
        data: _slides || slides,
        type: 'content',
      });
    }, 1000);
  };

  const collapseSideBar = () => {
    collapseMenu.current.classList.toggle('aside--preview--collapse');
    collapseMain.current.classList.toggle('aside--preview--collapse');
    // collapseSide.current.classList.toggle("aside--preview--collapse");
    // collapseMainControl.current.classList.toggle("aside--preview--collapse");
  };
  // const collapseSlideMenu = () => {
  //   collapseSide.current.classList.toggle("go--down");
  //   collapseMain.current.classList.toggle("add--height");
  // };
  const getContentFromMockup = () => {
    const mockup = MockupContent.find((item) => item.id === template);
    if (mockup) {
      return setSlides(mockup.slides);
    } else {
      return [];
    }
  };

  const fetchContent = async (contentId) => {
    const { data } = await axios.get(`/api/content/get/${site}/${contentId}`, {
      withCredentials: true,
    });

    if (data.status === 200) {
      if (data.data && typeof data.data === 'object') {
        if (data.data.length > 0) {
          setIsExistsStorytelling(true);
        }
      }
    }
    if (data.status === 404) {
      // setSlides([]);
    }
    if (data.status === 401) {
      document.cookie = 'storytelling_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      window.location.href = `/layout/${site}/${contentId}`;
    }
  };

  const onPreviewTemplate = (id) => {
    setTemplate(id);

    const mockup = MockupContent.find((item) => item.id === id);
    boardcastUpdate(mockup.slides);
  };

  const onApply = (value) => {
    const mockup = MockupContent.find((item) => item.id === value);
    boardcastUpdate(mockup.slides);
    Modal.confirm({
      title: (
        <>
          ต้องการใช้ Template นี้
          <button
            type='button'
            aria-label='Close'
            className='ant-modal-close'
            onClick={() => Modal.destroyAll()}>
            <span className='ant-modal-close-x'>
              <span role='img' aria-label='close' style={{ display: 'grid' }}>
                <svg
                  fillRule='evenodd'
                  viewBox='64 64 896 896'
                  focusable='false'
                  data-icon='close'
                  width='1em'
                  height='1em'
                  fill='currentColor'
                  aria-hidden='true'>
                  <path d='M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z'></path>
                </svg>
              </span>
            </span>
          </button>
        </>
      ),
      content: (
        <>
          <iframe
            src={`/${site}/preview`}
            title='template'
            width={'100%'}
            style={{
              border: 'none',
              height: 'calc(100vh - 400px)',
              position: 'relative',
              zIndex: 3,
              background: '#fff',
            }}
          />
          <div className='loading'>
            <div className='loader'></div>
          </div>
        </>
      ),
      width: '70vw',
      okText: 'ยืนยัน',
      cancelButtonProps: { style: { display: 'none' } },
      centered: true,
      className: 'select--template',
      onOk: () => {
        if (isExistsStorytelling) {
          Modal.confirm({
            title: SVGAlert,
            content: 'คุณมีข้อมูลเดิมอยู่แล้ว ต้องการแทนที่ข้อมูลเดิมหรือไม่',
            centered: true,
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onOk: () => {
              window.location.href = `/layout/${site}/${contentId}?apply_template=${value}`;
            },
          });
        } else {
          window.location.href = `/layout/${site}/${contentId}?apply_template=${value}`;
        }
      },
    });
  };
  return (
    <ContentContext.Provider
      value={{
        slides,
        setSlides,
        currentSlide,
        setCurrentSlide,
      }}>
      <div className='App'>
        <div className='main--control' ref={collapseMainControl}>
          <LayoutHeader />
          <div className='aside'>
            <SideBar page='template' />
          </div>
          <div
            className={screenWidth > 768 ? 'main--component desktop--view' : 'main--component'}
            ref={collapseMain}>
            <div
              style={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
              }}>
              <div className='scroll--overflow' />

              <div className='aside--preview' ref={collapseMenu}>
                <StoryTemplate
                  onSelected={(value) => {
                    onPreviewTemplate(value);
                  }}
                  onApply={(value) => onApply(value)}
                  site={site}
                  contentId={contentId}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // width: "100%",
                height: '100dvh',
              }}>
              <div className='template--layout' ref={templateLayout}>
                <button onClick={collapseSideBar} className='collapable--btn'>
                  {SVGCollapableLeft}
                </button>
                <ResponsiveBar
                  maxWidth={boxWidth}
                  onChange={setScreenWidth}
                  onNewWindow={() => {
                    window.open(`/${site}/preview`);
                    boardcastUpdate(slides);
                  }}
                />
                <div
                  className='preview--template template--page'
                  style={{ width: 'auto', margin: 'auto' }}>
                  <iframe
                    onLoad={handleLoad}
                    src={`/${site}/preview?template=${template}`}
                    title='template'
                    width={screenWidth !== null ? screenWidth : '100%'}
                    // style={{ border: "none", height: "calc(100% - 5px)" }}
                  />
                  {Loading ? (
                    <div className='loading'>
                      <div className='loader'></div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <button
                onClick={collapseSlideMenu}
                className="collapable--bottom--btn"
              >
                {SVGCollapableDown}
              </button>
              <div className="preview--component" ref={collapseSide}>
                <SlideSection readOnly={true} />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </ContentContext.Provider>
  );
};

export default TemplatePage;
