import mockupTemplate from "../../config/mockup-template.json";
import { useContext, useState } from "react";
import { Modal } from "antd";

const StoryTemplate = ({ onSelected, site, contentId, onApply }) => {
  return (
    <>
      <h2
        style={{
          textAlign: "left",
          fontSize: " 1.5em",
          margin: 0,
          lineHeight: 1,
        }}
      >
        All Templates
      </h2>
      {/* <div className="search--form">
        <input placeholder="Search Templates" />
        <button>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3849 15.4458C11.7346 17.5685 7.8552 17.4014 5.39842 14.9446C2.76238 12.3086 2.76238 8.0347 5.39842 5.39866C8.03445 2.76262 12.3083 2.76262 14.9444 5.39866C17.4011 7.85544 17.5682 11.7349 15.4456 14.3851L20.6012 19.5408C20.8941 19.8337 20.8941 20.3085 20.6012 20.6014C20.3083 20.8943 19.8334 20.8943 19.5405 20.6014L14.3849 15.4458ZM6.45908 13.8839C4.40882 11.8337 4.40882 8.50957 6.45908 6.45932C8.50933 4.40907 11.8334 4.40907 13.8837 6.45932C15.9324 8.50807 15.9339 11.8288 13.8882 13.8794C13.8867 13.8809 13.8852 13.8824 13.8837 13.8839C13.8822 13.8854 13.8807 13.8869 13.8792 13.8884C11.8286 15.9342 8.50783 15.9327 6.45908 13.8839Z"
              fill="#D9D9D9"
            />
          </svg>
        </button>
      </div> */}

      <div className="module--card--layout">
        <div className="module--card--layout--name">
          <h2>Blank</h2>
        </div>
        <div
          className="module--card--thumbnail"
          style={{
            aspectRatio: "16 / 9 ",
            display: "grid",
            alignItems: "center",
            border: "1px solid #D9D9D9",
          }}
        >
          +
        </div>
        <div
          className="module--card--layout--button"
          style={{ display: "flex" }}
        >
          <button
            className="selected"
            onClick={() =>
              (window.location.href = `/layout/${site}/${contentId}`)
            }
          >
            ใช้ template นี้
          </button>
        </div>
      </div>
      {mockupTemplate.map((item, index) => (
        <div className="module--card--layout" key={item.id}>
          <div className="module--card--layout--name">
            <h2>{item.name}</h2>
          </div>
          <div className="module--card--layout--thumbnail">
            <img alt={item.name} src={item.display} />
          </div>

          <div className="module--card--layout--button">
            <button className="preview" onClick={() => onSelected(item.id)}>
              ดูตัวอย่าง
            </button>

            <button className="selected" onClick={() => onApply(item.id)}>
              ใช้ template นี้
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default StoryTemplate;
