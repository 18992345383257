import { Dropdown } from "antd";
import { SwapOutlined, MoreOutlined } from "@ant-design/icons";
const OptionChat = ({
  changeLayout,
  removeConversation,
  setMessage,
  setTextEdit,
  item,
  index,
}) => {
  return (
    <>
      {item.layout === "left" && (
        <SwapOutlined
          onClick={() => {
            changeLayout(index);
          }}
          style={{
            border: "2px solid #000",
            borderRadius: "70px",
            padding: "3px",
          }}
        />
      )}
      <Dropdown
        menu={{
          items: [
            {
              label: (
                <a
                  href="#"
                  className="_menu-chat-option-1"
                  onClick={(e) => {
                    e.preventDefault();
                    removeConversation(index);
                  }}
                >
                  ลบ
                </a>
              ),
              key: "1",
            },
            {
              label: (
                <a
                  href="#"
                  className="_menu-chat-option-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setMessage(item.content);
                    setTextEdit(item);
                  }}
                >
                  แก้ไข
                </a>
              ),
              key: "2",
            },
          ],
        }}
      >
        <MoreOutlined />
      </Dropdown>
      {item.layout === "right" && (
        <SwapOutlined
          onClick={() => {
            changeLayout(index);
          }}
          style={{
            border: "2px solid #000",
            borderRadius: "70px",
            padding: "3px",
          }}
        />
      )}
    </>
  );
};

export default OptionChat;
