import LayoutHeader from "../Modules/Layout/Header";
import SideBar from "../Modules/Layout/SideBar";
import Widget from "../Modules/Layout/Widget";
import WidgetPreview from "../Modules/Layout/WidgetPreview";

const WidgetPage = () => {

  return (
    <div className="App">
      <div className="main--control">
        <LayoutHeader />
        <div className="aside--preview">
          <Widget />
        </div>
        <div className="aside">
          <SideBar page="widget" />
        </div>
        <div className="main--component">
          <div className="template--layout">
            <WidgetPreview />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetPage;
