/**
 * 
{
    type: "timeline_1", // ชื่อ template ที่ใช้ใน code
    name: "Timeline 1", // ชื่อ template ที่แสดงให้ user เลือก
    example_image: "", // url รูปภาพตัวอย่าง เป็น gif
    dataset: "many", // ใช้กับ template ที่มีหลาย chapter
    dataset_text: "เพิ่ม timeline", // ข้อความที่แสดงให้ user เพิ่ม chapter
    title: true, // แสดง title หรือไม่
    thumbnails: 1, // จำนวน thumbnail ที่แสดง
    abstract: true, // แสดง input ของ abstract หรือไม่
    content: false, // แสดง input ของ content หรือไม่
    abbr_name: false, // แสดง input ของ abbr_name หรือไม่
    point_name: false, // แสดง input ของ point_name หรือไม่
    active: true, // ใช้งานหรือไม่
    remove_header: true, // ใน chapter แรก เป็น header หรือไม่
    remove_header_fields: ["content"], // ใน chapter แรก ถ้าเป็น header ไม่แสดง input ของอะไรใน list นี้
  }
 */

const config = [
  {
    type: "content",
    name: "Content",
    example_image: "/img/template-gif/content.gif",
    dataset: "one",
    title: false,
    thumbnails: "none",
    abstract: false,
    content: true,
    active: true,
    not_changetemplate: true,
  },

  {
    type: "thumbnail_1",
    name: "Thumbnail 1",
    example_image: "/img/template-gif/thumbnail_1.gif",
    dataset: "one",
    title: true,
    thumbnails: 1,
    abstract: true,
    content: true,
    active: true,
    similar: [
      "thumbnail_1",
      "thumbnail_2",
      "bubble_1",
      "bubble_2",
      "content",
      "magnify",
    ],
    max_length: {
      title: 100,
    },
    device: true,
  },

  {
    type: "thumbnail_2",
    name: "Thumbnail 2",
    example_image: "/img/template-gif/thumbnail_2.gif",
    dataset: "one",
    title: true,
    thumbnails: 1,
    abstract: true,
    content: true,
    active: true,
    max_length: {
      title: 100,
    },
    device: true,
  },

  {
    type: "image_pile",
    name: "Image Pile",
    example_image: "/img/template-gif/image_pile.gif",
    dataset: "one",
    title: true,
    thumbnails: 999,
    abstract: true,
    content: true,
    active: true,
    max_length: {
      title: 100,
    },
  },

  {
    type: "fade_background",
    name: "Fade Background",
    example_image: "/img/template-gif/fade_background.gif",
    dataset: "one",
    title: true,
    thumbnails: 999,
    abstract: true,
    content: false,
    active: true,
    max_length: {
      title: 90,
    },
    device: true,
  },

  {
    type: "bubble_1",
    name: "Bubble 1",
    example_image: "/img/template-gif/bubble_1.gif",
    dataset: "many",
    dataset_text: "เพิ่ม slide +",
    title: true,
    thumbnails: 1,
    abstract: true,
    content: false,
    active: true,
    remove_header: true,
    remove_header_fields: ["title", "abstract", "content", "embed"],
    max_length: {
      title: 80,
    },
    // device: true,
    embed: true,
  },

  {
    type: "bubble_2",
    name: "Bubble 2",
    example_image: "/img/template-gif/bubble_2.gif",
    dataset: "one",
    title: false,
    thumbnails: 1,
    abstract: false,
    content: true,
    active: true,
    remove_header: true,
    remove_header_fields: ["title", "abstract", "content"],
    device: true,
  },

  {
    type: "timeline_1",
    name: "Timeline 1",
    example_image: "/img/template-gif/timeline_1.gif",
    dataset: "many",
    dataset_text: "เพิ่ม timeline +",
    title: true,
    max_length: {
      title: 90,
    },
    thumbnails: 2,
    thumbnails_content: 1,
    abstract: true,
    content: false,
    abbr_name: false,
    point_name: false,
    active: true,
    remove_header: true,
    remove_header_fields: ["content", "thumbnails_content"],
    remove_title: true,
    remove_title_fields: ["device"],
    device: true,
  },

  {
    type: "timeline_2",
    name: "Timeline 2",
    example_image: "/img/template-gif/timeline_2.gif",
    dataset: "many",
    dataset_text: "เพิ่ม timeline +",
    title: true,
    thumbnails: 2,
    thumbnails_content: 1,
    abstract: false,
    content: true,
    abbr_name: true,
    point_name: true,
    active: true,
    remove_header: true,
    remove_header_fields: [
      "content",
      "abbr_name",
      "point_name",
      "embed",
      "thumbnails_content",
    ],
    remove_title: true,
    remove_title_fields: ["device"],
    max_length: {
      title_main: 50,
      title: 90,
      abbr: 20,
      point: 30,
    },
    device: true,
    embed: true,
  },

  {
    type: "opener",
    name: "Opener 1",
    example_image: "/img/template-gif/opener.gif",
    dataset: "many",
    title: true,
    thumbnails: 1,
    abstract: false,
    content: false,
    active: true,
    device: true,
  },

  {
    type: "opener_2",
    name: "Opener 2",
    example_image: "/img/template-gif/opener2.gif",
    dataset: "one",
    title: true,
    thumbnails: 1,
    abstract: false,
    content: false,
    active: true,
    device: true,
    video: true,
    opacity_bg: true,
    effect: true,
    max_length: {
      title: 26,
    },
    not_changetemplate: true,
  },

  {
    type: "story",
    name: "Story",
    example_image: "/img/template-gif/story.gif",
    dataset: "many",
    dataset_text: "เพิ่ม slide +",
    title: true,
    thumbnails: 1,
    abstract: true,
    content: false,
    color: true,
    story_layout: true,
    max_length: {
      title: 50,
    },
    layout_options: [
      "fullscreenTxtLeftTheme",
      "fullscreenTxtRightTheme",
      "fullscreenTxtBottomTheme",
      "squareGreenTheme",
      "squareBlueTheme",
      "squareTxtCenterTheme",
    ],
    active: true,
    device: true,
  },

  {
    type: "title_effect",
    name: "Title Effect",
    example_image: "/img/template-gif/title_effect.gif",
    dataset: "one",
    title: false,
    thumbnails: 1,
    abstract: false,
    content: false,
    other_settings: "title_effect",
    active: true,
    animetion_title: true,
    device: true,
    not_changetemplate: true,
  },

  {
    type: "image_sequence",
    name: "Image Animation Frames",
    example_image: "/img/template-gif/image_sequence.gif",
    dataset: "one",
    title: false,
    thumbnails: 999,
    abstract: false,
    content: false,
    active: true,
    device: true,
    not_changetemplate: true,
  },

  {
    type: "hover_point",
    name: "Hover Point",
    example_image: "/img/template-gif/hover_point.gif",
    dataset: "many",
    dataset_text: "เพิ่มจุด",
    title: false,
    thumbnails: 1,
    abstract: true,
    content: false,
    active: false,
  },

  {
    type: "magnify",
    name: "Magnify",
    example_image: "/img/template-gif/magnify.gif",
    dataset: "one",
    title: true,
    thumbnails: 1,
    abstract: false,
    content: true,
    layout: true,
    layout_options: ["left", "right", "center", "full"],
    active: false,
  },

  {
    type: "image_comparison",
    name: "Image Compairson",
    example_image: "/img/template-gif/image_comparison.gif",
    dataset: "one",
    title: true,
    thumbnails: 2,
    abstract: false,
    content: true,
    layout: true,
    layout_options: ["left", "right", "center"],
    active: true,
    device: true,
  },

  {
    type: "horizon_scroll_gallery",
    name: "Horizon Scroll Gallery",
    example_image: "/img/template-gif/horizon_scroll_gallery.gif",
    dataset: "one",
    title: true,
    thumbnails: 999,
    abstract: true,
    content: true,
    active: true,
  },

  {
    type: "lightbox_photostack",
    name: "Lightbox Photostack",
    example_image: "/img/template-gif/lightbox_photostack.gif",
    dataset: "one",
    title: true,
    thumbnails: 999,
    abstract: true,
    content: true,
    active: true,
  },

  {
    type: "chat_bubble",
    name: "Chat Bubble",
    example_image: "/img/template-gif/chat_bubble.gif",
    dataset: "one",
    title: false,
    thumbnails: 1,
    abstract: false,
    content: false,
    other_settings: "chat_bubble",
    active: true,
    device: true,
  },
];

const getTemplateConfig = (key = false, activate = true) => {
  let templates = config;
  if (activate === true) {
    templates = config.filter((item) => {
      return item.active === activate;
    });
  }

  if (key) {
    const _templates = {};
    templates.forEach((item) => {
      _templates[item.type] = item;
    });
    return _templates;
  } else {
    return templates;
  }
};
export const templateConfig = getTemplateConfig();
export const templateConfigAll = getTemplateConfig(false, false);
export const templateConfigKey = getTemplateConfig(true, false);
