import { Input } from "antd";
import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { templateConfig } from "../../../config/template-config";
import _get from "lodash/get";

const FormAbstract = ({
  value = "",
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  template,
}) => {
  const _config = templateConfig.find((item) => item.type === template);
  const abstractLength = _get(_config, "max_length.abstract", 300);

  return (
    <>
      <Form.Item
        label={
          <span>
            * <strong>Description</strong>
          </span>
        }
        rules={[{ required: true, message: "Please input detail!" }]}
        // tooltip="This is a required field"
      >
        <TextArea
          rows={4}
          type="text"
          placeholder="Abstract"
          value={value}
          onBlur={() => onBlur("")}
          onFocus={() => {
            onFocus("abstract");
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <span
          className="char--remind"
          style={{
            color: value.length > abstractLength && "red",
            borderColor: value.length > abstractLength && "red",
          }}
        >
          {value.length}/{abstractLength} ตัวอักษร
        </span>
      </Form.Item>
    </>
  );
};

export default FormAbstract;
