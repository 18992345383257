import styled from '@emotion/styled';

const money = {
  MainColor: `#87AAA0`,
  SecondColor: `#199572`,
  DarkColor: `#296252`,
  LightColor: `#E6F2EF`,
  BrightColor: `#73BFAA`,
  Graphik_Font: `'Graphik TH'`,
  Kokoro_Font: `'Kokoro'`,
  Heading_Font: `'Kokoro', 'Graphik TH'`,
  Article_Font: `'Kokoro'`,
};

export { money };
