import { useState, createContext, useContext, useEffect } from "react";
import { getCookie } from "../lib/cookie";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const [cookitToken, setCookieToken] = useState(null);

  useEffect(() => {
    login();
  }, []);

  const login = () => {
    saveTokenToCookie();
    const token = getCookie("storytelling_token");
    const trplus_token = getCookie("storytelling_trplus_token");
    const mirror_token = getCookie("storytelling_mirror_token");
    if (token || trplus_token || mirror_token) {
      setAuthenticated(true);
      setCookieToken(token);
    } else {
      setAuthenticated(false);
    }
  };

  const logout = () => {
    setAuthenticated(false);
  };

  const saveTokenToCookie = () => {
    const url = new URL(window.location.href);
    const p = new URLSearchParams(url.search);
    const token = p.get("token");
    const path = url.pathname.split("/");
    const sites = ["trol", "money", "trplus", "mirror"];
    const site = path[2];
    if (sites.includes(site) && site) document.cookie = `site=${site};path=/`;
    if (token && site) {
      switch (site) {
        case "trplus":
          document.cookie = `storytelling_trplus_token=${token};path=/`;
          break;
        case "mirror":
          document.cookie = `storytelling_mirror_token=${token};path=/`;
          break;
        default:
          document.cookie = `storytelling_token=${token};path=/`;
          break;
      }
    }
  };

  return (
    <AuthContext.Provider value={{ authenticated, login, logout, cookitToken }}>
      {children}
    </AuthContext.Provider>
  );
};
