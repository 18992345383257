import { useParams } from "react-router-dom";
import UserInfo from "./User";
import { useEffect, useRef, useState } from "react";
import { Button, Input } from "antd";
import { SVGEdit } from "../../SVG/layout";
import { getCookie } from "../../../lib/cookie";

const LayoutHeader = ({
  onSave,
  saveContentProgress,
  contentTitle,
  callbackSaveTitle,
  isDataChanged,
}) => {
  const { contentId, site = 'trol' } = useParams();
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [title, setTitle] = useState('');
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    setTitle(contentTitle);
  }, [contentTitle]);

  const editTitle = () => {
    setIsEditTitle(true);
  };
  const handleEditTitle = (e) => {
    setTitle(e.target.value);
  };

  const onClickSaveBtn = (e) => {
    setIsSaveBtnDisabled(true);
    if (isSaveBtnDisabled) return;
    onSave(e);
    setTimeout(() => {
      setIsSaveBtnDisabled(false);
    }, 1000);
  };

  useEffect(() => {
    setTimeout(() => {
      switch (site) {
        case "trplus":
          const _infoTRPlus = getCookie("storytelling_trplus_info");
          try {
            if (_infoTRPlus) {
              const infoJsonTRPlus = decodeURIComponent(_infoTRPlus);
              const infoTRPlus = JSON.parse(infoJsonTRPlus);
              const { preview_url } = infoTRPlus;
              if (preview_url) setPreviewUrl(preview_url);
            }
          } catch (error) {
            console.error("Error :", error);
          }
          break;
        case "mirror":
          const _infoMirror = getCookie("storytelling_mirror_info");
          try {
            if (_infoMirror) {
              const infoJsonMirror = decodeURIComponent(_infoMirror);
              const infoMirror = JSON.parse(infoJsonMirror);
              const { preview_url } = infoMirror;
              if (preview_url) setPreviewUrl(preview_url);
            }
          } catch (error) {
            console.error("Error :", error);
          }
          break;
        default:
          setPreviewUrl(
            `${
              process.env[`REACT_APP_${site.toUpperCase()}_WEB_URL`]
            }/content/${contentId}?key=eyJ0cyI6MjUyNDU4MjgwMCwic2VjcmV0IjoiZF5jb2RlbDtwIn0=&random=${Math.random()}`
          );
      }
    }, 1000);
  }, [site]);

  return (
    <>
      <div className='header--main'>
        <div className='menu--action'>
          <h2>{title}</h2>
          {/* {!isEditTitle ? (
            <h2>
              {title ? (
                <>
                  <button onClick={editTitle}>
                    Edit
                    {SVGEdit}
                  </button>
                  {title}
                </>
              ) : (
                ""
              )}
            </h2>
          ) : (
            <div className="edit--box">
              <button
                onClick={() => {
                  setIsEditTitle(false);
                  callbackSaveTitle(title);
                }}
              >
                บันทึก
              </button>
              <Input
                value={title}
                onChange={handleEditTitle}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditTitle(false);
                    callbackSaveTitle(title);
                  }
                }}
              />
            </div>
          )} */}
          <div className='menu--preview'>
            <a
              className='preview'
              // target='_blank'
              href={`/prove/${site}/${contentId}`}
              alt='Preview'
              rel='noreferrer'>
              Proof
            </a>
            <a
              className='preview'
              target='_blank'
              href={previewUrl}
              alt='Preview'
              rel='noreferrer'>
              Preview
            </a>
            {saveContentProgress && (
              <a
                className='save'
                onClick={onClickSaveBtn}
                style={{ backgroundColor: isDataChanged ? '' : '#66ceba' }}>
                {saveContentProgress === 'idle' && 'Save'}
                {saveContentProgress === 'saving' && 'Saving...'}
                {saveContentProgress === 'saved' && 'Save'}
              </a>
            )}
            <UserInfo />
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutHeader;
