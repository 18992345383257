import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useRef } from "react";
import { templateConfig } from "../../../config/template-config";
import _get from "lodash/get";

const FormTitle = ({
  value = "",
  onChange,
  onBlur = () => {},
  onFocus = () => {},
  template,
  selectChapter,
}) => {
  const inputRef = useRef(null);
  const _config = templateConfig.find((item) => item.type === template);
  var titleLength = _get(_config, "max_length.title", 90);
  //fix first title timeline_2
  if (selectChapter == 0 && template == "timeline_2") {
    titleLength = _get(_config, "max_length.title_main", 90);
  }
  return (
    <>
      <Form.Item
        label={
          <span>
            * <strong>Title</strong>
          </span>
        }
        rules={[{ required: true, message: "Please input title!" }]}
        // tooltip="This is a required field"
      >
        <TextArea
          ref={inputRef}
          type="text"
          placeholder="Title"
          value={value}
          onBlur={() => onBlur("")}
          onFocus={() => {
            onFocus("title");
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <span
          className="char--remind"
          style={{
            color: value.length > titleLength && "red",
            borderColor: value.length > titleLength && "red",
          }}
        >
          {value.length}/{titleLength} ตัวอักษร
        </span>
      </Form.Item>
    </>
  );
};

export default FormTitle;
