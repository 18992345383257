import { Tooltip } from "antd";
import React, { useState, useRef } from "react";
import {
  SVGNewWindow,
  SVGPreviewDesktop,
  SVGPreviewMobile,
  SVGPreviewTablet,
} from "../SVG/layout";

const ResponsiveBar = ({ maxWidth, onChange, onNewWindow }) => {
  const [active, setActive] = useState("desktop");

  const handleChange = (screen) => {
    setActive(screen);
    if (screen === "mobile") {
      onChange(414);
    } else if (screen === "tablet") {
      onChange(768);
    } else {
      onChange(maxWidth);
    }
  };

  // ฝากโยกหน่อย
  const activeClass = {
    backgroundColor: "#ddd",
    padding: "0 5px",
    borderRadius: "3px",
  };
  return (
    <>
      <div className="responsive--bar">
        <button
          onClick={() => handleChange("mobile")}
          className={active === "mobile" && "btn--active"}
        >
          <Tooltip title="ดูแบบ Mobile">{SVGPreviewMobile} Mobile</Tooltip>
        </button>
        <button
          onClick={() => handleChange("tablet")}
          className={active === "tablet" && "btn--active"}
        >
          <Tooltip title="ดูแบบ Tablet">{SVGPreviewTablet} Tablet</Tooltip>
        </button>
        <button
          onClick={() => handleChange("desktop")}
          className={active === "desktop" && "btn--active"}
        >
          <Tooltip title="ดูแบบ Desktop">{SVGPreviewDesktop} Desktop</Tooltip>
        </button>
        <button onClick={onNewWindow}>
          <Tooltip title="เปิดหน้าจอใหม่ (new window)">
            {SVGNewWindow} New window
          </Tooltip>
        </button>
      </div>
    </>
  )
};

export default ResponsiveBar;
