import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import contentContext from "../../contexts/content_context";

import {
  templateConfig,
  templateConfigAll,
} from "../../config/template-config";


const ModalChangeTemplate = () => {
  const {
    currentSlide,
    slides,
    saveCurrentSlide,
    changeTemplateModal,
    setChangeTemplateModal,
  } = useContext(contentContext);

  const [slide, setSlide] = useState(slides[currentSlide] || { data: [] });
  const [slideConfig, setSlideConfig] = useState(templateConfigAll);
  const [changeSlide, setChangeSlide] = useState("");

  useEffect(() => {
    const _slide = slides[currentSlide];
    setSlideConfig(
      templateConfigAll.find((item) => item.type === _slide.template)
    );
  }, [changeTemplateModal]);

  useEffect(() => {
    const _slide = slides[currentSlide];
    setSlide(_slide);
    setSlideConfig(
      templateConfigAll.find((item) => item.type === _slide.template)
    );
  }, [currentSlide]);

  const changeTemplate = (name) => {
    const _slide = { ...slide, template: name };
    setSlide(_slide);
    saveCurrentSlide(_slide);
    setChangeTemplateModal(false);
  };

  return (
    <>
      <Modal
        open={changeTemplateModal}
        className="select--template"
        title="ต้องการเปลี่ยน Layout หรือไม่?"
        onOk={changeTemplate}
        onCancel={() => {
          setChangeTemplateModal(false);
        }}
      >
        <div className="template--list">
          {templateConfig.map((item, index) => {
            // special template can't convert to other template
            if (
              ["image_comparison", "chat_bubble", "title_effect" ].includes(
                slideConfig.type
              )
            ) {
              return null;
            }
            if (
              item.dataset === slideConfig.dataset &&
              item.thumbnails === slideConfig.thumbnails
            ) {
              // templates dataset and thumbnails must be same
              return (
                <div
                  key={index}
                  className="template--item"
                  onClick={() => {
                    changeTemplate(item.type);
                  }}
                >
                  <h3>{item.name}</h3>
                  <img src={item.example_image} />
                </div>
              );
            }
          })}
        </div>
      </Modal>
    </>
  );
};

export default ModalChangeTemplate;
