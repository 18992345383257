import { Form } from "antd";
import React, { useContext, useEffect, useState, Fragment } from "react";
import _get from "lodash/get";
import FormTitle from "../../Form/Elements/Title";
import FormAbstract from "../../Form/Elements/Abstract";
import DropImages from "../../Form/Elements/DropImage";
import AddNewChapter from "../../Form/Elements/AddNewChapter";
import FormColor from "../../Form/Elements/ColorPicker";
import ChapterTable from "../../Form/Elements/ChapterTable";
import FormAbbr from "../../Form/Elements/Abbr";
import FormPointName from "../../Form/Elements/PointName";
import FormDescription from "../../Form/Elements/Description";
import UploadList from "../../Form/Elements/ImageList";
import contentContext from "../../../contexts/content_context";
import LayoutOptions from "../../Form/Elements/ButtonLayoutOptions";
import StoryLayout from "../../Form/Elements/StoryOptions";
import StoryStyles from "../../Form/Elements/StoryStyles";
import RadioEmbed from "../../Form/Elements/RadioEmbed";
import EmbedUpload from "../../Form/Elements/EmbedUpload";


import { getDefaultChapter } from "../../../lib/default-value";

const defaultChapter = getDefaultChapter();
const bc = new BroadcastChannel("storytelling-update");

const MultipleChapter = ({ config, slide, onChange }) => {
  const {
    contentId,
    site,
    addImages,
    currentSlide,
    removeImage,
    imageMode,
    backgroundType,
    setBackgroundType,
  } = useContext(contentContext);
  const [form] = Form.useForm();
  const [chapter, setChapter] = useState({});
  const [chapters, setChapters] = useState([]);
  const [selectChapter, setSelectChapter] = useState(null);
  const [thumbnailMax, setThumbnailMax] = useState(1);
  const [enableImageInColorPicker, setEnableImageInColorPicker] =
    useState(false);

  useEffect(() => {
    if (selectChapter === null) {
      setChapter({ ...defaultChapter });
    } else {
      setChapter(chapters[selectChapter] || { ...defaultChapter });
    }
    if (
      chapters[selectChapter] &&
      chapters[selectChapter].background_type &&
      checkConfig("embed")
    ) {
      setBackgroundType(chapters[selectChapter].background_type);
    } else {
      setBackgroundType("image");
    }
  }, [selectChapter]);

  useEffect(() => {
    // reset chapter when change slide
    setSelectChapter(null);
  }, [currentSlide]);

  useEffect(() => {
    setChapters(slide.data);
  }, [slide, currentSlide]);

  useEffect(() => {
    checkEnableImageInColor();
  }, [chapter]);

  useEffect(() => {
    bc.onmessage = (e) => {
      const { event, type, slide, chapter, field } = e.data;
      if (event === "click-preview") {
        setSelectChapter(chapter);
      }
    };
  }, []);

  const deleteChapter = (index) => {
    const _chapters = chapters;
    _chapters.splice(index, 1);
    setChapters(_chapters);
    setSelectChapter(_chapters.length > 0 ? 0 : null);
    onChange({ ...slide, data: _chapters });
  };

  const addChapter = () => {
    const _chapters = [...chapters, { ...getDefaultChapter() }];
    setChapters(_chapters);
    setSelectChapter(_chapters.length - 1);
    onChange({ ...slide, data: _chapters });
    setBackgroundType("image");
    document.getElementById("chapter--editor");
    setTimeout(() => {
      bc.postMessage({
        type: "scroll",
        data: {
          currentSlide: 0,
          activeChapter: _chapters.length - 1,
          template: config.type,
        },
      });
    }, 1200);
  };

  const onMoveChapter = (target, moveto) => {
    const _slide = chapters.splice(target, 1);
    if (target > moveto) {
      chapters.splice(moveto, 0, _slide[0]);
    } else {
      chapters.splice(moveto - 1, 0, _slide[0]);
    }
    setChapters([...chapters]);
  };

  const handleChapterChange = (key, value) => {
    const _chapter = { ...chapter, [key]: value };
    setChapter(_chapter);

    const _chapters = chapters;
    _chapters[selectChapter] = _chapter;
    setChapters(_chapters);

    onChange({ ...slide, data: _chapters });
  };

  // only first chapter and a template with remove_header = true
  const checkConfig = (field) => {
    if (selectChapter === 0) {
      // return config[field];
      if (config.remove_header) {
        if (config.remove_header_fields.includes(field)) {
          return false;
        }
        return config[field];
      }
    } else if (selectChapter !== 0) {
      if (config.remove_title) {
        if (config.remove_title_fields.includes(field)) {
          return false;
        }
        return config[field];
      }
    }
    if (config.thumbnails_content) {
      setThumbnailMax(config.thumbnails_content);
    }
    return config[field];
  };

  const checkEnableImageInColor = () => {
    if (config.type === "story") {
      if (["squareGreenTheme", "squareBlueTheme"].includes(chapter.layout)) {
        setEnableImageInColorPicker(true);
      }
    } else {
      setEnableImageInColorPicker(false);
    }
  };

  const checkEnableColor = () => {
    if (config.type === "story") {
      if (["squareGreenTheme", "squareBlueTheme"].includes(chapter.layout)) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="form--multiple--chapter">
      <div className="chapter--table">
        <ChapterTable
          data={chapters}
          onClickChapter={setSelectChapter}
          onDeleteCapter={deleteChapter}
          onMoveChapter={onMoveChapter}
          activeChapter={selectChapter}
          template={config.type}
          config={config}
        />
        <AddNewChapter onAddChapter={addChapter} config={config} />
        <div id="chapter--editor"></div>
      </div>

      {chapters.length > 0 && selectChapter !== null && (
        <Form form={form} layout="vertical">
          {checkConfig("title") && (
            <FormTitle
              template={config.type}
              selectChapter={selectChapter}
              value={chapter.title}
              onChange={(val) => {
                handleChapterChange("title", val);
              }}
            />
          )}

          {checkConfig("story_layout") && (
            <>
              <StoryStyles
                label="Text Style (Title)"
                value={chapter.title_style}
                onSelected={(val) => {
                  handleChapterChange("title_style", val);
                }}
              />
            </>
          )}
          {checkConfig("abstract") && (
            <FormAbstract
              template={config.type}
              value={chapter.abstract}
              onChange={(val) => {
                handleChapterChange("abstract", val);
              }}
            />
          )}
          {checkConfig("story_layout") && (
            <>
              <StoryStyles
                label="Text Style (Description)"
                value={chapter.description_style}
                onSelected={(val) => {
                  handleChapterChange("description_style", val);
                }}
              />
            </>
          )}
          {checkConfig("content") && (
            <FormDescription
              value={chapter.content}
              onChange={(val) => {
                handleChapterChange("content", val);
              }}
              site={site}
              contentId={contentId}
              onFileUpdate={(res) => {
                if (res.type === "remove_image") {
                  removeImage(res.src, false);
                }
              }}
            />
          )}
          {checkConfig("abbr_name") && (
            <FormAbbr
              template={config.type}
              value={chapter.abbr_name}
              onChange={(val) => {
                handleChapterChange("abbr_name", val);
              }}
            />
          )}
          {checkConfig("point_name") && (
            <FormPointName
              template={config.type}
              value={chapter.point_name || ""}
              onChange={(val) => {
                handleChapterChange("point_name", val);
              }}
            />
          )}
          {checkConfig("story_layout") && (
            <>
              <StoryLayout
                value={chapter.layout}
                options={config.layout_options}
                onSelected={(val) => {
                  handleChapterChange("layout", val);
                }}
              />
            </>
          )}
          {checkConfig("layout") && (
            <LayoutOptions
              value={chapter.layout}
              options={config.layout_options}
              onSelected={(val) => {
                handleChapterChange("layout", val);
              }}
            />
          )}
          {checkConfig("color") && checkEnableColor() && (
            <FormColor
              onChange={(color) => {
                handleChapterChange("color", color);
              }}
              enableImage={enableImageInColorPicker}
              value={chapter.color}
              image={_get(chapter, "thumbnails[0]", "")}
            />
          )}
          {checkConfig("embed") && (
            <RadioEmbed
              defaultValue={
                (chapters[selectChapter] &&
                  chapters[selectChapter].background_type) ||
                "image"
              }
              value={backgroundType}
              onChange={(e) => {
                setBackgroundType(e.target.value);
                handleChapterChange("background_type", e.target.value);
              }}
            ></RadioEmbed>
          )}

          {checkConfig("embed") && backgroundType === "video" && (
            <Fragment>
              <p>ใส่โค้ด Embed ของวิดีโอที่ต้องการให้แสดงผล</p>
              <Fragment>
                <EmbedUpload
                  value={
                    chapters[selectChapter] ? chapters[selectChapter].embed : ""
                  }
                  onChange={(val) => {
                    handleChapterChange("embed", val);
                  }}
                />
              </Fragment>
            </Fragment>
          )}
          
          {checkConfig("thumbnails") && backgroundType !== "video" && (
            <div className="image--upload--container">
              <DropImages
                limitThumbnail={
                  checkConfig("thumbnails_content")
                    ? thumbnailMax
                    : config.thumbnails
                }
                device={checkConfig("device")}
                ref_id={contentId}
                site={site}
                onUploaded={addImages}
                template={config.type}
                chapterImageSplit={chapters[selectChapter].image_split}
                onCheckbox={(imgs) => {
                  handleChapterChange("image_split", imgs);
                }}
              />
              {imageMode === "desktop" && (
                <UploadList
                  selected={chapter.thumbnails || []}
                  limitThumbnail={
                    checkConfig("thumbnails_content")
                      ? thumbnailMax
                      : config.thumbnails
                  }
                  onChange={(imgs) => {
                    handleChapterChange("thumbnails", imgs);
                  }}
                />
              )}
              {imageMode === "mobile" && (
                <UploadList
                  selected={chapter.thumbnails_mobile || []}
                  limitThumbnail={
                    checkConfig("thumbnails_content")
                      ? thumbnailMax
                      : config.thumbnails
                  }
                  onChange={(imgs) => {
                    handleChapterChange("thumbnails_mobile", imgs);
                  }}
                />
              )}
            </div>
          )}
        </Form>
      )}
    </div>
  );
};
export default MultipleChapter;
